import {ResponseStatus} from '../models/responseStatus.js';

export const API_URL = "https://api.frisorsiden.dk";
//export const API_URL = "https://dev-api.frisorsiden.dk";
//export const API_URL = "http://localhost:8080";

export const callApi = async ({url, method, body, token}) => {

  url = API_URL + url;

  let headers = {};
  headers["Content-Type"] = "application/json";
  if (token) headers["Authorization"] = "Baerer " + token;
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Methods"] = "POST, GET, OPTIONS";
  headers["Access-Control-Allow-Headers"] = "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With";

  if (method === "GET" && body) {

    for (let key in body) {
      if (body[key] === null || body[key] === undefined || body[key] === '') {
        delete body[key];
      }
    }

    url = url + "?" + new URLSearchParams(body).toString();
  }

  let bodyJson = method !== "GET" && body ? JSON.stringify(body) : null;

  console.log("Calling url: " + url);
  console.log("Method: " + method);
  console.log("Body:", body);

  try {
    const response = await fetch(url, {
      method: method,
      mode: "cors",
      cache: "no-cache",
      headers: headers,
      body: bodyJson
    });

    let responseString = await response.json();
    let responseJson = typeof responseString === "object" ? responseString : JSON.parse(responseString);
    console.log(responseJson)

    // Do not use the following, as we set the status in the action payload (data) for some reason...
    // 200 <= response.status && response.status < 300
    return {
      status: 200 <= responseJson.statusCode && responseJson.statusCode < 300 ? ResponseStatus.OK : ResponseStatus.ERROR,
      data: responseJson,
    };
  } catch (error) {
    console.log("Error fetching API", error);
    return "Error fetching API";
  }
};

export const callApiUploadFile = ({url, method, body, files, token, callback}) => {

  url = API_URL + url;

  let headers = {};
  //headers["Content-Type"] = "application/json";
  if (token) headers["Authorization"] = "Baerer " + token;
  headers["Access-Control-Allow-Origin"] = "*";
  headers["Access-Control-Allow-Methods"] = "POST, GET, OPTIONS";
  headers["Access-Control-Allow-Headers"] = "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With";

  let formData = new FormData();
  formData.append('files', files);
  formData.append('foreignId', body.foreignId);
  formData.append('foreignTable', body.foreignTable);

  console.log(files)
  console.log(body)
  console.log(formData)
  let bodyJson = JSON.stringify(formData);

  console.log("Calling url: " + url);
  console.log("Method: " + method);
  console.log("Body:", formData);

  try {

    fetch(url, {
      method: 'POST',
      mode: "cors",
      cache: "no-cache",
      headers: headers,
      body: formData
    }, callback());

  } catch (error) {
    console.log("Error fetching API", error);
  }
};
