import React from 'react';

import './ContentPage.css';

// a basic form
const ContentPage = (props) => {

  return (
    <div className="content-page">
      {props.children}
    </div>
  );
};

export default ContentPage;
