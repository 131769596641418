import { callApi } from './base.js';

export const getHairdresserRatings = async () => {
  return callApi({
    url: "/hairdresser-ratings",
    method: "GET",
    body: null,
    token: null,
  });
};

export const getHairdresserRatingsByAuthUser = async (token) => {
  return callApi({
    url: "/hairdresser-ratings/auth",
    method: "GET",
    body: null,
    token: token,
  });
};

export const getHairdresserRating = async (id) => {
  return callApi({
    url: "/hairdresser-ratings/" + id,
    method: "GET",
    body: null,
    token: null,
  });
};

export const postHairdresserRating = async (token, data) => {
  return callApi({
    url: "/hairdresser-ratings",
    method: "POST",
    body: data,
    token: token,
  });
};

export const putHairdresserRating = async (token, id, data) => {
  return callApi({
    url: "/hairdresser-ratings/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};
