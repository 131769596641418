import { copy, keyGenerator } from "./utils";

export const initialHairdresserOffer = {
  id: "",
  hairdresserLocationId: "",
  name: "",
  description: "",
  price: "",
  active: true,
  deleted: false
}

export const generateEmptyHairdresserOffer = (hairdresserLocationId) => {
  let offer = copy(initialHairdresserOffer);
  offer.hairdresserLocationId = hairdresserLocationId;
  offer.key = keyGenerator();
  return offer;
}

export const initialHairdresserLocation = {
  hairdresserId: 0,
  streetName: "",
  streetNumber: "",
  zipCode: "",
  city: "",
  country: "",
  phoneCallingCode: 45,
  phoneNumber: "",
  email: "",
  name: "",
  description: "",
  cover: "",
  homepage: "",
  latitude: "",
  longitude: "",
  active: true,
  hairdresserCategories: []
}

export const generateEmptyHairdresserLocation = (hairdresserId) => {
  let hairdresser = copy(initialHairdresserLocation);
  hairdresser.hairdresserId = hairdresserId;
  hairdresser.key = keyGenerator();

  return hairdresser;
}


export const initialUser = {
  firstName: "",
  middleName: "",
  lastName: "",
  email: "",
  phoneCallingCode: 0,
  phoneNumber: 0,
  shortDescription: "",
  description: "",
  password: "",
  street: "",
  streetNumber: "",
  city: "",
  zipcode: 0,
  country: "",
  role: "User",
  emailConfirmed: false,
  phoneConfirmed: false,
  manualConfirmed: false
};

export const generateEmptyUser = () => {
  let user = copy(initialUser);
  user.key = keyGenerator();

  return user;
}

export const initialUserValidation = {
  firstName: [],
  middleName: [],
  lastName: [],
  email: [],
  phoneCallingCode: [],
  phoneNumber: [],
  password: [],
  street: [],
  streetNumber: [],
  city: [],
  zipcode: [],
  country: [],
  role: []
};

export const initialHairdresserRating = {
  hairdresserLocationId: 0,
  overall: 0,
  money: 0,
  quality: 0,
  experience: 0,
  message: '',
  hairdresserCategoryIds: []
};

export const generateEmptyHairdresserRating = (hairdresserLocationId) => {
  const rating = copy(initialHairdresserRating);
  rating.hairdresserLocationId = hairdresserLocationId;
  rating.key = keyGenerator();

  return rating;
}

export const initialHairdresserUser = {
  userId: 0,
  hairdresserLocationId: 0,
  permission: 'read',
  title: 'Kollega',
  showProfile: false,
  active: true,
  deleted: false,
  user: initialUser
};

export const generateEmptyHairdresserUser = (userId, hairdresserLocationId) => {
  const hairdresserUser = copy(initialHairdresserUser);
  hairdresserUser.userId = userId;
  hairdresserUser.hairdresserLocationId = hairdresserLocationId;
  hairdresserUser.key = keyGenerator();

  return hairdresserUser;
}

export const initialImage = {
  foreignId: 0,
  foreignTable: '',
  type: '',
  title: '',
  description: '',
  url: '',
  sequence: 0,
  deleted: false
}
