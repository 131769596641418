import React from 'react';

import './About.css';


export const About = (props) => {


  return (
    <div className="about content-page">
      <div className="content-body container">
        <h1>Om Frisørsiden</h1>

        <p>Frisørsiden er sat i verden, for at gøre det nemt og overskueligt at finde en frisør. Samtidig vil vi også hjælpe frisørerne med at blive synlige for den gængse kunde. Kort og godt, et sted hvor vi mødes.</p>
      </div>

    </div>
  )
}

export default About;
