import React from 'react';
import './MapMarker.css';
import {useComponentVisible} from "../../Helpers";

import {Link} from "react-router-dom";
import {IconMapMarkerScissor, IconRatingEmtpy, IconRatingFull, IconRatingHalf} from "../../Icons";

export const MapMarker = (props) => {

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  let minPrice = 0;
  for (let i = 0; i < props.marker.hairdresserOffers.length; i++) {
    if (props.marker.hairdresserOffers[i].price < minPrice || minPrice === 0) {
      minPrice = props.marker.hairdresserOffers[i].price;
    }
  }

  return (
    <div ref={ref} className={"marker" + (isComponentVisible ? " marker-open" : "")} onClick={() => setIsComponentVisible(!isComponentVisible)}>
      <div className="marker-icon"><IconMapMarkerScissor /></div>

      <div className={"marker-infobox" + (isComponentVisible ? " marker-infobox-open" : "")} onClick={(e) => {e.stopPropagation();}}>
        <div className="marker-infobox-cover">
          <div className="image image-cover">
            <Link to={"/frisor/"+props.marker.url}><img src={props.marker.cover} alt={props.marker.name} /></Link>
          </div>
          <h2 className="marker-infobox-title">{props.marker.name}</h2>
        </div>
        <div className="marker-infobox-content">
          <div className="marker-infobox-rating">
            <div className="marker-infobox-rating-icons">
              <IconRatingEmtpy />
              <IconRatingEmtpy />
              <IconRatingEmtpy />
              <IconRatingEmtpy />
              <IconRatingEmtpy />
            </div>
            <span className="marker-infobox-rating-points">0</span> <span className="marker-infobox-rating-max">(0)</span>
          </div>


          <div className="marker-infobox-footer">
            <div className="marker-infobox-price text-primary"><span>{minPrice > 0 ? "DKK " + minPrice + "+" : "Ingen pris"}</span></div>
            <div className="marker-infobox-link">
              <Link className="btn btn-primary btn-small" to={"/frisor/"+props.marker.url}>Læs mere</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapMarker;

