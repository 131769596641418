import React from 'react';

import './Faq.css';

export const Faq = () => {

  return (
    <div className="faq content-page">
      <div className="content-body container">
        <h1>Ofte stillede spørgsmål</h1>

        <div className="row">

          <div className="col-md-8">
            <div className="faq-item">
              <h3 className="">Spørgsmål 1</h3>
              <p className="font-weight-light font-size-normal">Et svar til spørgsmål 1.</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Faq;
