import { callApi } from './base.js';

export const getHairdresserTracking = async () => {
  return callApi({
    url: "/hairdresser-tracking",
    method: "GET",
    body: null,
    token: null,
  });
};

export const postHairdresserTracking = async (token, data) => {
  return callApi({
    url: "/hairdresser-tracking",
    method: "POST",
    body: data,
    token: token,
  });
};
