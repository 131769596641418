import { callApi } from './base.js';

export const getSearchAgents = async (token) => {
  return callApi({
    url: "/search-agents",
    method: "GET",
    body: null,
    token: token,
  });
};

export const getSearchAgent = async (token, id) => {
  return callApi({
    url: "/search-agents/" + id,
    method: "GET",
    body: null,
    token: token,
  });
};

export const postSearchAgent = async (token, data) => {
  return callApi({
    url: "/search-agents",
    method: "POST",
    body: data,
    token: token,
  });
};

export const putSearchAgent = async (token, id, data) => {
  return callApi({
    url: "/search-agents/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const deleteSearchAgent = async (token, id) => {
  return callApi({
    url: "/search-agents/" + id,
    method: "DELETE",
    body: null,
    token: token,
  });
};