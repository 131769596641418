import React from 'react';
import {useParams} from "react-router";

import './Hairdresser.css';

import {ResponseStatus} from "../../models/responseStatus";
import {getHairdresserLocation} from "../../requests/hairdresserLocation";
import {postHairdresserTracking} from "../../requests/hairdresserTracking";
import LinkPhoneNumber from "../elements/LinkPhoneNumber";
import LinkEmail from "../elements/LinkEmail";
import {IconCertification2, IconRatingEmtpy, IconRatingFull, IconRatingHalf} from "../../Icons";
import RateHairdresser from "../Modals/RateHairdresser";
import {countDecimals, getKey, hasValue} from "../../utils";

import hairdresserPlaceholder from "../../assets/images/general/frisor-placeholder.png";
import RatingList from "../templates/RatingList";
import LoadingPage from "../elements/LoadingPage";
import ContentPage from "../elements/ContentPage";
import RatingIcons from "../elements/RatingIcons";
import {generateEmptyHairdresserRating, initialHairdresserRating} from "../../types";
import {AuthContext} from "../../contexts/AuthContext";
import profileImage from "../../assets/images/general/avatar.jpg";

export const Hairdresser = (props) => {

  let { hairdresserLocationUrl } = useParams();
  const [state] = React.useContext(AuthContext);

  const [isPageReady, setIsPageReady] = React.useState(false)
  const [hairdresserLocation, setHairdresserLocation] = React.useState(null);
  const [showRateModal, setShowRateModal] = React.useState(false);

  const [averageRating, setAverageRating] = React.useState(initialHairdresserRating);
  const [groupedRatings, setGroupedRatings] = React.useState({});

  React.useEffect(() => {
    async function fetchInitialInfo() {

      let hairdresserLocationResult = await getHairdresserLocation(hairdresserLocationUrl);

      if (hairdresserLocationResult.status === ResponseStatus.OK) {

        setHairdresserLocation(hairdresserLocationResult.data.data);

        let initGroupedRatings = {};
        for (const rating of hairdresserLocationResult.data.data.hairdresserRatings) {
          if (initGroupedRatings[rating.userId] === undefined) {
            initGroupedRatings[rating.userId] = {
              average: generateEmptyHairdresserRating(0),
              ratings: []
            };

            initGroupedRatings[rating.userId]['average'].message = rating.message;
            initGroupedRatings[rating.userId]['average'].userName = rating.userName;
          }

          const prevOverall = initGroupedRatings[rating.userId]['average'].overall * initGroupedRatings[rating.userId]['ratings'].length;
          const prevExperience = initGroupedRatings[rating.userId]['average'].experience * initGroupedRatings[rating.userId]['ratings'].length;
          const prevMoney = initGroupedRatings[rating.userId]['average'].money * initGroupedRatings[rating.userId]['ratings'].length;
          const prevQuality = initGroupedRatings[rating.userId]['average'].quality * initGroupedRatings[rating.userId]['ratings'].length;

          initGroupedRatings[rating.userId]['average'].overall = (prevOverall + rating.overall) / (initGroupedRatings[rating.userId]['ratings'].length + 1);
          initGroupedRatings[rating.userId]['average'].experience = (prevExperience + rating.experience) / (initGroupedRatings[rating.userId]['ratings'].length + 1);
          initGroupedRatings[rating.userId]['average'].money = (prevMoney + rating.money) / (initGroupedRatings[rating.userId]['ratings'].length + 1);
          initGroupedRatings[rating.userId]['average'].quality = (prevQuality + rating.quality) / (initGroupedRatings[rating.userId]['ratings'].length + 1);

          initGroupedRatings[rating.userId]['ratings'].push(rating);
        }

        let initAverageRating = generateEmptyHairdresserRating(hairdresserLocationResult.data.data.id);
        for (const key in initGroupedRatings) {
          initAverageRating.overall += initGroupedRatings[key]['average'].overall;
        }
        if (Object.keys(initGroupedRatings).length > 0) {
          initAverageRating.overall = initAverageRating.overall / Object.keys(initGroupedRatings).length;
        }
        if (countDecimals(initAverageRating.overall) > 2) {
          initAverageRating.overall = initAverageRating.overall.toFixed(2);
        }
        initAverageRating.overall = initAverageRating.overall.toString().replace('.',',');

        setGroupedRatings(initGroupedRatings)
        setAverageRating(initAverageRating)

      } else {
        console.log("Fejl under hentning af frisør");
        setHairdresserLocation("Fejl under hentning af frisør");
      }

      setIsPageReady(true)
    }

    fetchInitialInfo();
  }, [hairdresserLocationUrl]);

  const trackUser = async () => {
    let data = {
      "hairdresserLocationId": hairdresserLocation.id
    };

    let hairdresserTrackingResult = await postHairdresserTracking(state?.token?.accessToken, data);

    if (hairdresserTrackingResult.status === ResponseStatus.OK) {
      console.log("success")
    } else if (hairdresserTrackingResult.status === ResponseStatus.ERROR) {
      console.log("error")
      console.log(hairdresserTrackingResult)
    } else {
      console.log("error 2")
    }
  }

  //let coverImage = hairdresserLocation.images.length > 0 ? hairdresserLocation.images[0].url : defaultCover;
  //let coverDescription = hairdresserLocation.images.length > 0 ? hairdresserLocation.images[0].title : "Standard frisør";
  return (
    <ContentPage>
      <LoadingPage isPageReady={isPageReady} notFound={!hasValue(hairdresserLocation)}>
        {
          hasValue(hairdresserLocation) &&
          <div className="hairdresser">
            <div className="container">
              <div className="hairdresser-header position-relative">
                <h1>{hairdresserLocation.name}</h1>
                {
                  hairdresserLocation.hairdresser?.certified &&
                  <IconCertification2 />
                }
              </div>

              <div className="images mb-4">
                <div className="image image-cover">
                  <img src={hasValue(hairdresserLocation.images) ? hairdresserLocation.images[0].url : (hairdresserLocation.cover !== '' ? hairdresserLocation.cover : hairdresserPlaceholder)} alt={hairdresserLocation.name} />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12 col-sm-6 col-md-4 mb-3 mb-sm-0 text-center text-sm-left">
                  <div className="hairdresser-rating-score">
                    <RatingIcons rating={averageRating} size="large"></RatingIcons>
                    <span className="hairdresser-rating-points">{averageRating.overall}</span> <span className="hairdresser-rating-max">({Object.keys(groupedRatings).length})</span>
                  </div>

                  <div className="hairdresser-categories">
                    {
                      hairdresserLocation.hairdresserCategories.map((hairdresserCategory) =>
                        <span key={hairdresserCategory.id} className="hairdresser-category">{hairdresserCategory.name}</span>
                      )
                    }
                  </div>

                </div>
                <div className="col-12 col-md-4 mb-3 mb-sm-2 mt-sm-3 mt-md-0 mb-md-0 order-2 order-sm-3 order-md-2 text-center">
                  <span className="hairdresser-contact">Telefon: <LinkPhoneNumber phoneCallingCode={hairdresserLocation.phoneCallingCode} phoneNumber={hairdresserLocation.phoneNumber} /></span>
                  <span className="hairdresser-contact">Mail: <LinkEmail email={hairdresserLocation.email} /></span>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mb-3 mb-sm-0 text-center text-sm-right order-3 order-sm-2 order-md-3">
                  {
                    hasValue(hairdresserLocation.homepage)
                      ?
                      <a className="btn btn-primary" href={hairdresserLocation.homepage} target="_blank" onClick={trackUser}>Gå til hjemmeside</a>
                      :
                      <button className="btn btn-primary simple-tooltip" data-title="Ingen hjemmeside tilføjet" disabled>Gå til hjemmeside</button>
                  }
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-7 mb-3 mb-md-0">
                  <div className="hairdresser-info mb-5">
                    <h2 className="font-size-xlarge font-weight-bold mt-0 mb-3">Hvem og hvad er {hairdresserLocation.name}?</h2>
                    {
                      hasValue(hairdresserLocation.description)
                        ?
                        <div dangerouslySetInnerHTML={{ __html: hairdresserLocation.description }} />
                        :
                        <div>Der er ikke lavet nogle beskrivelse.</div>
                    }
                  </div>

                  <div className="hairdresser-offers mb-5">
                    <h2 className="font-size-xlarge font-weight-bold mt-0 mb-3">{hairdresserLocation.name} tilbyder</h2>
                    {
                      hasValue(hairdresserLocation.hairdresserOffers)
                        ?
                        hairdresserLocation.hairdresserOffers.map((offer) =>
                          <div className="hairdresser-offer" key={getKey(offer)}>
                            <div className="hairdresser-offers-line d-flex">
                              <div className="hairdresser-offer-name">{offer.name}</div>
                              <div className="hairdresser-offer-price">{offer.price} kr.</div>
                            </div>
                            <div className="hairdresser-offer-description">{offer.description}</div>
                          </div>
                        )
                        :
                        <div className="hairdresser-offer">Der er ikke blevet oprettet nogle services.</div>
                    }
                  </div>

                  <div className="hairdresser-users mb-5 mb-md-0">
                    <h2 className="font-size-xlarge font-weight-bold mt-0 mb-3">Her møder du</h2>
                    <div className="row">
                      {
                        hasValue(hairdresserLocation.hairdresserUsers)
                          ?
                          hairdresserLocation.hairdresserUsers.map((hairdresserUser) =>
                            <div className="col-12 col-sm-6 mb-4" key={getKey(hairdresserUser)}>
                              <div className="hairdresser-user">
                                <div className="card hairdresser-user-card d-flex">
                                  <img className="card-img-top hairdresser-user-profile-image" src={hairdresserUser.user.profileImage?.url || profileImage} alt="Medarbejder" />
                                  <div className="card-body">
                                    <h5 className="card-title hairdresser-user-card-title">{hairdresserUser.user.firstName} {hairdresserUser.user.lastName}</h5>
                                    <h6>> {hairdresserUser.title}</h6>
                                    <p className="card-text">{hairdresserUser.user.shortDescription}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                          :
                          <div className="col-12 hairdresser-user">Der er ikke blevet tilføjet nogle brugere.</div>
                      }
                    </div>
                  </div>


                </div>
                <div className="col-12 col-md-5">
                  <div className="hairdresser-ratings">
                    <h2 className="font-size-xlarge font-weight-bold mt-0 mb-3">Ratings</h2>

                    <RatingList groupedRatings={groupedRatings} />

                    <button type="button" className="btn btn-primary" onClick={() => setShowRateModal(true)}>Rate {hairdresserLocation.name}</button>
                    {/*<span type="button" className="btn btn-primary hover-title" data-title="Kræver en bruger for at rate">Rate {hairdresserLocation.name}</span>*/}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-12 col-md-6">

                </div>
              </div>

            </div>

            <RateHairdresser show={showRateModal} close={() => setShowRateModal(false)} hairdresserLocation={hairdresserLocation} hairdresserCategories={hairdresserLocation.hairdresserCategories} />
          </div>
        }
      </LoadingPage>
    </ContentPage>
  )
};

export default Hairdresser;
