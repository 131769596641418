import React from 'react';
import {Link} from "react-router-dom";

import './ConnectedCompanies.css';
import {IconEdit, IconInfo} from "../../../Icons";
import {getHairdressersByAuthUser} from "../../../requests/hairdresser";
import {AuthContext} from "../../../contexts/AuthContext";
import {ResponseStatus} from "../../../models/responseStatus";
import {hasValue} from "../../../utils";

export const ConnectedCompanies = () => {

  const [state,] = React.useContext(AuthContext);

  const [hairdressers, setHairdressers] = React.useState([]);

  React.useEffect(() => {
    async function fetchInitialInfo() {

      let hairdresserResult = await getHairdressersByAuthUser(state.token.accessToken);

      if (hairdresserResult.status === ResponseStatus.OK) {

        setHairdressers(hairdresserResult.data.data);

      } else {
        console.log("Fejl under hentning af virksomheder");
      }
    }
    fetchInitialInfo();
  }, []);

  return (
    <div className="connected-companies">

      <div className="d-flex">
        <div className="flex-grow-1">
          <h1>Dine virksomheder</h1>
        </div>
        <div>
          <Link className="btn btn-primary" to="/dashboard/virksomheder/opret">Opret ny frisør</Link>
        </div>

      </div>

      <div className="connected-company connected-company-header mb-3">
        <div className="connected-company-active">
        </div>
        <div className="connected-company-name">
          Navn
        </div>
        <div className="connected-company-estate-type">
          Boligtype
        </div>
        <div className="connected-company-price">
          Udbudspris
        </div>
        <div className="connected-company-area">
          Boligareal
        </div>
        <div className="connected-company-actions">
        </div>
      </div>

      {
        hasValue(hairdressers) &&
        hairdressers.map(hairdresser =>
            <div key={hairdresser.id} className="box box-shadow connected-company mb-3">
              <div className="connected-company-name">
                {hairdresser.name}
              </div>
              <div className="connected-company-estate-type">

              </div>
              <div className="connected-company-price">

              </div>
              <div className="connected-company-area">

              </div>
              <div className="connected-company-actions">
                <Link to={"/dashboard/virksomheder/"+hairdresser.url}><IconEdit /></Link>
                <a href="#"><IconInfo /></a>
              </div>
            </div>
          )
      }

    </div>
  )
}

export default ConnectedCompanies;
