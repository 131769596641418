import React from 'react';
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";

import SearchAddress from "../../elements/SearchAddress";
import {IconAndelsbolig, IconHome} from "../../../Icons";
import ChoiceButton from "../../elements/ChoiceButton";
import CustomInput from "../../elements/CustomInput";
import ChoiceWrapper from "../../elements/ChoiceWrapper";
import EnergyLabel from "../../elements/EnergyLabel";
import {AuthContext} from "../../../contexts/AuthContext";
import {getSearchAgent, postSearchAgent, putSearchAgent} from "../../../requests/searchAgent";
import {ResponseStatus} from "../../../models/responseStatus";

import './UserRating.css';

const initialSearchAgent = {
  active: true,
  city: "",
  country: "",
  estateTypes: [],
  maxBasementArea: 0,
  maxBathrooms: 0,
  maxConstructionYear: 0,
  maxEnergyLabel: "",
  maxFloors: 0,
  maxGroundArea: 0,
  maxLivingArea: 0,
  maxPlan: -1,
  maxPrice: 0,
  maxRooms: 0,
  minBasementArea: 0,
  minBathrooms: 0,
  minConstructionYear: 0,
  minEnergyLabel: "",
  minFloors: 0,
  minGroundArea: 0,
  minLivingArea: 0,
  minPlan: -1,
  minPrice: 0,
  minRooms: 0,
  streetName: "",
  streetNumber: "",
  zipcode: 0,
}

export const UserRating = (props) => {
  let history = useHistory();
  const [state,] = React.useContext(AuthContext);
  let { searchAgentId } = useParams();
  const newSearchAgent = !searchAgentId;

  const [pageReady, setPageReady] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [readyForSubmit, setReadyForSubmit] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const [searchAgent, setSearchAgent] = React.useState(initialSearchAgent);

  React.useEffect(() => {
    async function fetchInitialInfo() {

      if (!newSearchAgent) {
        let searchAgentResult = await getSearchAgent(state.token.accessToken, searchAgentId);

        if (searchAgentResult.status === ResponseStatus.OK) {

          setSearchAgent(searchAgentResult.data.data);

        } else {
          console.log("Fejl under hentning af søgeagenter");
          setErrorMessage("Fejl under hentning af søgeagenter");
        }
      }

      setPageReady(false);
    }

    fetchInitialInfo();
  }, []);

  const setAddress = (returnAddress) => {
    if (returnAddress.id.length > 0) {
      setSearchAgent({
        ...searchAgent,
        streetName: returnAddress.streetName,
        streetNumber: returnAddress.streetNumber,
        zipcode: returnAddress.zipCode,
        city: returnAddress.city,
        country: returnAddress.country
      })
    }
  }

  const setEstateTypes = (estateType) => {
    let estateTypes = searchAgent.estateTypes;

    if (estateTypes.includes(estateType)) {
      estateTypes = searchAgent.estateTypes.filter(e => e !== estateType);
    } else {
      estateTypes.push(estateType);
    }

    setSearchAgent({...searchAgent, estateTypes: estateTypes})
  }

  // Value determining if min or max has to change next time
  const [minMaxValues, setMinMaxValues] = React.useState([]);
  const setChoiceRange = (minType, maxType, value) => {

    let minMaxValuesCopy = minMaxValues.slice();

    if (!minMaxValues.includes(minType) && !minMaxValues.includes(maxType)) {
      minMaxValuesCopy.push(minType);
    }

    if (searchAgent[minType] <= 0 || searchAgent[maxType] <= 0 || searchAgent[minType] === "" || searchAgent[maxType] === "") {
      setSearchAgent({...searchAgent, [minType]: value, [maxType]: value});
    } else if (searchAgent[minType] === searchAgent[maxType] && searchAgent[minType] < value) {
      setSearchAgent({...searchAgent, [maxType]: value});
      minMaxValuesCopy = minMaxValuesCopy.map(e => e === maxType ? minType : e);
    } else if (searchAgent[minType] === searchAgent[maxType] && searchAgent[minType] > value) {
      setSearchAgent({...searchAgent, [minType]: value});
      minMaxValuesCopy = minMaxValuesCopy.map(e => e === minType ? maxType : e);
    } else if (searchAgent[minType] > value) {
      setSearchAgent({...searchAgent, [minType]: value});
      minMaxValuesCopy = minMaxValuesCopy.map(e => e === minType ? maxType : e);
    } else if (searchAgent[maxType] < value) {
      setSearchAgent({...searchAgent, [maxType]: value});
      minMaxValuesCopy = minMaxValuesCopy.map(e => e === maxType ? minType : e);
    } else if (searchAgent[minType] < searchAgent[maxType]) {
      if (minMaxValues.includes(minType)) {
        setSearchAgent({...searchAgent, [minType]: value});
        minMaxValuesCopy = minMaxValuesCopy.map(e => e === minType ? maxType : e);
      } else {
        setSearchAgent({...searchAgent, [maxType]: value});
        minMaxValuesCopy = minMaxValuesCopy.map(e => e === maxType ? minType : e);
      }
    }

    setMinMaxValues(minMaxValuesCopy)

  }

  const addUpdateSeachAgent = async () => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);
    //setIndicativeOfferValidation(initialIndicativeOfferValidation);

    if (newSearchAgent) {

      let searchAgentResult = await postSearchAgent(state.token.accessToken, searchAgent);

      if (searchAgentResult.status === ResponseStatus.OK) {

        history.push("/dashboard/sogeagenter/" + searchAgentResult.data.data.id);

      } else if (searchAgentResult.status === ResponseStatus.ERROR) {

        // Reset validations
        /*
        let indicativeOfferValidationCopy = copy(indicativeOfferValidation);
        for (let [key,] of Object.entries(indicativeOfferValidation)) {
          indicativeOfferValidationCopy[key] = [];
        }
        */

        // Set new errors
        /*
        for (let [key, value] of Object.entries(indicativeOfferResult.data.errors)) {
          for (let [, value2] of Object.entries(value.description)) {
            if (key in indicativeOfferValidationCopy)
              indicativeOfferValidationCopy[key].push(value2);
            else
              setErrorMessage(value2);
          }
        }
        */

        //setIndicativeOfferValidation(indicativeOfferValidationCopy);
        setErrorMessage("Fejl");
      }
    } else {
      let searchAgentResult = await putSearchAgent(state.token.accessToken, searchAgentId, searchAgent);

      if (searchAgentResult.status === ResponseStatus.OK) {

        setSuccessMessage("Success");

      } else if (searchAgentResult.status === ResponseStatus.ERROR) {

        // Reset validations
        /*
        let indicativeOfferValidationCopy = copy(indicativeOfferValidation);
        for (let [key,] of Object.entries(indicativeOfferValidation)) {
          indicativeOfferValidationCopy[key] = [];
        }
        */

        // Set new errors
        /*
        for (let [key, value] of Object.entries(indicativeOfferResult.data.errors)) {
          for (let [, value2] of Object.entries(value.description)) {
            if (key in indicativeOfferValidationCopy)
              indicativeOfferValidationCopy[key].push(value2);
            else
              setErrorMessage(value2);
          }
        }
        */

        //setIndicativeOfferValidation(indicativeOfferValidationCopy);
        setErrorMessage("Fejl");
      }
    }

    setLoading(false);

  }

  return pageReady ? <div>Loading</div> : (
    <div className="user-rating">

      <h1>Søgeagenter / {newSearchAgent ? "Opret ny søgeagent" : searchAgent.address.prettyDisplay.long}</h1>

      {
        errorMessage &&
        <div className="invalid-feedback">{errorMessage}</div>
      }
      {
        successMessage &&
        <div className="valid-feedback">{successMessage}</div>
      }

      <h4 className="form-header">1. I hvilket område vil du gerne bo?</h4>

      <SearchAddress searchFor={"cities"} value={(newSearchAgent ? '' : searchAgent.address.prettyDisplay.long)} setAddress={setAddress} showMarker={true} />

      <h4 className="form-header">2. Hvilke søgekriterier, skal din bolig indeholde?</h4>

      <div className="form-group">
        <label className="font-size-large font-weight-xbold mb-3">Boligtype</label>
        <ChoiceWrapper type="multi">
          <ChoiceButton active={searchAgent.estateTypes.includes("Villa")} onClick={() => setEstateTypes("Villa")}><IconHome />Villa</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Rækkehus")} onClick={() => setEstateTypes("Rækkehus")}><IconHome />Rækkehus</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Ejerlejlighed")} onClick={() => setEstateTypes("Ejerlejlighed")}><IconHome />Ejerlejlighed</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Andelsbolig")} onClick={() => setEstateTypes("Andelsbolig")}><IconAndelsbolig />Andelsbolig</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Fritidshus")} onClick={() => setEstateTypes("Fritidshus")}><IconHome />Fritidshus</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Fritidsgrund")} onClick={() => setEstateTypes( "Fritidsgrund")}><IconHome />Fritidsgrund</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Helårsgrund")} onClick={() => setEstateTypes( "Helårsgrund")}><IconHome />Helårsgrund</ChoiceButton>
          <ChoiceButton active={searchAgent.estateTypes.includes("Landejendom")} onClick={() => setEstateTypes("Landejendom")}><IconHome />Landejendom</ChoiceButton>
        </ChoiceWrapper>
      </div>

      <div className="form-group mt-5">
        <label className="font-size-large font-weight-xbold">Søgedetaljer</label>

        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Udbudpris</label>
          <div className="form-inputs">
            <CustomInput type="number" name="minPrice" placeholder={"Min"} value={searchAgent.minPrice} onChange={(e) => setSearchAgent({...searchAgent, minPrice: e.target.value})} />
            <CustomInput type="number" name="maxPrice" placeholder={"Max"} value={searchAgent.maxPrice} onChange={(e) => setSearchAgent({...searchAgent, maxPrice: e.target.value})} />
          </div>
        </div>
        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Boligareal m<sup>2</sup></label>
          <div className="form-inputs">
            <CustomInput type="number" name="minLivingArea" placeholder={"Min"} value={searchAgent.minLivingArea} onChange={(e) => setSearchAgent({...searchAgent, minLivingArea: e.target.value})} />
            <CustomInput type="number" name="maxLivingArea" placeholder={"Max"} value={searchAgent.maxLivingArea} onChange={(e) => setSearchAgent({...searchAgent, maxLivingArea: e.target.value})} />
          </div>
        </div>

        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Værelser</label>
          <div className="form-inputs">
            <ChoiceWrapper type="range">
              <ChoiceButton active={searchAgent.minRooms === 1 || searchAgent.maxRooms === 1} onClick={() => setChoiceRange('minRooms','maxRooms',1)}>1</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 2 || searchAgent.maxRooms === 2} onClick={() => setChoiceRange('minRooms','maxRooms',2)}>2</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 3 || searchAgent.maxRooms === 3} onClick={() => setChoiceRange('minRooms','maxRooms',3)}>3</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 4 || searchAgent.maxRooms === 4} onClick={() => setChoiceRange('minRooms','maxRooms',4)}>4</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 5 || searchAgent.maxRooms === 5} onClick={() => setChoiceRange('minRooms','maxRooms',5)}>5</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 6 || searchAgent.maxRooms === 6} onClick={() => setChoiceRange('minRooms','maxRooms',6)}>6</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 7 || searchAgent.maxRooms === 7} onClick={() => setChoiceRange('minRooms','maxRooms',7)}>7</ChoiceButton>
              <ChoiceButton active={searchAgent.minRooms === 8 || searchAgent.maxRooms === 8} onClick={() => setChoiceRange('minRooms','maxRooms',8)}>8+</ChoiceButton>
            </ChoiceWrapper>
          </div>
        </div>
        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Plan</label>
          <div className="form-inputs">
            <ChoiceWrapper type="range">
              <ChoiceButton active={searchAgent.minFloors === 1 || searchAgent.maxFloors === 1} onClick={() => setChoiceRange('minFloors','maxFloors',1)}>1</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 2 || searchAgent.maxFloors === 2} onClick={() => setChoiceRange('minFloors','maxFloors',2)}>2</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 3 || searchAgent.maxFloors === 3} onClick={() => setChoiceRange('minFloors','maxFloors',3)}>3</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 4 || searchAgent.maxFloors === 4} onClick={() => setChoiceRange('minFloors','maxFloors',4)}>4</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 5 || searchAgent.maxFloors === 5} onClick={() => setChoiceRange('minFloors','maxFloors',5)}>5</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 6 || searchAgent.maxFloors === 6} onClick={() => setChoiceRange('minFloors','maxFloors',6)}>6</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 7 || searchAgent.maxFloors === 7} onClick={() => setChoiceRange('minFloors','maxFloors',7)}>7</ChoiceButton>
              <ChoiceButton active={searchAgent.minFloors === 8 || searchAgent.maxFloors === 8} onClick={() => setChoiceRange('minFloors','maxFloors',8)}>8+</ChoiceButton>
            </ChoiceWrapper>
          </div>
        </div>
        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Etage</label>
          <div className="form-inputs">
            <ChoiceWrapper type="range">
              <ChoiceButton active={searchAgent.minPlan === 0 || searchAgent.maxPlan === 0} onClick={() => setChoiceRange('minPlan','maxPlan',0)}>Stue</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 1 || searchAgent.maxPlan === 1} onClick={() => setChoiceRange('minPlan','maxPlan',1)}>1. sal</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 2 || searchAgent.maxPlan === 2} onClick={() => setChoiceRange('minPlan','maxPlan',2)}>2. sal</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 3 || searchAgent.maxPlan === 3} onClick={() => setChoiceRange('minPlan','maxPlan',3)}>3. sal</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 4 || searchAgent.maxPlan === 4} onClick={() => setChoiceRange('minPlan','maxPlan',4)}>4. sal</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 5 || searchAgent.maxPlan === 5} onClick={() => setChoiceRange('minPlan','maxPlan',5)}>5. sal</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 6 || searchAgent.maxPlan === 6} onClick={() => setChoiceRange('minPlan','maxPlan',6)}>6. sal</ChoiceButton>
              <ChoiceButton active={searchAgent.minPlan === 7 || searchAgent.maxPlan === 7} onClick={() => setChoiceRange('minPlan','maxPlan',7)}>7. sal+</ChoiceButton>
            </ChoiceWrapper>
          </div>
        </div>
        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Byggeår</label>
          <div className="form-inputs">
            <CustomInput type="number" name="minConstructionYear" placeholder={"Fra"} value={searchAgent.minConstructionYear} onChange={(e) => setSearchAgent({...searchAgent, minConstructionYear: e.target.value})} />
            <CustomInput type="number" name="maxConstructionYear" placeholder={"Til"} value={searchAgent.maxConstructionYear} onChange={(e) => setSearchAgent({...searchAgent, maxConstructionYear: e.target.value})} />
          </div>
        </div>
        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Grundareal</label>
          <div className="form-inputs">
            <CustomInput type="number" name="minGroundArea" placeholder={"Min"} value={searchAgent.minGroundArea} onChange={(e) => setSearchAgent({...searchAgent, minGroundArea: e.target.value})} />
            <CustomInput type="number" name="maxGroundArea" placeholder={"Max"} value={searchAgent.maxGroundArea} onChange={(e) => setSearchAgent({...searchAgent, maxGroundArea: e.target.value})} />
          </div>
        </div>
        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Kælderareal</label>
          <div className="form-inputs">
            <CustomInput type="number" name="minBasementArea" placeholder={"Min"} value={searchAgent.minBasementArea} onChange={(e) => setSearchAgent({...searchAgent, minBasementArea: e.target.value})} />
            <CustomInput type="number" name="maxBasementArea" placeholder={"Max"} value={searchAgent.maxBasementArea} onChange={(e) => setSearchAgent({...searchAgent, maxBasementArea: e.target.value})} />
          </div>
        </div>

        <div className="form-group form-row">
          <label className="font-size-large font-weight-light">Energimærke</label>
          <div className="form-inputs">
            <ChoiceWrapper type="range">
              <ChoiceButton active={searchAgent.minEnergyLabel === "A" || searchAgent.maxEnergyLabel === "A"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"A")}><EnergyLabel label={"a"} /></ChoiceButton>
              <ChoiceButton active={searchAgent.minEnergyLabel === "B" || searchAgent.maxEnergyLabel === "B"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"B")}><EnergyLabel label={"b"} /></ChoiceButton>
              <ChoiceButton active={searchAgent.minEnergyLabel === "C" || searchAgent.maxEnergyLabel === "C"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"C")}><EnergyLabel label={"c"} /></ChoiceButton>
              <ChoiceButton active={searchAgent.minEnergyLabel === "D" || searchAgent.maxEnergyLabel === "D"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"D")}><EnergyLabel label={"d"} /></ChoiceButton>
              <ChoiceButton active={searchAgent.minEnergyLabel === "E" || searchAgent.maxEnergyLabel === "E"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"E")}><EnergyLabel label={"e"} /></ChoiceButton>
              <ChoiceButton active={searchAgent.minEnergyLabel === "F" || searchAgent.maxEnergyLabel === "F"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"F")}><EnergyLabel label={"f"} /></ChoiceButton>
              <ChoiceButton active={searchAgent.minEnergyLabel === "G" || searchAgent.maxEnergyLabel === "G"} onClick={() => setChoiceRange('maxEnergyLabel','minEnergyLabel',"G")}><EnergyLabel label={"g"} /></ChoiceButton>
            </ChoiceWrapper>
          </div>
        </div>
      </div>

      <div className="form-footer text-center">
        <button type="button" className={"btn btn-primary btn-large" + (readyForSubmit || loading ? "" : " disabled")} disabled={!readyForSubmit || loading} onClick={addUpdateSeachAgent}>{loading ? "Loading..." :  (newSearchAgent ? "Opret søgeagent" : "Gem søgeagent")}</button>
      </div>

    </div>
  )
}

export default UserRating;
