import React from 'react';

import './CustomAlert.css';
import {IconClose} from "../../Icons";

// First we create our class
const CustomAlert = (props) => {

	return (
		<div className={"custom-alert alert alert-" + props.type + " " + props.className}>
			{props.children}

			{
				props.close &&
				<button type="button" className="close" onClick={props.close}><IconClose /></button>
			}
		</div>
	)
}

export default CustomAlert;
