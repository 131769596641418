import React from "react";

import './ChoiceWrapper.css';
import {hasValue} from "../../utils";

export const LinkEmail = (props) => {

  return (
    <>
      {
        hasValue(props.email, "email") &&
        <a href={"mailto:" + props.email}>{props.email}</a>
      }

      {
        !hasValue(props.email) &&
        <span className="text-greyish">Ikke angivet</span>
      }
    </>
  );
};
export default LinkEmail;
