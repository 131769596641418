import React from "react";

import './EnergyLabel.css';

export const EnergyLabel = (props) => {

  return (
    <span className={"energy-label energy-label-" + props.label}></span>
  );
};
export default EnergyLabel;