import { callApi } from './base.js';

export const postHairdresserOffer = async (token, data) => {
  return callApi({
    url: "/hairdresser-offers",
    method: "POST",
    body: data,
    token: token,
  });
};

export const putHairdresserOffer = async (token, id, data) => {
  return callApi({
    url: "/hairdresser-offers/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const deleteHairdresserOffer = async (token, id) => {
  return callApi({
    url: "/hairdresser-offers/" + id,
    method: "DELETE",
    body: null,
    token: token,
  });
};
