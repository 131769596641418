import React from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext.js";
import facebookFill from '../../assets/images/icon/facebook-fill.svg';

import { ResponseStatus } from '../../models/responseStatus.js';
import { postUser } from "../../requests/user.js";
import { postToken } from "../../requests/auth.js";
import { copy } from "../../utils.js";
import { generateEmptyUser, initialUserValidation } from "../../types.js";
import CustomButton from "../elements/CustomButton";
import CustomInput from "../elements/CustomInput.js";
import CustomSelect from "../elements/CustomSelect";

export const CreateUser = ({success, cancel, goToLogin}) => {
  const [, dispatch] = React.useContext(AuthContext);

  const [user, setUser] = React.useState(generateEmptyUser());
  const [userValidation, setUserValidation] = React.useState(initialUserValidation);
  const [termsAndConditions, setTermsAndConditions] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const login = async () => {

    let loginResult = await postToken({ username: user.email, password: user.password });

    if (loginResult.status === ResponseStatus.OK) {

      dispatch({
        type: "LOGIN",
        payload: loginResult.data.data
      })

      success();

    } else {

      setErrorMessage("Fejl under log ind");

    }
  };

  const addUser = async (e) => {

    setLoading(true);
    setErrorMessage(null);
    setUserValidation(initialUserValidation);

    if (termsAndConditions && confirmPassword === user.password) {

      let userResult = await postUser(user);

      if (userResult.status === ResponseStatus.OK) {

        await login();

      } else if (userResult.status === ResponseStatus.ERROR) {

        // Reset validations
        let userValidationCopy = copy(userValidation);
        for (let [key,] of Object.entries(userValidation)) {
          userValidationCopy[key] = [];
        }

        // Set new errors
        for (let [key, value] of Object.entries(userResult.data.errors)) {
          for (let [, value2] of Object.entries(value.description)) {
            if (key in userValidationCopy)
              userValidationCopy[key].push(value2);
            else
              setErrorMessage(value2);
          }
        }

        setUserValidation(userValidationCopy);
      }

    } else {
      if (!termsAndConditions)
        setErrorMessage("Bekræft vores betingelser");
      else if (confirmPassword !== user.password) 
        setErrorMessage("Kodeordene er ikke ens");
    }

    setLoading(false);

  }

  return (
    <>

      {
        errorMessage &&
        <div className="invalid-feedback mb-3 font-size-large font-weight-bold">{errorMessage}</div>
      }

      <div className="form-group">
        <label>Fornavn</label>
        <CustomInput type="text" name="firstName" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} onEnterKeyUp={addUser} />
        {
          userValidation.firstName.map((item, i) =>
            <div key={i} className="invalid-feedback">{item}</div>
          )
        }
      </div>
      <div className="form-group">
        <label>Efternavn</label>
        <CustomInput type="text" name="lastName" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} onEnterKeyUp={addUser} />
        {
          userValidation.lastName.map((item, i) =>
            <div key={i} className="invalid-feedback">{item}</div>
          )
        }
      </div>
      <div className="form-group">
        <label>Email</label>
        <CustomInput type="text" name="email" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} onEnterKeyUp={addUser} />
        {
          userValidation.email.map((item, i) =>
            <div key={i} className="invalid-feedback">{item}</div>
          )
        }
      </div>
      <div className="form-group">
        <label>Brugertype</label>
        <CustomSelect value={user.role} options={[{value: "user", label: "Bruger"},{value: "professional", label: "Frisør"}]} onChange={(role) => setUser({ ...user, role: role })} />
        {
          userValidation.role.map((item, i) =>
            <div key={i} className="invalid-feedback">{item}</div>
          )
        }
      </div>
      <div className="form-group">
        <label>Kodeord</label>
        <CustomInput type="password" name="password" value={user.password} onChange={(e) => setUser({ ...user, password: e.target.value })} onEnterKeyUp={addUser} />
        {
          userValidation.password.map((item, i) =>
            <div key={i} className="invalid-feedback">{item}</div>
          )
        }
      </div>

      <div className="form-group">
        <label>Bekræft kodeord</label>
        <CustomInput type="password" onChange={(e) => setConfirmPassword(e.target.value)} onEnterKeyUp={addUser} />
        {
          confirmPassword !== user.password && confirmPassword !== "" &&
          <div className="invalid-feedback">Kodeordene er ikke ens</div>
        }
      </div>

      <div className="form-group">
        <label className="form-check">
          <Link to="/betingelser" target="_blank" rel="noopener noreferrer">Accepter betingelser</Link>
          <input className="form-check-input" type="checkbox" checked={termsAndConditions} onChange={(e) => setTermsAndConditions(e.target.checked)} />
          <span className="checkmark"></span>
        </label>
      </div>

      <div className="text-center mb-3">
        <CustomButton type={"primary"} disabled={loading} loading={loading} onClick={addUser} className="btn-large mt-auto" >Opret bruger</CustomButton>
      </div>

      <div className="text-center">
        <small className="font-size-small font-weight-light">Allerede bruger? <span className="link" onClick={goToLogin}>Log ind</span></small>
      </div>

      {
        /*
        <a className="create-by-facebook" href="#">
          <div className="icon">
            <img src={facebookFill} alt="facebook" />
          </div>
          <span>Opret med facebook</span>
        </a>
         */
      }

    </>
  );
};
export default CreateUser;
