import React from 'react';

import './LoadingPage.css';
import {faHome, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// a basic form
const LoadingPage = (props) => {

  return (
    <>
      {
        !props.isPageReady &&
        <div className="d-flex flex-column align-items-center">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" color="#8562A2" />
          <span className="mt-3">Henter indhold</span>
        </div>
      }
      {
        props.isPageReady && props.deleted && !props.hide &&
          <div>
            Siden eksisterer ikke mere.
          </div>
      }
      {
        props.isPageReady && props.notFound && !props.hide &&
        <div>
          Siden blev ikke fundet.
        </div>
      }
      {
        props.isPageReady && !props.deleted && !props.notFound &&
        props.children
      }
    </>
  );
}

export default LoadingPage;
