import React from 'react';

import './HomeHairdressers.css';

import background from "../../assets/images/content/beauty.jpg";
import {Link} from "react-router-dom";

export const HomeHairdressers = () => {

  return (
    <div className="home-hairdressers content-page">
      <div className="content-body container">
        <div className="row">
          <div className="col-md-6">

            <h1>For Frisører</h1>
            <p className="mb-4 font-size-large font-weight-light">Velkommen til Frisørsiden. Vi stræber efter at blive danmarks førende guide til frisører, stylister og resten af beauty verdenen i Danmark.</p>
            <p className="mb-5 font-size-large font-weight-light">Vores vision er at skabe et mødested for kunder og professionelle inden for branchen, hvor vi gør det nemt for søgende at finde hvad de mangler og professionelle at komme ud til brugeren.</p>

            <div className="mb-5 box box-primary box-padding box-shadow box-wider">
              <h3 className="font-weight-xbold font-size-xlarge text-uppercase mb-3">Gratis oprettelse:</h3>
              <ul>
                <li>Det koster ikke noget at oprette en profil på Frisørsiden</li>
                <li>Der er ingen skjulte omkostninger</li>
                <li>Bindingsperioder findes ikke</li>
              </ul>
            </div>

            <h2>Hvorfor Frisørsiden?</h2>

            <p className="mb-4 font-size-large font-weight-light">Der kan være mange årsager til, at man ønsker at prøve en ny frisør. Nogen skifter jævnligt, blot for at få en frisk pust, mens andre måske er flyttet til en ny by. F.eks. flytter der hvert år ca. 60.000 mennesker til og fra københavn.</p>
            <p className="mb-5 font-size-large font-weight-light">Vi tror på at vi kan være med til at give forbrugerne det overblik der skal til for at få ro i maven, allerede før besøget. Det vil gøre via vores filtrerings system, som hjælper forbrugeren med at navigere. Når filtreringen er slut, kan forbrugeren nemt og hurtigt få et overblik over de forskellige frisører som matcher forbrugernes ønsker, samt se hvilken kvalitet de kan forvente hos de forskellige.</p>

            <h2>Jeres opgaver</h2>
            <p className="mb-4 font-size-large font-weight-light">Jeres opgaver er at holde jeres profil opdateret med f.eks. priser, tilbud og jeres færdigheder. På den måde sikrer i, at jeres profil er effektiv og bliver vist på de bedst mulige kriterier, som passer jer.</p>
            <p className="mb-5 font-size-large font-weight-light">Vi er dog forståelige med, at det kan være svært at finde tid til at holde alle medier opdateret og det er derfor også helt okay at tage det i de mængder man nu ønsker. Vi anbefaler dog, at man udfylder ens firma-profil bedst muligt.</p>

            <h2>Vi markedsføre os på to fronter</h2>

            <ul className="mb-5">
              <li className="font-size-large font-weight-light">Vi holder løbende konkurrencer for vores kundeklub i samarbejde med frisørerne på siden, som bliver vist via online annoncering på facebook og instagram. Vi sørger for kunderne har noget at blive for, og engagerer dem også til at rate deres oplevelser hos de forskellige saloner.</li>
              <li className="font-size-large font-weight-light">Vi bruger ressourcer på anden fysisk reklame på gymnasier, videregående uddannelser, og i udvalgte butikker/cafeer rundt omkring i de større byer i Danmark.</li>
            </ul>

            <h2>Vores fremtid</h2>
            <p className="mb-4 font-size-large font-weight-light">Vi vil løbende opdaterer siden, så i kan få endnu flere muligheder for at markedsføre jer på Frisørsiden. Vi arbejder bla. på Instagram links, Visitkort, og meget mere endnu.</p>
            <p className="mb-4 font-size-large font-weight-light">Opret en bruger, så hjælper vi dig med at blive set.</p>
            <p className="mb-4 font-size-large font-weight-light">Hvis I har nogen spørgsmål, idéer eller andet, så kan i altid ringe eller skrive til os via vores <Link to="/kontakt">kontaktinformationer</Link>.</p>
          </div>
        </div>
      </div>
      <div className="image-sideways image-sideways-right image image-cover">
        <img src={background} alt="For frisører" />
      </div>
    </div>
  )
}

export default HomeHairdressers;
