import {callApi} from './base.js';

export const postToken = async (data) => {

    return callApi({
      url: "/tokens",
      method: "POST",
      body: data,
      token: null
    });
  };
