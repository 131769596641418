import React from 'react';

import './Contact.css';

import background from '../../assets/images/content/contact.jpg';

export const Contact = () => {

  return (
    <div className="contact content-page">
      <div className="content-body container">
        <div className="row">
          <div className="col-md-6">

            <h1>Kontakt</h1>
            <p>Har du spørgsmål er du mere end velkommen til at kontakte os.</p>
            <p className="mb-4">Vi hører også gerne fra dig, hvis vi har overset noget. Om det er en frisør vi mangler eller en god ide til, hvordan vi kan gøre siden endnu bedre!</p>

            <a href="mailto:kontakt@frisorsiden.dk" className="font-weight-light font-size-normal">kontakt@frisorsiden.dk</a>
            {
              /*
              <a href="tel:53506186" className="text-normal text-decoration-none font-weight-light font-size-normal">Tlf. nr.: 53 50 61 86</a>
              */
            }

            <h4 className="font-size-large font-weight-bold text-dark mt-4 mb-0">Frisørsiden</h4>
            <span className="font-size-normal font-weight-light">Nybrovej 304, L-11</span>
            <span className="font-size-normal font-weight-light">2800, Kongens Lyngby</span>
            <span className="font-size-normal font-weight-light">Danmark</span>

          </div>
        </div>
      </div>
      <div className="image-sideways image-sideways-right image image-cover">
        <img src={background} alt="Kontakt" />
      </div>
    </div>
  )
}

export default Contact;
