import React from 'react';

import './Modal.css';

// First we create our class
class Modal extends React.Component {
	
	// Then we add our constructor which receives our props
	constructor(props) {
    super(props);
  }

	render() {
		return (
			<div className={(this.props.show ? "modal-open " : "") + "modal " + (this.props.className)}>
        <div className="modal-background" onClick={this.props.close}></div>
        <div className="modal-content">
          {this.props.children}
        </div>
			</div>
		)
	}
}

export default Modal;
