import React from 'react';
import { useComponentVisible } from '../../Helpers';

import './SearchAddress.css';
import { getCities, getAddresses } from '../../requests/dawa.js';
import {IconMapPointer} from "../../Icons";

export const SearchAddress = (props) => {

  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);

  const [keyword, setKeyword] = React.useState(props.value)
  const [suggestions, setSuggestions] = React.useState([]);

  /*
  React.useEffect(() => {
    setKeyword(props.value)
  }, []);
  */

  const fetchAddresses = async (e) => {
    
    let newKeyword = e.target.value;

    setKeyword(newKeyword);

    if (newKeyword.length > 1) {
      let addressResponse = props.searchFor === "cities" ? await getCities(newKeyword) : await getAddresses(newKeyword);

      setSuggestions(addressResponse);
      setIsComponentVisible(true)
    } else {
      setSuggestions([]);
    }
  }

  const setAddress = async (idx) => {
    
    let address = suggestions[idx];
    setKeyword(address.tekst);
    setSuggestions([]);

    console.log(address)
    let returnAddress = props.searchFor === "cities"
      ?
        {
          id: address.postnummer.nr,
          streetName: "",
          streetNumber: "",
          streetPlan: "",
          streetDoor: "",
          zipCode: address.postnummer.nr,
          city: address.postnummer.navn,
          country: "Danmark",
          latitude: address.postnummer.visueltcenter_y,
          longitude: address.postnummer.visueltcenter_x
        }
      :
        {
          id: address.adresse.id,
          streetName: address.adresse.vejnavn,
          streetNumber: address.adresse.husnr,
          streetPlan: address.adresse.etage,
          streetDoor: address.adresse.dør,
          zipCode: address.adresse.postnr,
          city: address.adresse.postnrnavn,
          country: "Danmark",
          latitude: address.adresse.y,
          longitude: address.adresse.x
        };

    props.setAddress(returnAddress)
  }
  
  return (
    <div className="search-address">
      <div className="form-group">
        <div className="search-address-input">
          <label className={"search-address-marker" + (props.showMarker ? " show-marker" : "")} htmlFor="search-address-input"><IconMapPointer /></label>
          <input id="search-address-input" type="text" className={"form-control" + (suggestions.length > 0 && isComponentVisible ? " open" : "") + (props.showMarker ? " padding-left-marker" : "")} autoComplete="q" value={keyword} placeholder={props.placeholder} onChange={fetchAddresses} />
        </div>
        {
          suggestions.length > 0 && isComponentVisible &&
          <ul ref={ref} >
            {
              isComponentVisible &&
              suggestions.map((suggestion, idx) => {
                return props.searchFor === "cities"
                  ? <li key={ suggestion.postnummer.nr } onClick={() => setAddress(idx)}>{ suggestion.tekst }</li>
                  : <li key={ suggestion.adresse.id } onClick={() => setAddress(idx)}>{ suggestion.tekst }</li>
                }
              )
            }
          </ul>
        }
      </div>
    </div>
  );
}

export default SearchAddress
