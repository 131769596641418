import React from 'react';
import './RangeInput.css';

import { formatNumber } from '../../utils';

export const RangeInput = props => {

  const lowerSlider = (e) => {
    let lowerVal = parseInt(e.target.value);
    let upperVal = props.upperVal;

    if (lowerVal > upperVal)
      upperVal = lowerVal;

    props.handleChange(lowerVal, upperVal);
  };

  const upperSlider = (e) => {
    let lowerVal = props.lowerVal;
    let upperVal = parseInt(e.target.value);

    if (lowerVal > upperVal)
      lowerVal = upperVal;

    props.handleChange(lowerVal, upperVal);
  };

  return (
    <div className="multi-range" style={{ width: props.width }}>
      <input type="range" min={props.min} max={props.max} value={props.lowerVal} onChange={(e) => lowerSlider(e)} />
      <input type="range" min={props.min} max={props.max} value={props.upperVal} onChange={(e) => upperSlider(e)} />

      <span className="range-input-min">{formatNumber(props.lowerVal)} kr.</span>
      <span className="range-input-max">{formatNumber(props.upperVal)}{props.upperVal == props.max ? "+" : ""} kr.</span>
    </div>
  )
}

export default RangeInput;
