import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { AuthContext } from "../../contexts/AuthContext.js";

const PrivateRoute = ({ component: Component, ...rest }) => {

  const [state, _] = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props => {
          return state.isAuthenticated ? (
            <Component {...props} showUserMenu={rest.showUserMenu} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
      }
    />
  )
}

export default PrivateRoute
