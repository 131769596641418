import React from "react";
import {Link} from "react-router-dom";

import profileImagePlaceholder from '../../assets/images/general/avatar.jpg';
import {hasValue} from "../../utils";
import RatingIcons from "../elements/RatingIcons";

export const Rating = ({rating, showLocation}) => {

  return (
    <>
      <div className="hairdresser-rating">
        <div className="hairdresser-rating-user">
          <div className="hairdresser-rating-user-image image image-contain">
            <img src={hasValue(rating.userProfileImage) ? rating.userProfileImage : profileImagePlaceholder} alt="Profilbillede"/>
          </div>
          <div className="hairdresser-rating-info">
            <div className="hairdresser-rating-user-name">
              {
                showLocation
                  ?
                  <Link className={"text-normal"} to={"/frisor/"+rating.hairdresserLocationUrl}>{rating.hairdresserLocationName}</Link>
                  :
                  rating.userName
              }
            </div>
            <div className="hairdresser-rating-score">
              <RatingIcons rating={rating}></RatingIcons>
              {rating.hairdresserCategoryName}
            </div>
          </div>
        </div>

        <div className="hairdresser-rating-message">
          {rating.message}
        </div>
      </div>
    </>
  );
};
export default Rating;
