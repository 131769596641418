import React from "react";

import CustomButton from "../elements/CustomButton";
import Modal from "../elements/Modal";

import { confirmable, createConfirmation } from "react-confirm";

const Confirmation = (
  {
    proceedLabel,
    cancelLabel,
    title,
    confirmation,
    show,
    proceed,
    enableEscape = true
  }) => {

  return (
    <Modal show={show} close={() => proceed(false)}>

      <div className="modal-header d-flex">
        <div className="flex-grow-1">
          <h3>Er du sikker?</h3>
        </div>
        <div className="modal-close" onClick={() => proceed(false)}></div>
      </div>

      <div className="modal-body" dangerouslySetInnerHTML={{ __html: confirmation }} />

      <div className="modal-footer">
        <CustomButton type="secondary" onClick={() => proceed(false)}>{cancelLabel}</CustomButton>
        <CustomButton type="primary" onClick={() => proceed(true)}>
          {proceedLabel}
        </CustomButton>
      </div>

    </Modal>
  );
}

export function confirm(
  confirmation,
  proceedLabel = "Ja",
  cancelLabel = "Nej",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    proceedLabel,
    cancelLabel,
    ...options
  });
}
