import React from 'react';

import './CustomSwitch.css';

// a basic form
const CustomSwitch = ({active, onClick}) => {
  return (
    <label className={"custom-switch" + (active ? " custom-switch-active" : "")} onClick={() => onClick(!active)}>
      <span className="custom-switch-slider"></span>
    </label>
  );
};

export default CustomSwitch;
