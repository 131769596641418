import React from 'react';

// First we create our class
class AddEstates extends React.Component {
	
	// Then we add our constructor which receives our props
	constructor(props) {
		super(props);
	}
	
	// The render function, where we actually tell the browser what it should show
	render() {
		return (
			<div>
				AddEstates
			</div>
		)
	}
}

export default AddEstates;
