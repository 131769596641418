import { callApi } from './base.js';

export const getHairdresserLocations = async (body) => {
  return callApi({
    url: "/hairdresser-locations",
    method: "GET",
    body: body,
    token: null,
  });
};

export const getHairdresserLocationsByAuthUser = async (token) => {
  return callApi({
    url: "/hairdresser-locations/auth",
    method: "GET",
    body: null,
    token: token,
  });
};

export const getHairdresserLocation = async (url) => {
  return callApi({
    url: "/hairdresser-locations/" + url,
    method: "GET",
    body: null,
    token: null,
  });
};

export const getHairdresserLocationByUrlAndAuthUser = async (token, url) => {
  return callApi({
    url: "/hairdresser-locations/auth/" + url,
    method: "GET",
    body: null,
    token: token,
  });
};

export const postHairdresserLocation = async (token, data) => {
  return callApi({
    url: "/hairdresser-locations",
    method: "POST",
    body: data,
    token: token,
  });
};

export const putHairdresserLocation = async (token, id, data) => {
  return callApi({
    url: "/hairdresser-locations/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const deleteHairdresserLocation = async (token, id) => {
  return callApi({
    url: "/hairdresser-locations/" + id,
    method: "DELETE",
    body: null,
    token: token,
  });
};
