import React from 'react';

import './Dashboard.css';
import LoadingPage from "../elements/LoadingPage";

export const Dashboard = (props) => {

  const [isPageReady, setIsPageReady] = React.useState(true);

  return (

    <div className="dashboard">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h1>Velkommen til dit kontrolpanel</h1>
        </div>
      </div>

      <LoadingPage isPageReady={isPageReady}>

        <p>
          Kontrolpanelet er lidt tomt i øjeblikket, men vi arbejder på at få flere funktioner implementeret.
        </p>

      </LoadingPage>
    </div>
  )
}

export default Dashboard;
