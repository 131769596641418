import React from "react";
import {Link, useHistory} from "react-router-dom";
import CustomInput from "../elements/CustomInput.js";
import Modal from '../elements/Modal.js';

import { AuthContext } from "../../contexts/AuthContext.js";
import facebookFill from '../../assets/images/icon/facebook-fill.svg';

import { ResponseStatus } from '../../models/responseStatus.js';
import { postToken } from "../../requests/auth.js";

export const Login = (props) => {
  let history = useHistory();
  const [authcontext, dispatch] = React.useContext(AuthContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const handleLogin = async () => {

    setLoading(true);
    setErrorMessage(null);

    let loginResult = await postToken({ username: email, password: password });

    if (loginResult.status === ResponseStatus.OK) {

      dispatch({
        type: "LOGIN",
        payload: loginResult.data.data
      })

      props.close();
      history.push("/dashboard");

    } else {

      setErrorMessage("Vi kunne desværre ikke finde nogen bruger, som matcher det indtastede");

    }

    setLoading(false);
  }

  return (
    <Modal show={props.show} close={props.close}>

      <div className="modal-header d-flex">
        <div className="flex-grow-1">
          <h3>Login</h3>
        </div>
        <div className="modal-close" onClick={props.close}></div>
      </div>

      {
        errorMessage &&
        <div className="invalid-feedback mb-3 font-size-large font-weight-bold">{errorMessage}</div>
      }

      <div className="form-group">
        <label>Email</label>
        <CustomInput type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} onEnterKeyUp={handleLogin} />
      </div>
      <div className="form-group">
        <label>Kodeord</label>
        <CustomInput type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} onEnterKeyUp={handleLogin} />
      </div>

      <div className="text-center mb-3">
        <button className="btn btn-large btn-primary mt-auto" disabled={loading} onClick={handleLogin}>{loading ? "Loading..." : "Log ind"}</button>
      </div>

      <div className="text-center">
        <small className="font-size-small font-weight-light">Glemt kodeordet? <span className="link" onClick={() => {props.close(); props.forgotPassword();}}>Få et nyt</span></small><br />
        <small className="font-size-small font-weight-light">Mangler du en bruger? <span className="link" onClick={() => {props.close(); props.createUser();}}>Opret en</span></small>
      </div>

      {
        /*
        <a className="create-by-facebook" href="#">
          <div className="icon">
            <img src={facebookFill} alt="facebook" />
          </div>
          <span>Opret med facebook</span>
        </a>
        */
      }
    </Modal>
  );
};
export default Login;
