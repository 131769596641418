import React from 'react';
import {useParams} from "react-router";
import {Link, useHistory} from "react-router-dom";

import SearchAddress from "../../elements/SearchAddress";
import {IconAndelsbolig, IconEdit, IconHome, IconInfo} from "../../../Icons";
import ChoiceButton from "../../elements/ChoiceButton";
import CustomInput from "../../elements/CustomInput";
import ChoiceWrapper from "../../elements/ChoiceWrapper";
import EnergyLabel from "../../elements/EnergyLabel";
import {AuthContext} from "../../../contexts/AuthContext";
import {ResponseStatus} from "../../../models/responseStatus";

import './ConnectedCompany.css';
import {copy, hasValue} from "../../../utils";
import {getHairdresser, postHairdresser, putHairdresser} from "../../../requests/hairdresser";


const initialHairdresser = {
  name: "",
  description: "",
  certified: false,
  homepage: "",
  url: ""
}

export const ConnectedCompany = (props) => {
  let history = useHistory();
  const [state,] = React.useContext(AuthContext);
  let { companySlug } = useParams();
  let hairdresserSlug = companySlug;

  const newHairdresser = !hairdresserSlug;

  const [pageReady, setPageReady] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [readyForSubmit, setReadyForSubmit] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const [hairdresser, setHairdresser] = React.useState(initialHairdresser);
  const [locations, setLocations] = React.useState([]);

  React.useEffect(() => {
    async function fetchInitialInfo() {
      if (!newHairdresser) {
        let hairdresserResult = await getHairdresser(hairdresserSlug);

        if (hairdresserResult.status === ResponseStatus.OK) {

          setHairdresser(hairdresserResult.data.data);
          setLocations(hairdresserResult.data.data.hairdresserLocations);

        } else {
          console.log("Fejl under hentning af virksomhed");
          setErrorMessage("Fejl under hentning af virksomhed");
        }
      }

      setPageReady(false);
    }

    fetchInitialInfo();
  }, [hairdresserSlug]);

  const addHairdresser = async () => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserResult = await postHairdresser(state.token.accessToken, hairdresser);

    if (hairdresserResult.status === ResponseStatus.OK) {

      history.push("/dashboard/virksomheder/" + hairdresserResult.data.data.url);

    } else if (hairdresserResult.status === ResponseStatus.ERROR) {

      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const updateHairdresser = async () => {

    let hairdresserResult = await putHairdresser(state.token.accessToken, hairdresser.id, hairdresser);

    if (hairdresserResult.status === ResponseStatus.OK) {

      setSuccessMessage("Success");

    } else if (hairdresserResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);

  }

  return pageReady ? <div>Loading</div> : (
    <div className="connected-company">

      <h1>Virksomhed / {newHairdresser ? "Opret ny virksomhed" : hairdresser.name}</h1>

      {
        errorMessage &&
        <div className="invalid-feedback">{errorMessage}</div>
      }
      {
        successMessage &&
        <div className="valid-feedback">{successMessage}</div>
      }


      <h4 className="form-header">Primære oplysninger om virksomheden</h4>

      <div className="form-group">
        <label className="font-size-large font-weight-xbold mb-3">Navn</label>
        <CustomInput type={"text"} name="name" placeholder={"Virksomhedens navn"} value={hairdresser.name} onChange={(e) => setHairdresser({...hairdresser, name: e.target.value})} />
      </div>

      <div className="form-group">
        <label className="font-size-large font-weight-xbold mb-3">Beskrivelse</label>
        <textarea className={"form-control"} type={"text"} name="description" placeholder={"Beskrivelse"} value={hairdresser.description} onChange={(e) => setHairdresser({...hairdresser, description: e.target.value})} />
      </div>

      <div className="form-group">
        <label className="font-size-large font-weight-xbold mb-3">Certificeret (0 = nej, 1 = ja)</label>
        <CustomInput type={"number"} name="certified" placeholder={"0 for nej / 1 for ja"} value={hairdresser.certified ? 1 : 0} onChange={(e) => setHairdresser({...hairdresser, certified: e.target.value == 1})} />
      </div>

      <div className="form-group">
        <label className="font-size-large font-weight-xbold mb-3">Homepage</label>
        <CustomInput type={"text"} name="homepage" placeholder={"Virksomhedens hjemmeside"} value={hairdresser.homepage} onChange={(e) => setHairdresser({...hairdresser, homepage: e.target.value})} />
      </div>

      <div className="form-footer text-center">
        <button type="button" className={"btn btn-primary btn-large" + (readyForSubmit || loading ? "" : " disabled")} disabled={!readyForSubmit || loading} onClick={newHairdresser ? addHairdresser : updateHairdresser}>{loading ? "Loading..." :  (newHairdresser ? "Opret virksomhed" : "Gem virksomhed")}</button>
      </div>


      <h2>Lokationer</h2>

      <div>
        <Link className="btn btn-primary" to={"/dashboard/virksomheder/"+hairdresser.url+"/filialer/opret"}>Opret ny lokation</Link>
      </div>

      <div className="locations locations-header mb-3">
        <div className="locations-active">
        </div>
        <div className="locations-name">
          Navn
        </div>
        <div className="locations-estate-type">
          Boligtype
        </div>
        <div className="locations-price">
          Udbudspris
        </div>
        <div className="location-area">
          Boligareal
        </div>
        <div className="location-actions">
        </div>
      </div>

      {
        hasValue(locations) &&
        locations.map(location =>
          <div key={location.id} className="box box-shadow location mb-3">
            <div className="location-name">
              {location.name}
            </div>
            <div className="location-estate-type">

            </div>
            <div className="location-price">

            </div>
            <div className="location-area">

            </div>
            <div className="location-actions">
              <Link to={"/dashboard/virksomheder/"+hairdresser.url+"/filialer/"+location.url}><IconEdit /></Link>
              <a href="#"><IconInfo /></a>
            </div>
          </div>
        )
      }

    </div>
  )
}

export default ConnectedCompany;
