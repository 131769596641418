import { callApi, callApiUploadFile } from './base.js';

export const postImage = (token, data, files, callback) => {
  callApiUploadFile({
    url: "/images",
    method: "POST",
    body: data,
    files: files,
    token: token,
    callback: callback
  });
};

export const putImage = async (token, id, data) => {
  return callApi({
    url: "/images/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const deleteImage = async (token, id) => {
  return callApi({
    url: "/images/" + id,
    method: "DELETE",
    body: null,
    token: token,
  });
};
