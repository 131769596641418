import React from "react";
import {SortableContainer, SortableElement, SortableHandle, sortableHandle} from 'react-sortable-hoc';

import {copy, arrayMove, getKey} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAlt, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

import './Sortable.css';

const DragHandle = sortableHandle(() => <div className="drag-handle"><FontAwesomeIcon icon={faArrowsAlt} size="1x" color="#18121E" /></div>);

const SortableImage = SortableElement(({ item, removeItem }) => (
  <div key={getKey(item)} className="sortable-item col-6 col-lg-3 mb-3">
    <div className="image image-contain">
      <img src={item.url} title={item.title} alt={item.description} />
      <DragHandle />
      <button type="button" className={"btn btn-icon ml-2 remove-image"} onClick={() => removeItem(item)}><FontAwesomeIcon icon={faTrashAlt} size="1x" color="#D32D27" /></button>
    </div>
  </div>
));

const SortableItem = SortableElement(({ item }) => (
  <div key={getKey(item)}>
    <span>{item.title}</span>
  </div>
));

const SortableList = SortableContainer(({ items, type, removeItem }) => (
  <div className="row pt-3 pb-3">
    {
      type === 'images' &&
      items.map((item, index) => (
        <SortableImage
          key={item.id}
          index={index}
          item={item}
          removeItem={removeItem}
        />
      ))
    }
    {
      type === 'list' &&
      items.map((item, index) => (
        <SortableItem
          key={item.id}
          index={index}
          item={item}
        />
      ))
    }
  </div>
));

export const Sortable = (props) => {

  const onSortEnd = ({oldIndex, newIndex}) => {
    props.updateItems(oldIndex, newIndex);
  };

  return (
    <div className="sortable">
      <SortableList
        items={props.items}
        removeItem={props.removeItem}
        type={props.type}
        onSortEnd={onSortEnd}
        axis="xy"
        useDragHandle={true}
      />
    </div>
  )
}

export default Sortable;
