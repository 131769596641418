import React from 'react';
import {Link} from "react-router-dom";

import './Locations.css';
import {IconEdit, IconInfo, IconSearch} from "../../../../Icons";
import {getHairdresserLocationsByAuthUser, putHairdresserLocation} from "../../../../requests/hairdresserLocation";
import {AuthContext} from "../../../../contexts/AuthContext";
import {ResponseStatus} from "../../../../models/responseStatus";
import {hasValue, getKey} from "../../../../utils";
import {putSearchAgent} from "../../../../requests/searchAgent";
import CustomSwitch from "../../../elements/CustomSwitch";
import {faPencilAlt, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingPage from "../../../elements/LoadingPage";

export const Locations = () => {

  const [state,] = React.useContext(AuthContext);

  const [isPageReady, setIsPageReady] = React.useState(false)
  const [hairdresserLocations, setHairdresserLocations] = React.useState([]);

  React.useEffect(() => {
    async function fetchInitialInfo() {

      let hairdresserLocationResult = await getHairdresserLocationsByAuthUser(state.token.accessToken);

      if (hairdresserLocationResult.status === ResponseStatus.OK) {

        setHairdresserLocations(hairdresserLocationResult.data.data);

      } else {
        console.log("Fejl under hentning af søgeagenter");
      }

      setIsPageReady(true)
    }
    fetchInitialInfo();
  }, []);

  const toggleHairdresserLocations = async (hairdresserLocationId) => {

    let hairdresserLocationToUpdate = hairdresserLocations.find(e => e.id === hairdresserLocationId);
    hairdresserLocationToUpdate.active = !hairdresserLocationToUpdate.active;

    let hairdresserLocationResult = await putHairdresserLocation(state.token.accessToken, hairdresserLocationId, hairdresserLocationToUpdate);

    if (hairdresserLocationResult.status === ResponseStatus.OK) {

      setHairdresserLocations(hairdresserLocations.map(e => e.id === hairdresserLocationId ? hairdresserLocationResult.data.data : e))

    } else {
      console.log("Fejl under opdatering af frisør");
    }
  }

  return (
    <div className="connected-companies">

      <div className="d-flex">
        <div className="flex-grow-1">
          <h1>Dine lokationer</h1>
        </div>
        <div>
          <Link className="btn btn-primary" to="/dashboard/filialer/opret">Opret ny frisør</Link>
        </div>
      </div>

      <LoadingPage isPageReady={isPageReady}>
        <div className="locations locations-header mb-3">
          <div className="locations-active">

          </div>
          <div className="locations-name">
            Navn
          </div>
          <div className="locations-address">
            Adresse
          </div>
          <div className="locations-actions">
          </div>
        </div>

        {
          hasValue(hairdresserLocations) &&
          hairdresserLocations.map(hairdresserLocation =>
            <div key={getKey(hairdresserLocation)} className="box box-shadow locations mb-3">
              <div className="locations-active">
                <CustomSwitch active={hairdresserLocation.active} onClick={() => toggleHairdresserLocations(hairdresserLocation.id)} />
              </div>
              <div className="locations-name">
                {hairdresserLocation.name}
              </div>
              <div className="locations-address">
                {hairdresserLocation.streetName} {hairdresserLocation.streetNumber}, {hairdresserLocation.zipCode} {hairdresserLocation.city}
              </div>
              <div className="locations-actions">
                {
                  ((hasValue(hairdresserLocation.hairdresserUsers) && hairdresserLocation.hairdresserUsers[0].permission === 'write') || (state.token.role == "admin")) &&
                  <Link to={"/dashboard/filialer/"+hairdresserLocation.url}><FontAwesomeIcon icon={faPencilAlt} size="1x" color="#28a745" /></Link>
                }
                <Link to={"/frisor/"+hairdresserLocation.url} target="_blank"><FontAwesomeIcon icon={faEye} size="1x" color="#96989b" /></Link>
              </div>
            </div>
          )
        }
      </LoadingPage>
    </div>
  )
}

export default Locations;
