import React from 'react';
import {
  Link,
  useHistory
} from "react-router-dom";

import SearchAddress from '../elements/SearchAddress';

import './Home.css';

import background from '../../assets/images/content/cover1.jpg';
import goOnAdventure from '../../assets/images/content/go_on_advendure.jpg';

const initialAddress = {
  id: ''
}

export const Home = (props) => {
  let history = useHistory();

  let [address, setAddress] = React.useState(initialAddress);

  const searchAddress = (returnAddress) => {
    setAddress(returnAddress)
    submitAddress(returnAddress)
  }

  const submitAddress = (address) => {
    if (address.id.length > 0)
      history.push("/find-frisor?latitude=" + address.latitude + "&longitude=" + address.longitude);
    else
      history.push("/find-frisor");
  }

  return (
    <div className="home content-page mt-0">
      <div className="banner mb-0">
        <div className="banner-overlay banner-image image image-cover">
          <img src={background} alt="Frisørsiden" />
        </div>
        <div className="container">

          <div className="banner-content">
            <h1 className="mb-4">Er dit hår stukket af?</h1>
            <h2 className="mb-4">- find din næste frisør her</h2>
            <p className="font-size-large">Søg i hele Danmark og få et nemt og hurtigt overblik over alle frisører, stylister, beauty specialister m.fl. Leder du efter noget bestemt, kan du nemt filtrere i resultaterne.</p>
          </div>

          <div className="box box-shadow box-no-padding front-page-search">
            <div className="box-tab-content p-0">
              <SearchAddress searchFor={"cities"} value={""} setAddress={searchAddress} placeholder="Hvor leder du fra?" showMarker={true} />
              <button type="button" className="btn btn-primary btn-large" onClick={() => submitAddress(address)}>Søg nu</button>
            </div>
          </div>
        </div>
      </div>

      <div className="fluid-container text-center">

        <div className="how-does-it-work-items">
          <div className="row">

            <div className="col how-does-it-work-item text-center">
              <div className="how-does-it-work-item-cell">
                <span className="how-does-it-work-item-number">205</span>
                <h4 className="how-does-it-work-item-title">frisører på platformen</h4>
              </div>
            </div>

            <div className="col how-does-it-work-item text-center">
              <div className="how-does-it-work-item-cell">
                <span className="how-does-it-work-item-number">1052</span>
                <h4 className="how-does-it-work-item-title">saloner på platformen</h4>
              </div>
            </div>

            <div className="col how-does-it-work-item text-center">
              <div className="how-does-it-work-item-cell">
                <span className="how-does-it-work-item-number">421</span>
                <h4 className="how-does-it-work-item-title">behandlinger</h4>
              </div>
            </div>

            <div className="col how-does-it-work-item text-center">
              <div className="how-does-it-work-item-cell">
                <span className="how-does-it-work-item-number">24</span>
                <h4 className="how-does-it-work-item-title">besøgende sendt videre</h4>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container text-center join-today space-before">
        <div className="row space-before space-after">
          <div className="col-md-12 col-lg-6 text-left align-self-center content-col">
            <h2 className="h1 mb-4">Gå på opdagelse</h2>
            <p className="mb-4 font-size-large font-weight-light">Med Frisørsiden kan du nemt og bekvemt gå på opdagelse i din by og finde de frisører som passer dig. Hvad enten du bare skal have en hurtig tilpasning eller en større forvandling.</p>
            <p className="mb-4 font-size-large font-weight-light">Vores mål er at få alle frisører med i Danmark, så ingen bliver snydt. Mangler vi derfor en frisør, så høre vi gerne fra dig! :)</p>

            <Link to="/find-frisor" className="btn btn-large btn-primary mr-3 mb-3 mb-sm-0">Gå på opdagelse</Link>
            <Link to="/kontakt" className="btn btn-large btn-primary mb-3 mb-sm-0">Mangler vi en frisør?</Link>
          </div>
          <div className="col-md-12 col-lg-6 image-col">
            <div className="image image-contain frontpage-image">
              <img src={goOnAdventure} alt="Gå på opdagelse!" />
            </div>
          </div>
        </div>
      </div>

      {/*

      <div className="background-primary space-before-large space-after-large messenger">
        <div className="container">
          <div className="row">
            <div className="col-sm-12  col-lg-6 col-xl-5 text-left content-col">
              <h2 className="h1 mb-4">Tag direkte kontakt<br />til køber eller sælger</h2>
              <span className="coming-soon coming-soon-on-dark">Kommer snart</span>
              <p className="mb-4 font-size-large font-weight-light">Når du bruger Min Drømmebolig, er der ingen traditionelle ejendsomsmæglere der styrer købet eller salget. Her har du selv kontakten direkte med køber eller sælger, og når I får brug for hjælp til de nødvendige dokumenter, sidder vores boligrådgiver fra boligassistancen.dk klar til at hjælpe.</p>
              <button type="button" className="btn btn-large btn-primary">Læs mere her</button>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-7 image-col">
              <div className="image frontpage-image no-shadow">
                <img src={messenger} alt="Snak med køber eller sælger" className="no-shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container space-before-large space-after-large estate-match">
        <div className="row">
          <div className="col-md-12 col-lg-6 image-col">
            <div className="image image-contain frontpage-image">
              <img src={estateMatch} alt="BoligMatch" />
            </div>
          </div>
          <div className="col-md-12 col-lg-6 text-left align-self-center content-col">
            <h2 className="h1 mb-4">BoligMatch</h2>
            <span className="coming-soon coming-soon-on-light">Kommer snart</span>
            <p className="mb-4 font-size-large font-weight-light">Når du bruger Min Drømmebolig har du mulighed for at oprette en gratis profil, og når en sælger opretter en bolig som matcher dine ønsker, vil vi give dig besked. På den måde vil du altid kunne komme hurtigt i kontakt med sælger, så der ikke er en anden der køber boligen, lige for næsen af dig.</p>
            <button type="button" className="btn btn-large btn-primary">Læs mere her</button>
          </div>
        </div>
      </div>
      */}
      {/*
      <div className="background-secondary space-before space-after boligassistancen">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-6 text-left align-self-center content-col">
              <h2 className="h1 mb-4">Boligassistancen.dk</h2>
              <p className="mb-4 font-size-large font-weight-light">Når der skal bestilles rapporter og derefter aftales en handel, foregår det altid i trygge rammer med en boligrådgiver fra boligassistancen.dk, som med 5 ud af 5 stjerner på trustpilot er den perfekte rådgiver, når handlen skal afsluttes.</p>

              <a target="_blank" href="https://boligassistancen.dk/" className="btn btn-large btn-primary">Læs mere her</a>
            </div>

            <div className="col-md-12 col-lg-6 align-self-center image-col">
              <div className="image image-contain frontpage-image">
                <img src={boligassistancen} alt="Trustpilot" className="no-shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  )
}


export default Home;
