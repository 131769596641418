import React from 'react';
import Select, { components } from 'react-select';
import CustomInput from './CustomInput';

import './PhoneNumberInput.css';

const callingCodes = [
  { value: 44, label: 'UK (+44)', showLabel: '+44' },
  { value: 1, label: 'USA (+1)', showLabel: '+1' },
  { value: 213, label: 'Algeria (+213)', showLabel: '+213' },
  { value: 376, label: 'Andorra (+376)', showLabel: '+376' },
  { value: 244, label: 'Angola (+244)', showLabel: '+244' },
  { value: 1264, label: 'Anguilla (+1264)', showLabel: '+1264' },
  { value: 1268, label: 'Antigua & Barbuda (+1268)', showLabel: '+1268' },
  { value: 54, label: 'Argentina (+54)', showLabel: '+54' },
  { value: 374, label: 'Armenia (+374)', showLabel: '+374' },
  { value: 297, label: 'Aruba (+297)', showLabel: '+297' },
  { value: 61, label: 'Australia (+61)', showLabel: '+61' },
  { value: 43, label: 'Austria (+43)', showLabel: '+43' },
  { value: 994, label: 'Azerbaijan (+994)', showLabel: '+994' },
  { value: 1242, label: 'Bahamas (+1242)', showLabel: '+1242' },
  { value: 973, label: 'Bahrain (+973)', showLabel: '+973' },
  { value: 880, label: 'Bangladesh (+880)', showLabel: '+880' },
  { value: 1246, label: 'Barbados (+1246)', showLabel: '+1246' },
  { value: 375, label: 'Belarus (+375)', showLabel: '+375' },
  { value: 32, label: 'Belgium (+32)', showLabel: '+32' },
  { value: 501, label: 'Belize (+501)', showLabel: '+501' },
  { value: 229, label: 'Benin (+229)', showLabel: '+229' },
  { value: 1441, label: 'Bermuda (+1441)', showLabel: '+1441' },
  { value: 975, label: 'Bhutan (+975)', showLabel: '+975' },
  { value: 591, label: 'Bolivia (+591)', showLabel: '+591' },
  { value: 387, label: 'Bosnia Herzegovina (+387)', showLabel: '+387' },
  { value: 267, label: 'Botswana (+267)', showLabel: '+267' },
  { value: 55, label: 'Brazil (+55)', showLabel: '+55' },
  { value: 673, label: 'Brunei (+673)', showLabel: '+673' },
  { value: 359, label: 'Bulgaria (+359)', showLabel: '+359' },
  { value: 226, label: 'Burkina Faso (+226)', showLabel: '+226' },
  { value: 257, label: 'Burundi (+257)', showLabel: '+257' },
  { value: 855, label: 'Cambodia (+855)', showLabel: '+855' },
  { value: 237, label: 'Cameroon (+237)', showLabel: '+237' },
  { value: 1, label: 'Canada (+1)', showLabel: '+1' },
  { value: 238, label: 'Cape Verde Islands (+238)', showLabel: '+238' },
  { value: 1345, label: 'Cayman Islands (+1345)', showLabel: '+1345' },
  { value: 236, label: 'Central African Republic (+236)', showLabel: '+236' },
  { value: 56, label: 'Chile (+56)', showLabel: '+56' },
  { value: 86, label: 'China (+86)', showLabel: '+86' },
  { value: 57, label: 'Colombia (+57)', showLabel: '+57' },
  { value: 269, label: 'Comoros (+269)', showLabel: '+269' },
  { value: 242, label: 'Congo (+242)', showLabel: '+242' },
  { value: 682, label: 'Cook Islands (+682)', showLabel: '+682' },
  { value: 506, label: 'Costa Rica (+506)', showLabel: '+506' },
  { value: 385, label: 'Croatia (+385)', showLabel: '+385' },
  { value: 53, label: 'Cuba (+53)', showLabel: '+53' },
  { value: 90392, label: 'Cyprus North (+90392)', showLabel: '+90392' },
  { value: 357, label: 'Cyprus South (+357)', showLabel: '+357' },
  { value: 42, label: 'Czech Republic (+42)', showLabel: '+42' },
  { value: 45, label: 'Denmark (+45)', showLabel: '+45' },
  { value: 253, label: 'Djibouti (+253)', showLabel: '+253' },
  { value: 1809, label: 'Dominica (+1809)', showLabel: '+1809' },
  { value: 1809, label: 'Dominican Republic (+1809)', showLabel: '+1809' },
  { value: 593, label: 'Ecuador (+593)', showLabel: '+593' },
  { value: 20, label: 'Egypt (+20)', showLabel: '+20' },
  { value: 503, label: 'El Salvador (+503)', showLabel: '+503' },
  { value: 240, label: 'Equatorial Guinea (+240)', showLabel: '+240' },
  { value: 291, label: 'Eritrea (+291)', showLabel: '+291' },
  { value: 372, label: 'Estonia (+372)', showLabel: '+372' },
  { value: 251, label: 'Ethiopia (+251)', showLabel: '+251' },
  { value: 500, label: 'Falkland Islands (+500)', showLabel: '+500' },
  { value: 298, label: 'Faroe Islands (+298)', showLabel: '+298' },
  { value: 679, label: 'Fiji (+679)', showLabel: '+679' },
  { value: 358, label: 'Finland (+358)', showLabel: '+358' },
  { value: 33, label: 'France (+33)', showLabel: '+33' },
  { value: 594, label: 'French Guiana (+594)', showLabel: '+594' },
  { value: 689, label: 'French Polynesia (+689)', showLabel: '+689' },
  { value: 241, label: 'Gabon (+241)', showLabel: '+241' },
  { value: 220, label: 'Gambia (+220)', showLabel: '+220' },
  { value: 7880, label: 'Georgia (+7880)', showLabel: '+7880' },
  { value: 49, label: 'Germany (+49)', showLabel: '+49' },
  { value: 233, label: 'Ghana (+233)', showLabel: '+233' },
  { value: 350, label: 'Gibraltar (+350)', showLabel: '+350' },
  { value: 30, label: 'Greece (+30)', showLabel: '+30' },
  { value: 299, label: 'Greenland (+299)', showLabel: '+299' },
  { value: 1473, label: 'Grenada (+1473)', showLabel: '+1473' },
  { value: 590, label: 'Guadeloupe (+590)', showLabel: '+590' },
  { value: 671, label: 'Guam (+671)', showLabel: '+671' },
  { value: 502, label: 'Guatemala (+502)', showLabel: '+502' },
  { value: 224, label: 'Guinea (+224)', showLabel: '+224' },
  { value: 245, label: 'Guinea - Bissau (+245)', showLabel: '+245' },
  { value: 592, label: 'Guyana (+592)', showLabel: '+592' },
  { value: 509, label: 'Haiti (+509)', showLabel: '+509' },
  { value: 504, label: 'Honduras (+504)', showLabel: '+504' },
  { value: 852, label: 'Hong Kong (+852)', showLabel: '+852' },
  { value: 36, label: 'Hungary (+36)', showLabel: '+36' },
  { value: 354, label: 'Iceland (+354)', showLabel: '+354' },
  { value: 91, label: 'India (+91)', showLabel: '+91' },
  { value: 62, label: 'Indonesia (+62)', showLabel: '+62' },
  { value: 98, label: 'Iran (+98)', showLabel: '+98' },
  { value: 964, label: 'Iraq (+964)', showLabel: '+964' },
  { value: 353, label: 'Ireland (+353)', showLabel: '+353' },
  { value: 972, label: 'Israel (+972)', showLabel: '+972' },
  { value: 39, label: 'Italy (+39)', showLabel: '+39' },
  { value: 1876, label: 'Jamaica (+1876)', showLabel: '+1876' },
  { value: 81, label: 'Japan (+81)', showLabel: '+81' },
  { value: 962, label: 'Jordan (+962)', showLabel: '+962' },
  { value: 7, label: 'Kazakhstan (+7)', showLabel: '+7' },
  { value: 254, label: 'Kenya (+254)', showLabel: '+254' },
  { value: 686, label: 'Kiribati (+686)', showLabel: '+686' },
  { value: 850, label: 'Korea North (+850)', showLabel: '+850' },
  { value: 82, label: 'Korea South (+82)', showLabel: '+82' },
  { value: 965, label: 'Kuwait (+965)', showLabel: '+965' },
  { value: 996, label: 'Kyrgyzstan (+996)', showLabel: '+996' },
  { value: 856, label: 'Laos (+856)', showLabel: '+856' },
  { value: 371, label: 'Latvia (+371)', showLabel: '+371' },
  { value: 961, label: 'Lebanon (+961)', showLabel: '+961' },
  { value: 266, label: 'Lesotho (+266)', showLabel: '+266' },
  { value: 231, label: 'Liberia (+231)', showLabel: '+231' },
  { value: 218, label: 'Libya (+218)', showLabel: '+218' },
  { value: 417, label: 'Liechtenstein (+417)', showLabel: '+417' },
  { value: 370, label: 'Lithuania (+370)', showLabel: '+370' },
  { value: 352, label: 'Luxembourg (+352)', showLabel: '+352' },
  { value: 853, label: 'Macao (+853)', showLabel: '+853' },
  { value: 389, label: 'Macedonia (+389)', showLabel: '+389' },
  { value: 261, label: 'Madagascar (+261)', showLabel: '+261' },
  { value: 265, label: 'Malawi (+265)', showLabel: '+265' },
  { value: 60, label: 'Malaysia (+60)', showLabel: '+60' },
  { value: 960, label: 'Maldives (+960)', showLabel: '+960' },
  { value: 223, label: 'Mali (+223)', showLabel: '+223' },
  { value: 356, label: 'Malta (+356)', showLabel: '+356' },
  { value: 692, label: 'Marshall Islands (+692)', showLabel: '+692' },
  { value: 596, label: 'Martinique (+596)', showLabel: '+596' },
  { value: 222, label: 'Mauritania (+222)', showLabel: '+222' },
  { value: 269, label: 'Mayotte (+269)', showLabel: '+269' },
  { value: 52, label: 'Mexico (+52)', showLabel: '+52' },
  { value: 691, label: 'Micronesia (+691)', showLabel: '+691' },
  { value: 373, label: 'Moldova (+373)', showLabel: '+373' },
  { value: 377, label: 'Monaco (+377)', showLabel: '+377' },
  { value: 976, label: 'Mongolia (+976)', showLabel: '+976' },
  { value: 1664, label: 'Montserrat (+1664)', showLabel: '+1664' },
  { value: 212, label: 'Morocco (+212)', showLabel: '+212' },
  { value: 258, label: 'Mozambique (+258)', showLabel: '+258' },
  { value: 95, label: 'Myanmar (+95)', showLabel: '+95' },
  { value: 264, label: 'Namibia (+264)', showLabel: '+264' },
  { value: 674, label: 'Nauru (+674)', showLabel: '+674' },
  { value: 977, label: 'Nepal (+977)', showLabel: '+977' },
  { value: 31, label: 'Netherlands (+31)', showLabel: '+31' },
  { value: 687, label: 'New Caledonia (+687)', showLabel: '+687' },
  { value: 64, label: 'New Zealand (+64)', showLabel: '+64' },
  { value: 505, label: 'Nicaragua (+505)', showLabel: '+505' },
  { value: 227, label: 'Niger (+227)', showLabel: '+227' },
  { value: 234, label: 'Nigeria (+234)', showLabel: '+234' },
  { value: 683, label: 'Niue (+683)', showLabel: '+683' },
  { value: 672, label: 'Norfolk Islands (+672)', showLabel: '+672' },
  { value: 670, label: 'Northern Marianas (+670)', showLabel: '+670' },
  { value: 47, label: 'Norway (+47)', showLabel: '+47' },
  { value: 968, label: 'Oman (+968)', showLabel: '+968' },
  { value: 680, label: 'Palau (+680)', showLabel: '+680' },
  { value: 507, label: 'Panama (+507)', showLabel: '+507' },
  { value: 675, label: 'Papua New Guinea (+675)', showLabel: '+675' },
  { value: 595, label: 'Paraguay (+595)', showLabel: '+595' },
  { value: 51, label: 'Peru (+51)', showLabel: '+51' },
  { value: 63, label: 'Philippines (+63)', showLabel: '+63' },
  { value: 48, label: 'Poland (+48)', showLabel: '+48' },
  { value: 351, label: 'Portugal (+351)', showLabel: '+351' },
  { value: 1787, label: 'Puerto Rico (+1787)', showLabel: '+1787' },
  { value: 974, label: 'Qatar (+974)', showLabel: '+974' },
  { value: 262, label: 'Reunion (+262)', showLabel: '+262' },
  { value: 40, label: 'Romania (+40)', showLabel: '+40' },
  { value: 7, label: 'Russia (+7)', showLabel: '+7' },
  { value: 250, label: 'Rwanda (+250)', showLabel: '+250' },
  { value: 378, label: 'San Marino (+378)', showLabel: '+378' },
  { value: 239, label: 'Sao Tome & Principe (+239)', showLabel: '+239' },
  { value: 966, label: 'Saudi Arabia (+966)', showLabel: '+966' },
  { value: 221, label: 'Senegal (+221)', showLabel: '+221' },
  { value: 381, label: 'Serbia (+381)', showLabel: '+381' },
  { value: 248, label: 'Seychelles (+248)', showLabel: '+248' },
  { value: 232, label: 'Sierra Leone (+232)', showLabel: '+232' },
  { value: 65, label: 'Singapore (+65)', showLabel: '+65' },
  { value: 421, label: 'Slovak Republic (+421)', showLabel: '+421' },
  { value: 386, label: 'Slovenia (+386)', showLabel: '+386' },
  { value: 677, label: 'Solomon Islands (+677)', showLabel: '+677' },
  { value: 252, label: 'Somalia (+252)', showLabel: '+252' },
  { value: 27, label: 'South Africa (+27)', showLabel: '+27' },
  { value: 34, label: 'Spain (+34)', showLabel: '+34' },
  { value: 94, label: 'Sri Lanka (+94)', showLabel: '+94' },
  { value: 290, label: 'St. Helena (+290)', showLabel: '+290' },
  { value: 1869, label: 'St. Kitts (+1869)', showLabel: '+1869' },
  { value: 1758, label: 'St. Lucia (+1758)', showLabel: '+1758' },
  { value: 249, label: 'Sudan (+249)', showLabel: '+249' },
  { value: 597, label: 'Suriname (+597)', showLabel: '+597' },
  { value: 268, label: 'Swaziland (+268)', showLabel: '+268' },
  { value: 46, label: 'Sweden (+46)', showLabel: '+46' },
  { value: 41, label: 'Switzerland (+41)', showLabel: '+41' },
  { value: 963, label: 'Syria (+963)', showLabel: '+963' },
  { value: 886, label: 'Taiwan (+886)', showLabel: '+886' },
  { value: 7, label: 'Tajikstan (+7)', showLabel: '+7' },
  { value: 66, label: 'Thailand (+66)', showLabel: '+66' },
  { value: 228, label: 'Togo (+228)', showLabel: '+228' },
  { value: 676, label: 'Tonga (+676)', showLabel: '+676' },
  { value: 1868, label: 'Trinidad & Tobago (+1868)', showLabel: '+1868' },
  { value: 216, label: 'Tunisia (+216)', showLabel: '+216' },
  { value: 90, label: 'Turkey (+90)', showLabel: '+90' },
  { value: 7, label: 'Turkmenistan (+7)', showLabel: '+7' },
  { value: 993, label: 'Turkmenistan (+993)', showLabel: '+993' },
  { value: 1649, label: 'Turks & Caicos Islands (+1649)', showLabel: '+1649' },
  { value: 688, label: 'Tuvalu (+688)', showLabel: '+688' },
  { value: 256, label: 'Uganda (+256)', showLabel: '+256' },
  { value: 380, label: 'Ukraine (+380)', showLabel: '+380' },
  { value: 971, label: 'United Arab Emirates (+971)', showLabel: '+971' },
  { value: 598, label: 'Uruguay (+598)', showLabel: '+598' },
  { value: 7, label: 'Uzbekistan (+7)', showLabel: '+7' },
  { value: 678, label: 'Vanuatu (+678)', showLabel: '+678' },
  { value: 379, label: 'Vatican City (+379)', showLabel: '+379' },
  { value: 58, label: 'Venezuela (+58)', showLabel: '+58' },
  { value: 84, label: 'Vietnam (+84)', showLabel: '+84' },
  { value: 84, label: 'Virgin Islands - British (+1284)', showLabel: '+1284' },
  { value: 84, label: 'Virgin Islands - US (+1340)', showLabel: '+1340' },
  { value: 681, label: 'Wallis & Futuna (+681)', showLabel: '+681' },
  { value: 969, label: 'Yemen (North)(+969)', showLabel: '+969' },
  { value: 967, label: 'Yemen (South)(+967)', showLabel: '+967' },
  { value: 260, label: 'Zambia (+260)', showLabel: '+260' },
  { value: 263, label: 'Zimbabwe (+263)', showLabel: '+263' },
];



const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.showLabel}
  </components.SingleValue>
);

// a basic form
const PhoneNumberInput = (props) => {

  let [viewHeight, setViewHeight] = React.useState(0);
  let [viewWidth, setViewWidth] = React.useState(0);

  React.useEffect(() => {
    function setDimensions() {
      setViewHeight(window.innerHeight)
      setViewWidth(window.innerWidth)
    }
    setDimensions()
    window.addEventListener('resize', setDimensions);
    return () => window.removeEventListener('resize', setDimensions);
  });

  let isSearchable = viewWidth >= 767;

  return (
    <div className="phone-number-input">
      <Select
        styles={{
          indicatorSeparator: () => { }, // removes the "stick"
          dropdownIndicator: base => ({
            ...base,
            '& svg': { display: 'none' }
          }),
          menu: (base, state) => {

            if (viewWidth < 767) {
              return {
                ...base,
                top: "calc(" + viewHeight + "px - 190px)",
                left: "0",
                position: "fixed",
                zIndex: 9999,
                margin: 0,
                borderRadius: 0,
                height: "190px"
              }
            } else {
              return {
                ...base,
                zIndex: 9999,
                width: "240px"
              }
            }
          },
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? "#8562A2" : "#ffffff",
            "&:hover": {
              backgroundColor: state.isSelected ? "#8562A2" : "#cdafe5"
            },
            "&:focus": {
              backgroundColor: state.isSelected ? "#8562A2" : "#cdafe5"
            },
            "&:active": {
              backgroundColor: state.isSelected ? "#8562A2" : "#cdafe5"
            }
          })
        }}
        className="select"
        value={callingCodes.find(e => e.value === props.phoneCallingCode)}
        options={callingCodes}
        onChange={(selectedOption) => props.onChange(selectedOption.value, props.phoneNumber)}
        isSearchable={false}
        components={{ SingleValue }}
        /*defaultMenuIsOpen={true}*/
      />
      <CustomInput type="number"
        name="phoneNumber"
        value={props.phoneNumber || ''}
        onChange={(e) => props.onChange(props.phoneCallingCode, e.target.value)}
        onEnterKeyUp={props.onEnterKeyUp} />
    </div>
  )
};

export default PhoneNumberInput;
