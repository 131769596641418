import { callApi } from './base.js';

export const getHairdresserCategories = async (body) => {
  return callApi({
    url: "/hairdresser-categories",
    method: "GET",
    body: body,
    token: null,
  });
};
