import { callApi } from './base.js';

export const getHairdressers = async () => {
  return callApi({
    url: "/hairdressers",
    method: "GET",
    body: null,
    token: null,
  });
};

export const getHairdressersByAuthUser = async (token) => {
  return callApi({
    url: "/hairdressers/auth",
    method: "GET",
    body: null,
    token: token,
  });
};

export const getHairdresser = async (url) => {
  return callApi({
    url: "/hairdressers/" + url,
    method: "GET",
    body: null,
    token: null,
  });
};

export const postHairdresser = async (token, data) => {
  return callApi({
    url: "/hairdressers",
    method: "POST",
    body: data,
    token: token,
  });
};

export const putHairdresser = async (token, id, data) => {
  return callApi({
    url: "/hairdressers/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const deleteHairdresser = async (token, id) => {
  return callApi({
    url: "/hairdressers/" + id,
    method: "DELETE",
    body: null,
    token: token,
  });
};
