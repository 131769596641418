import React from "react";

import './ChoiceWrapper.css';
import Modal from "./Modal";

export const CookiePopup = (props) => {

  let hasCookieConsent = localStorage.getItem("cookie_consent") || false;

  const [open, setOpen] = React.useState(true);

  const acceptConcent = () => {
    props.onAccept();
    setOpen(false);
  }

  const declineConcent = () => {
    setOpen(false);
  }

  return (
    <Modal show={!hasCookieConsent && open}>

      <div className="modal-header d-flex">
        <div className="flex-grow-1">
          <h3>Vi bruger cookies</h3>
          <p>Vi bruger cookies til analyse af brugsmønstret på hjemmesiden samt håndtering af intern logik. Accepter venligst brugen af cookies for at hjemmesiden virker korrekt.</p>
          <div className="d-flex">
            <button type="button" className="btn btn-secondary mr-3" onClick={declineConcent}>Afvis</button>
            <button type="button" className="btn btn-primary" onClick={acceptConcent}>Accepter</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CookiePopup;