import React from "react";
import { Link, useHistory } from "react-router-dom";
import Modal from '../elements/Modal.js';


export const Welcome = (props) => {
  let history = useHistory();

  const goToDashboard = () => {
    props.close();
    history.push("/dashboard");
  }

  return (
    <Modal show={props.show} close={props.close}>

      <div className="modal-header d-flex">
        <div className="flex-grow-1">
          <h3>Velkommen til Frisørsiden</h3>
        </div>
        <div className="modal-close" onClick={props.close}></div>
      </div>

      <div className="modal-body">
        <p>
          Tak fordi du, at du har valgt at oprette dig hos os.
        </p>
        <p>
          Du vil nu blive bedt om at verificere din bruger.<br />
          Til dette har vi sendt en mail med en 6-cifret kode, som du bedes indtaste, når du går til dit brugerpanel.
        </p>

        <button type="button" className="btn btn-primary" onClick={goToDashboard}>Gå til brugerpanel</button>
      </div>

      {
        /*
        <a className="create-by-facebook" href="#">
          <div className="icon">
            <img src={facebookFill} alt="facebook" />
          </div>
          <span>Opret med facebook</span>
        </a>
         */
      }

    </Modal>
  );
};
export default Welcome;