import React from "react";
import Modal from '../elements/Modal.js';

import './CodeConfirm.css';

const initialCode = {
  part1: "",
  part2: "",
  part3: "",
  part4: "",
  part5: "",
  part6: ""
}

export const CodeConfirm = (props) => {

  if (props.initialCode) {
    initialCode.part1 = props.initialCode.substring(0, 1);
    initialCode.part2 = props.initialCode.substring(1, 2);
    initialCode.part3 = props.initialCode.substring(2, 3);
    initialCode.part4 = props.initialCode.substring(3, 4);
    initialCode.part5 = props.initialCode.substring(4, 5);
    initialCode.part6 = props.initialCode.substring(5, 6);
  }
  const [code, setCode] = React.useState(initialCode);

  const part1 = React.useRef(null);
  const part2 = React.useRef(null);
  const part3 = React.useRef(null);
  const part4 = React.useRef(null);
  const part5 = React.useRef(null);
  const part6 = React.useRef(null);
  const submit = React.useRef(null);

  React.useEffect(() => {
    if (props.show) {
      part1.current.focus();
    }
  }, [props.show]);

  function validateOnlyOneNumber(e, part) {

    var val = e.target.value;

    var regex = /[0-9]/;

    if (regex.test(val) && val.length < 2) {
      switch (part) {
        case 1:
          setCode({...code, part1: val});
          part2.current.focus();
          break;
        case 2:
          setCode({...code, part2: val});
          part3.current.focus();
          break;
        case 3:
          setCode({...code, part3: val});
          part4.current.focus();
          break;
        case 4:
          setCode({...code, part4: val});
          part5.current.focus();
          break;
        case 5:
          setCode({...code, part5: val});
          part6.current.focus();
          break;
        case 6:
          setCode({...code, part6: val});
          submit.current.focus();
          break;
      }

    }
  }

  return (
    <Modal show={props.show} className={"code-confirm " + props.className}>

      <div className="text-center">
        <div className="code-confirm-icon">{props.icon}</div>

        <h3 className="font-weight-xbold" className="mb-2">{props.title}</h3>

        <p className="font-size-large font-weight-light mb-4">{props.children}</p>

        {
          props.errorMessage &&
          <div className="invalid-feedback">{props.errorMessage}</div>
        }
        {
          props.successMessage &&
          <div className="valid-feedback">{props.successMessage}</div>
        }

        <div className="code-confirm-input mb-4">
          <input ref={part1} className="form-control" type="text" value={code.part1} data-lpignore="true" onChange={(e) => validateOnlyOneNumber(e, 1)}/>
          <input ref={part2} className="form-control" type="text" value={code.part2} data-lpignore="true" onChange={(e) => validateOnlyOneNumber(e, 2)}/>
          <input ref={part3} className="form-control" type="text" value={code.part3} data-lpignore="true" onChange={(e) => validateOnlyOneNumber(e, 3)}/>
          <input ref={part4} className="form-control" type="text" value={code.part4} data-lpignore="true" onChange={(e) => validateOnlyOneNumber(e, 4)}/>
          <input ref={part5} className="form-control" type="text" value={code.part5} data-lpignore="true" onChange={(e) => validateOnlyOneNumber(e, 5)}/>
          <input ref={part6} className="form-control" type="text" value={code.part6} data-lpignore="true" onChange={(e) => validateOnlyOneNumber(e, 6)}/>
        </div>

        <div className="mb-3">
          {
            props.onCancel &&
            <button className="btn btn-large btn-secondary mt-auto mr-2 ml-2" disabled={props.loading} onClick={() => props.onCancel()}>{props.loading ? "Loading..." : "Tilbage"}</button>
          }
          <button ref={submit} className="btn btn-large btn-primary mt-auto mr-2 ml-2" disabled={props.loading} onClick={() => props.onSubmit("" + code.part1 + code.part2 + code.part3 + code.part4 + code.part5 + code.part6)}>{props.loading ? "Loading..." : "Bekræft"}</button>
        </div>

        {
          props.resend &&
          <span className="font-size-small font-weight-light">Har du ikke modtaget nogen kode? <span className="link font-weight-light" onClick={props.resend}>Send igen</span></span>
        }

      </div>
    </Modal>
  );
};
export default CodeConfirm;