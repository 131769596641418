const APIURL = 'https://dawa.aws.dk';

//https://dawa.aws.dk/postnumre?q=strand
///stednavne2/autocomplete?hovedtype=Bebyggelse&undertype=by&q=' + keyword + '&fuzzy=');
export const getCities = async keyword => {
  try {
    const response = await fetch(APIURL + '/postnumre/autocomplete?q=' + keyword + '&fuzzy=');
    return response.json();

  } catch (error) {
    return "Error fetching dawa API";
  }
};

export const getAddresses = async keyword => {
  try {
    const response = await fetch(APIURL + '/adresser/autocomplete?q=' + keyword + '&fuzzy=');
    return response.json();

  } catch (error) {
    return "Error fetching dawa API";
  }
};

export const getAddress = async addressId => {
  try {
    const response = await fetch(APIURL + '/adresser/' + addressId);
    return response.json();

  } catch (error) {
    return "Error fetching dawa API";
  }
};

export const getBbr = async addressId => {
  try {
    const response = await fetch(APIURL + '/bbrlight/enheder?adresseid=' + addressId);
    return response.json();

  } catch (error) {
    return "Error fetching dawa API";
  }
};

export const getEstateType = ENH_ANVEND_KODE => {
  return ENH_ANVEND_KODE === 110 ? "Stuehus til landbrugsejendom" :
  ENH_ANVEND_KODE === 120 ? "Fritliggende enfamilieshus (parcelhus)" :
  ENH_ANVEND_KODE === 130 ? "Række-, kæde-, dobbelthus (lodret adskillelse mellem enhederne)" :
  ENH_ANVEND_KODE === 131 ? "Række- og kædehus" :
  ENH_ANVEND_KODE === 132 ? "Dobbelthus" :
  ENH_ANVEND_KODE === 140 ? "Bolig i etageejendom, flerfamiliehus eller to-familiehus" :
  ENH_ANVEND_KODE === 150 ? "Kollegium" :
  ENH_ANVEND_KODE === 160 ? "Boligbygning til døgninstitution" :
  ENH_ANVEND_KODE === 185 ? "Anneks i tilknytning til helårsbolig" :
  ENH_ANVEND_KODE === 190 ? "Anden bygning til helårsbeboelse" :
  ENH_ANVEND_KODE === 210 ? "Bygning til erhvervsmæssig produktion vedrørende landbrug, gartneri, råstofudvinding o.lign." :
  ENH_ANVEND_KODE === 211 ? "Stald til svin" :
  ENH_ANVEND_KODE === 212 ? "Stald til kvæg, får mv." :
  ENH_ANVEND_KODE === 213 ? "Stald til fjerkræ" :
  ENH_ANVEND_KODE === 214 ? "Minkhal" :
  ENH_ANVEND_KODE === 215 ? "Væksthus" :
  ENH_ANVEND_KODE === 216 ? "Lade til foder, afgrøder mv." :
  ENH_ANVEND_KODE === 217 ? "Maskinhus, garage mv." :
  ENH_ANVEND_KODE === 218 ? "Lade til halm, hø mv." :
  ENH_ANVEND_KODE === 219 ? "Anden bygning til landbrug mv." :
  ENH_ANVEND_KODE === 220 ? "Bygning til erhvervsmæssig produktion vedrørende industri, håndværk m.v. (fabrik, værksted o.lign.)" :
  ENH_ANVEND_KODE === 221 ? "Bygning til industri med integreret produktionsapparat" :
  ENH_ANVEND_KODE === 222 ? "Bygning til industri uden integreret produktionsapparat" :
  ENH_ANVEND_KODE === 223 ? "Værksted" :
  ENH_ANVEND_KODE === 229 ? "Anden bygning til produktion" :
  ENH_ANVEND_KODE === 230 ? "El-, gas-, vand- eller varmeværk forbrændingsanstalt m.v." :
  ENH_ANVEND_KODE === 231 ? "Bygning til energiproduktion" :
  ENH_ANVEND_KODE === 232 ? "Bygning til forsynings- og energidistribution" :
  ENH_ANVEND_KODE === 233 ? "Bygning til vandforsyning" :
  ENH_ANVEND_KODE === 234 ? "Bygning til håndtering af affald og spildevand" :
  ENH_ANVEND_KODE === 239 ? "Anden bygning til energiproduktion og -distribution" :
  ENH_ANVEND_KODE === 290 ? "Anden bygning til landbrug, industri etc." :
  ENH_ANVEND_KODE === 310 ? "Transport- og garageanlæg (fragtmandshal, lufthavnsbygning, banegårdsbygning, parkeringshus). Garage med plads til et eller to køretøjer registreres med anvendelseskode 910" :
  ENH_ANVEND_KODE === 311 ? "Bygning til jernbane- og busdrift" :
  ENH_ANVEND_KODE === 312 ? "Bygning til luftfart" :
  ENH_ANVEND_KODE === 313 ? "Bygning til parkerings- og transportanlæg" :
  ENH_ANVEND_KODE === 314 ? "Bygning til parkering af flere end to køretøjer i tilknytning til boliger" :
  ENH_ANVEND_KODE === 315 ? "Havneanlæg" :
  ENH_ANVEND_KODE === 319 ? "Andet transportanlæg" :
  ENH_ANVEND_KODE === 320 ? "Bygning til kontor, handel, lager herunder offentlig administration" :
  ENH_ANVEND_KODE === 321 ? "Bygning til kontor" :
  ENH_ANVEND_KODE === 322 ? "Bygning til detailhandel" :
  ENH_ANVEND_KODE === 323 ? "Bygning til lager" :
  ENH_ANVEND_KODE === 324 ? "Butikscenter" :
  ENH_ANVEND_KODE === 325 ? "Tankstation" :
  ENH_ANVEND_KODE === 329 ? "Anden bygning til kontor, handel og lager" :
  ENH_ANVEND_KODE === 330 ? "Bygning til hotel, restaurant, vaskeri, frisør og anden servicevirksomhed" :
  ENH_ANVEND_KODE === 331 ? "Hotel, kro og konferencecenter med overnatning" :
  ENH_ANVEND_KODE === 332 ? "Bed & breakfast mv." :
  ENH_ANVEND_KODE === 333 ? "Restaurant, café og konferencecenter uden overnatning" :
  ENH_ANVEND_KODE === 334 ? "Privat servicevirksomhed som frisør, vaskeri, netcafé mv." :
  ENH_ANVEND_KODE === 339 ? "Anden bygning til serviceerhverv" :
  ENH_ANVEND_KODE === 390 ? "Anden bygning til handel transport, handel etc." :
  ENH_ANVEND_KODE === 410 ? "Bygning til biograf, teater, erhvervsmæssig udstilling, bibliotek, museum, kirke o.lign." :
  ENH_ANVEND_KODE === 411 ? "Biograf, teater, koncertsted mv." :
  ENH_ANVEND_KODE === 412 ? "Museum" :
  ENH_ANVEND_KODE === 413 ? "Bibliotek" :
  ENH_ANVEND_KODE === 414 ? "Kirke eller anden bygning til trosudøvelse for statsanerkendte trossamfund" :
  ENH_ANVEND_KODE === 415 ? "Forsamlingshus" :
  ENH_ANVEND_KODE === 416 ? "Forlystelsespark" :
  ENH_ANVEND_KODE === 419 ? "Anden bygning til kulturelle formål" :
  ENH_ANVEND_KODE === 420 ? "Bygning til undervisning og forskning (skole, gymnasium, forskningslaboratorium o.lign.)." :
  ENH_ANVEND_KODE === 421 ? "Grundskole" :
  ENH_ANVEND_KODE === 422 ? "Universitet" :
  ENH_ANVEND_KODE === 429 ? "Anden bygning til undervisning og forskning" :
  ENH_ANVEND_KODE === 430 ? "Bygning til hospital, sygehjem, fødeklinik o.lign." :
  ENH_ANVEND_KODE === 431 ? "Hospital og sygehus" :
  ENH_ANVEND_KODE === 432 ? "Hospice, behandlingshjem mv." :
  ENH_ANVEND_KODE === 433 ? "Sundhedscenter, lægehus, fødeklinik mv." :
  ENH_ANVEND_KODE === 439 ? "Anden bygning til sundhedsformål" :
  ENH_ANVEND_KODE === 440 ? "Bygning til daginstitution " :
  ENH_ANVEND_KODE === 441 ? "Daginstitution" :
  ENH_ANVEND_KODE === 442 ? "Servicefunktion på døgninstitution" :
  ENH_ANVEND_KODE === 443 ? "Kaserne" :
  ENH_ANVEND_KODE === 444 ? "Fængsel, arresthus mv." :
  ENH_ANVEND_KODE === 449 ? "Anden bygning til institutionsformål" :
  ENH_ANVEND_KODE === 490 ? "Bygninger til anden institution, herunder kaserne, fængsel o.lign." :
  ENH_ANVEND_KODE === 510 ? "Sommerhus" :
  ENH_ANVEND_KODE === 520 ? "Bygning til ferieformål m.v., bortset fra sommerhus (feriekoloni, vandrehjem o.lign.) " :
  ENH_ANVEND_KODE === 521 ? "Feriecenter, center til campingplads, sommerlejr mv." :
  ENH_ANVEND_KODE === 522 ? "Bygning med ferielejligheder til erhvervsmæssig udlejning" :
  ENH_ANVEND_KODE === 523 ? "Bygning med ferielejligheder til eget brug" :
  ENH_ANVEND_KODE === 529 ? "Anden bygning til ferieformål" :
  ENH_ANVEND_KODE === 530 ? "Bygning i forbindelse med idrætsudøvelser (klubhus, idrætshal, svømmehal o.lign.)." :
  ENH_ANVEND_KODE === 531 ? "Klubhus i forbindelse med fritid og idræt" :
  ENH_ANVEND_KODE === 532 ? "Svømmehal" :
  ENH_ANVEND_KODE === 533 ? "Idrætshal" :
  ENH_ANVEND_KODE === 534 ? "Tribune i forbindelse med stadion" :
  ENH_ANVEND_KODE === 535 ? "Rideskole" :
  ENH_ANVEND_KODE === 539 ? "Anden bygning til idrætsformål" :
  ENH_ANVEND_KODE === 540 ? "Kolonihavehus" :
  ENH_ANVEND_KODE === 585 ? "Anneks i tilknytning til fritids- og sommerhus" :
  ENH_ANVEND_KODE === 590 ? "Anden bygning til fritidsformål" :
  ENH_ANVEND_KODE === 910 ? "Garage" :
  ENH_ANVEND_KODE === 920 ? "Carport" :
  ENH_ANVEND_KODE === 930 ? "Udhus" :
  ENH_ANVEND_KODE === 940 ? "Drivhus" :
  ENH_ANVEND_KODE === 950 ? "Fritliggende overdækning " :
  ENH_ANVEND_KODE === 960 ? "Fritliggende udestue" :
  ENH_ANVEND_KODE === 970 ? "Tiloversbleven landbrugsbygning" :
  ENH_ANVEND_KODE === 990 ? "Faldefærdig bygning" :
  ENH_ANVEND_KODE === 999 ? "Ukendt bygning" : "Ukendt ejendom";
}