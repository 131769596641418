import { API_URL, callApi } from './base.js';
import { ResponseStatus } from '../models/responseStatus.js';

export const getUsers = async (body) => {
  return callApi({
    url: "/users",
    method: "GET",
    body: body,
    token: null,
  });
};

export const getUser = async (url) => {
  return callApi({
    url: "/users/"+url,
    method: "GET",
    body: null,
    token: null,
  });
};

export const getUserByAuth = async (token) => {
  return callApi({
    url: "/users/auth",
    method: "GET",
    body: null,
    token: token,
  });
};

export const postUser = async data => {
  return callApi({
    url: "/users",
    method: "POST",
    body: data,
    token: null,
  });
};

export const putUser = async (token, id, data) => {
  return callApi({
    url: "/users/"+id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const validateUser = async data => {
  try {
    const response = await fetch(`${API_URL}/users/validate`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
      },
      body: JSON.stringify(data)
    });
    
    const responseJson = await response.json();
    return {
      status: 200 <= responseJson.statusCode && responseJson.statusCode < 300 ? ResponseStatus.OK : ResponseStatus.ERROR,
      data: responseJson,
    };
    
  } catch (error) {
    return "Error fetching API";
  }
};

export const deleteUser = async id => {
  try {
    const response = fetch(`${API_URL}/users/${id}`, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache"
    });
    return response;
  } catch (error) {
    return "Error fetching API";
  }
};

export const confirmUser = async (token, data) => {

  return callApi({
    url: "/users/confirm",
    method: "PUT",
    body: data,
    token: token,
  });
};

export const resendConfirmation = async (token) => {

  return callApi({
    url: "/users/resend-confirmation",
    method: "PUT",
    body: null,
    token: token,
  });
};

export const forgotPasword = async (data) => {

  return callApi({
    url: "/users/forgot-password",
    method: "PUT",
    body: data,
    token: null,
  });
};

export const resetPasword = async (data) => {

  return callApi({
    url: "/users/reset-password",
    method: "PUT",
    body: data,
    token: null,
  });
};
