import React from 'react';
import {copy, getKey} from "../utils";

export const ItemContext = React.createContext();

export const ItemContextProvider = props => {

  const add = (item) => {
    props.setItems([...props.items, copy(item)]);
  }

  const update = (item, key) => {
    for (let i = 0; i < props.items.length; i++) {
      if (getKey(props.items[i]) === key) {
        props.items[i] = item;
      }
    }
  }

  const remove = (item, key) => {

    console.log(JSON.parse(JSON.stringify(props.items)));

    for (let i = 0; i < props.items.length; i++) {
      if (getKey(props.items[i]) === key) {
        props.items.splice(i,1);
      }
    }
    console.log(JSON.parse(JSON.stringify(props.items)));
  }

  return (
    <ItemContext.Provider value={{items: props.items, add: add, update: update, remove: remove}}>
      {props.children}
    </ItemContext.Provider>
  );
};
