import React from "react";
import {Link, useHistory} from "react-router-dom";
import CustomInput from "../elements/CustomInput.js";
import Modal from '../elements/Modal.js';

import { AuthContext } from "../../contexts/AuthContext.js";
import facebookFill from '../../assets/images/icon/facebook-fill.svg';

import { ResponseStatus } from '../../models/responseStatus.js';
import { postToken } from "../../requests/auth.js";
import CustomButton from "../elements/CustomButton";

export const Login = ({success, cancel, goToCreateUser, goToForgotPassword}) => {
  let history = useHistory();
  const [authcontext, dispatch] = React.useContext(AuthContext);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const login = async () => {

    setLoading(true);
    setErrorMessage(null);

    let loginResult = await postToken({ username: email, password: password });

    if (loginResult.status === ResponseStatus.OK) {

      dispatch({
        type: "LOGIN",
        payload: loginResult.data.data
      })

      success();

    } else {

      setErrorMessage("Vi kunne desværre ikke finde nogen bruger, som matcher det indtastede");

    }

    setLoading(false);
  }

  return (
    <>
      {
        errorMessage &&
        <div className="invalid-feedback mb-3 font-size-large font-weight-bold">{errorMessage}</div>
      }

      <div className="form-group">
        <label>Email</label>
        <CustomInput type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} onEnterKeyUp={login} />
      </div>
      <div className="form-group">
        <label>Kodeord</label>
        <CustomInput type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} onEnterKeyUp={login} />
      </div>

      <div className="text-center mb-3">
        <CustomButton type={"primary"} disabled={loading} loading={loading} onClick={login} className="btn-large mt-auto" >Log ind</CustomButton>
      </div>

      <div className="text-center">
        <small className="font-size-small font-weight-light">Mangler du en bruger? <span className="link" onClick={goToCreateUser}>Opret en</span></small>
      </div>
    </>
  );
};
export default Login;
