import React from 'react';
import {Link} from "react-router-dom";

import './MapListing.css';

import {IconRatingEmtpy, IconRatingFull, IconRatingHalf} from "../../Icons";
import hairdresserPlaceholder from "../../assets/images/general/frisor-placeholder.png";
import {hasValue} from "../../utils";

export const MapListing = (props) => {

  let minPrice = 0;
  for (let i = 0; i < props.marker.hairdresserOffers.length; i++) {
    if (props.marker.hairdresserOffers[i].price < minPrice || minPrice === 0) {
      minPrice = props.marker.hairdresserOffers[i].price;
    }
  }

  return (
    <div className="map-listing">
      <div className="map-listing-cover image image-cover">
        <img src={hasValue(props.marker.images) ? props.marker.images[0].url : (props.marker.cover !== '' ? props.marker.cover : hairdresserPlaceholder)} alt={props.marker.name} />
      </div>
      <div className="maps-listing-content">
        <h2 className="maps-listing-title">{props.marker.name}</h2>
        <div className="maps-listing-rating">
          <div className="maps-listing-rating-icons">
            <IconRatingEmtpy />
            <IconRatingEmtpy />
            <IconRatingEmtpy />
            <IconRatingEmtpy />
            <IconRatingEmtpy />
          </div>
          <span className="maps-listing-rating-points">0</span> <span className="maps-listing-rating-max">(0)</span>
        </div>
        {/*
        <div className="maps-listing-text">
          <div dangerouslySetInnerHTML={{ __html: props.marker.description }} />
        </div>
        */}
        <div className="maps-listing-footer">
          <div className="maps-listing-price text-primary"><span>{minPrice > 0 ? "DKK " + minPrice + "+" : "Ingen pris"}</span></div>
          <div className="maps-listing-link">
            <Link className="btn btn-primary btn-small" to={"/frisor/"+props.marker.url}>Læs mere</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapListing;

