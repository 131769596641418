import React from 'react';
import {Editor} from "@tinymce/tinymce-react";

// a basic form
const CustomTextarea = (props) => {

  let [viewHeight, setViewHeight] = React.useState(0);
  let [viewWidth, setViewWidth] = React.useState(0);

  React.useEffect(() => {
    function setDimensions() {
      setViewHeight(window.innerHeight)
      setViewWidth(window.innerWidth)
    }
    setDimensions()
    window.addEventListener('resize', setDimensions);
    return () => window.removeEventListener('resize', setDimensions);
  });

  let hasSidebar = viewWidth >= 767;
  let textboxWidth = (viewWidth - 40) + 'px';
  if (hasSidebar) {
    textboxWidth = (viewWidth - 420) + 'px';
  }

  return (
    <Editor
      apiKey="zxa99hzklblix88eut4j86wpa2dsdrl1y99dhuz7aa22xibn"
      value={props.value}
      init={{
        height: 500,
        width: textboxWidth,
        menubar: false,
        plugins: [
          'advlist autolink lists link image',
          'charmap print preview anchor help',
          'searchreplace visualblocks code',
          'insertdatetime media table paste wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic | \
          alignleft aligncenter alignright | \
          bullist numlist outdent indent | help'
      }}
      onEditorChange={props.onChange}
    />
  );
}

export default CustomTextarea;
