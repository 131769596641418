import React from 'react';

export const AuthContext = React.createContext();

const initialState = {
  isAuthenticated: false,
  isAuthorized: false,
  token: null,
  loading: false,
  error: null
};

const isExpired = (token) => {
  return token.expiredAt <= Date.now();
}

const checkIfLoggedInAtLoad = () => {
  let tokenString = localStorage.getItem("mdb_auth") || null;
  
  if (tokenString !== null) {
    let token = JSON.parse(tokenString);
    
    if (isExpired(token)) {
      localStorage.removeItem('mdb_auth');
    } else {
      initialState.isAuthenticated = true;
      initialState.isAuthorized = token.isConfirmed;
      initialState.token = token;
    }
  }
}
checkIfLoggedInAtLoad();

const reducer = (state, action) => {
  
  switch (action.type) {

    case "CONFIRM":

      const token = state.token;
      const updatedToken = {
        ...token,
        isConfirmed: true
      };

      localStorage.setItem("mdb_auth", JSON.stringify(updatedToken));

      return {
        ...state,
        isAuthenticated: true,
        isAuthorized: true,
        token: updatedToken
      };

    case "LOGIN":
      const auth = {
        accessToken: action.payload.access_token,
        expiresIn: action.payload.expires_in,
        tokenType: action.payload.token_type,
        expiredAt: Date.now() + (action.payload.expires_in * 1000),
        refreshToken: "", //action.payload.refresh_token
        isConfirmed: action.payload.user.confirmation.confirmed,
        role: action.payload.user.role,
        name: action.payload.user.firstName + " " + action.payload.user.lastName,
        profileImage: action.payload.user.profileImage?.url
      };

      localStorage.setItem("mdb_auth", JSON.stringify(auth));
      
      return {
        ...state,
        isAuthenticated: true,
        isAuthorized: auth.isConfirmed,
        token: auth
      };

    case "LOGOUT":
      localStorage.removeItem('mdb_auth');
      
      return {
        ...state,
        isAuthenticated: false,
        isAuthorized: false,
        token: null
      };

    default:
      return state;
  }
};

export const AuthContextProvider = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={[state, dispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};
