import React from "react";

import CustomInput from "../elements/CustomInput.js";
import CodeConfirm from '../elements/CodeConfirm';
import Modal from '../elements/Modal.js';

import lockIcon from '../../assets/images/icon/mdb-lock.png';

import { forgotPasword, resetPasword } from "../../requests/user.js";
import { ResponseStatus } from '../../models/responseStatus.js';

export const ForgotPassword = (props) => {
  const [email, setEmail] = React.useState("");
  const [recoveryCode, setRecoveryCode] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const [step, setStep] = React.useState(1);

  const handleForgotPassword = async () => {

    setLoading(true);
    setErrorMessage(null);

    let forgotPasswordResult = await forgotPasword({ email: email });
    console.log(forgotPasswordResult)

    if (forgotPasswordResult.status === ResponseStatus.OK) {

      setStep(2);

    } else if (forgotPasswordResult.status === ResponseStatus.ERROR) {

      // Set new errors
      for (let [key, value] of Object.entries(forgotPasswordResult.data.errors)) {
        for (let [_, value2] of Object.entries(value.description)) {
            setErrorMessage(value2);
        }
      }

    } else {

      setErrorMessage("Der gik noget galt under kaldet til vores api - kontakt venligst vores support");

    }

    setLoading(false);
  }

  const handleConfirmation = async (code) => {
    setRecoveryCode(code)
    setStep(3);
  }

  const handleCancelation = async () => {
    setRecoveryCode("")
    setStep(1);
  }

  const handleResetPassword = async () => {
    
    setLoading(true);
    setErrorMessage(null);

    if (confirmPassword === password) {

      let resetPasswordResult = await resetPasword({ email: email, recoveryCode: recoveryCode, password: password });

      if (resetPasswordResult.status === ResponseStatus.OK) {

        setStep(4);

      } else if (resetPasswordResult.status === ResponseStatus.ERROR) {

        // Set new errors
        if (resetPasswordResult.data.errors) {
          for (let [key, value] of Object.entries(resetPasswordResult.data.errors)) {
            for (let [_, value2] of Object.entries(value.description)) {
              setErrorMessage(value2);
            }
          }
        }
        if (resetPasswordResult.data.error) {
          setErrorMessage(resetPasswordResult.data.error);
        }

      } else {

        setErrorMessage("Der gik noget galt under kaldet til vores api - kontakt venligst vores support");

      }

    } else {
      if (confirmPassword !== password)
        setErrorMessage("Kodeordene er ikke ens");
    }
    
    setLoading(false);
  }

  const close = () => {
    setStep(1)
    props.close();
  }
  const goToLogin = () => {
    setStep(1)
    props.close();
    props.login();
  }

  if (step === 1)
    return (
        <Modal show={props.show} close={close}>
          <div className="modal-header d-flex">
            <div className="flex-grow-1">
              <h3>Få et nyt kodeord</h3>
            </div>
            <div className="modal-close" onClick={close}></div>
          </div>

          <div>
            <p>Anmod om et nyt kodeord, ved at indtaste din email. Du vil kort efter modtage en mail med mere information.</p>

            {
              errorMessage &&
              <div className="invalid-feedback mb-3 font-size-large font-weight-bold">{errorMessage}</div>
            }

            <div className="form-group">
              <label>Email</label>
              <CustomInput type="text" value={email} onChange={(e) => setEmail(e.target.value)} onEnterKeyUp={handleForgotPassword}/>
            </div>

            <div className="text-center mb-3">
              <button className="btn btn-large btn-primary mt-auto" disabled={loading} onClick={handleForgotPassword}>{loading ? "Loading..." : "Videre >"}</button>
            </div>

            <div className="text-center">
              <small className="font-size-small font-weight-light">Blevet klogere? <span className="link" onClick={goToLogin}>Log ind</span></small>
            </div>
          </div>
        </Modal>
    );
  else if (step === 2)
    return (
        <CodeConfirm initialCode={recoveryCode} title={"Bekræft din kode"} show={props.show} icon={lockIcon} onSubmit={handleConfirmation} onCancel={handleCancelation} loading={loading} errorMessage={errorMessage}>
          En mail er blevet sendt til den indtastede<br />
          email, <span className={"text-underline"}>antaget at den findes</span>.<br />
          Indtast koden du modtager for at fortsætte.
        </CodeConfirm>
    );
  else if (step === 3)
    return (
        <Modal show={props.show} close={close}>
          <div className="modal-header d-flex">
            <div className="flex-grow-1">
              <h3>Få et nyt kodeord</h3>
            </div>
            <div className="modal-close" onClick={close}></div>
          </div>
          <div>
            <p>En mail er blevet sendt til den indtastede email, antaget at den findes. Indtast koden du modtager og vælg et nyt kodeord.</p>

            {
              errorMessage &&
              <div className="invalid-feedback mb-3 font-size-large font-weight-bold">{errorMessage}</div>
            }

            <div className="form-group">
              <label>Nyt kodeord</label>
              <CustomInput type="password" value={password} onChange={(e) => setPassword(e.target.value)} onEnterKeyUp={handleResetPassword} />
            </div>

            <div className="form-group">
              <label>Bekræft kodeord</label>
              <CustomInput type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} onEnterKeyUp={handleResetPassword} />
              {
                confirmPassword !== password && confirmPassword !== "" &&
                <div className="invalid-feedback">Kodeordene er ikke ens</div>
              }
            </div>
            <div className="text-center mb-3">
              <button className="btn btn-large btn-secondary mt-auto mr-1" disabled={loading} onClick={() => setStep(2)}>{loading ? "Loading..." : "Tilbage"}</button>
              <button className="btn btn-large btn-primary mt-auto ml-1" disabled={loading} onClick={handleResetPassword}>{loading ? "Loading..." : "Ændre kodeord"}</button>
            </div>

            <div className="text-center">
              <small className="font-size-small font-weight-light">Blevet klogere? <span className="link" onClick={goToLogin}>Log ind</span></small>
            </div>
          </div>
        </Modal>
    );
  else if (step === 4)
    return (
        <Modal show={props.show} close={close}>
          <div className="modal-header d-flex">
            <div className="flex-grow-1">
              <h3>Du har nu fået nyt kode</h3>
            </div>
            <div className="modal-close" onClick={close}></div>
          </div>
          <div>
            <p>Du er nu færdig med processen og kan logge ind med dit nye kodeord.</p>

            <div className="text-center mb-3">
              <button className="btn btn-large btn-primary mt-auto" onClick={goToLogin}>Log ind</button>
            </div>
          </div>
        </Modal>
    );
};
export default ForgotPassword;