import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

import './UserMenu.css';

import profileImage from '../../assets/images/general/avatar.jpg';
import {faCut, faHome, faStar, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../../contexts/AuthContext";

// First we create our class
export const UserMenu = (props) => {

  const [authState,] = React.useContext(AuthContext);

  return (
    <div className="user-menu">

      <div className="profile-image">
        <div className="image image-circle image-cover">
          <img src={authState.token.profileImage || profileImage} alt="Profilbillede"/>
        </div>
        <span className="font-weight-bold font-size-large">{authState.token.name}</span>
      </div>

      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item"><Link className="nav-link" to="/dashboard"><FontAwesomeIcon icon={faHome} size="1x" color="#000000" />Dashboard</Link></li>
          <li className="nav-item"><Link className="nav-link" to="/dashboard/profil"><FontAwesomeIcon icon={faUser} size="1x" color="#000000" />Din profil</Link></li>
          <li className="nav-item"><Link className="nav-link" to="/dashboard/ratings"><FontAwesomeIcon icon={faStar} size="1x" color="#000000" />Dine ratings</Link></li>
          <li className="nav-item"><Link className="nav-link" to="/dashboard/filialer"><FontAwesomeIcon icon={faCut} size="1x" color="#000000" />Dine lokationer</Link></li>

          {
            /*
              <li className="nav-item"><Link className="nav-link" to="/dashboard/ejendomme"><img src={temp} alt="Opret bolig"/>Min bolig</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/dashboard/sogeagenter"><img src={temp} alt="Søgeagenter"/>Søgeagenter</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/dashboard/beskeder"><img src={temp} alt="Chat"/>Chat</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/dashboard/"><img src={temp} alt="Indstillinger"/>Indstillinger</Link></li>
           */
          }
        </ul>
      </nav>
    </div>
  )
}

export default UserMenu;
