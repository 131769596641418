import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar';

import Layout from './components/shared/Layout.js';
import CookiePopup from './components/elements/CookiePopup';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import { AuthContextProvider } from './contexts/AuthContext';

export const App = (props) => {

  const startTracking = () => {

    let hasCookieConsent = localStorage.getItem("cookie_consent") || false;

    if (hasCookieConsent) {

      // Setup Google Analytic
      ReactGA.initialize('UA-184232537-1');
      ReactGA.pageview(window.location.pathname + window.location.search);

      /*
      // Setup Google Tag Manager
      const tagManagerArgs = {
        gtmId: 'GTM-T82N76C'
      }

      TagManager.initialize(tagManagerArgs)

      // Setup Hotjar
      hotjar.initialize(1824701, 6);
      */
    }
  }
  startTracking();

  const setCookieConsent = () => {
    localStorage.setItem('cookie_consent', true);
    startTracking();
  }

  return (
    <AuthContextProvider>
      <CookiePopup onAccept={() => setCookieConsent()}>
        This site uses cookies ...
      </CookiePopup>

      <div className="App">
        <BrowserRouter forceRefresh={true}>
          <Switch>
            <Route path="/" component={Layout} />
          </Switch>
        </BrowserRouter>
      </div>
    </AuthContextProvider>
  );
}

export default App;
