import React from 'react';
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";

import CustomAlert from "../../../elements/CustomAlert";
import SearchAddress from "../../../elements/SearchAddress";
import ChoiceButton from "../../../elements/ChoiceButton";
import CustomInput from "../../../elements/CustomInput";
import { Editor } from '@tinymce/tinymce-react';

import PhoneNumberInput from "../../../elements/PhoneNumberInput";
import ChoiceWrapper from "../../../elements/ChoiceWrapper";
import Offers from "./Offers/Offers";
import {AuthContext} from "../../../../contexts/AuthContext";
import {ItemContextProvider} from "../../../../contexts/ItemContext";
import {ResponseStatus} from "../../../../models/responseStatus";

import { initialHairdresserLocation } from "../../../../types";
import {hasValue, copy, getKey, arrayMove} from "../../../../utils";

import './Location.css';

import {getHairdresserLocationByUrlAndAuthUser, postHairdresserLocation, putHairdresserLocation} from "../../../../requests/hairdresserLocation";
import {getHairdresserCategories} from "../../../../requests/hairdresserCategories";
import {putImage} from "../../../../requests/image";

import LoadingPage from "../../../elements/LoadingPage";
import DropZone from "../../../elements/DropZone";
import Sortable from "../../../elements/Sortable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencilAlt, faSave, faTimes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Offer} from "./Offers/Offer";
import CustomButton from "../../../elements/CustomButton";
import CustomSwitch from "../../../elements/CustomSwitch";
import Users from "./Users/Users";
import CustomTextarea from "../../../elements/CustomTextarea";



export const Location = (props) => {

  const [state,] = React.useContext(AuthContext);

  let history = useHistory();
  let { branchSlug } = useParams();
  let hairdresserLocationSlug = branchSlug;
  const newLocation = !hairdresserLocationSlug;

  const [authState] = React.useContext(AuthContext);

  const [isPageReady, setIsPageReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [readyForSubmit, setReadyForSubmit] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const [hairdresser, setHairdresser] = React.useState(null);
  const [hairdresserLocation, setHairdresserLocation] = React.useState(initialHairdresserLocation);
  const [hairdresserOffers, setHairdresserOffers] = React.useState([]);
  const [hairdresserCategories, setHairdresserCategories] = React.useState([]);
  const [hairdresserUsers, setHairdresserUsers] = React.useState([]);

  const [images, setImages] = React.useState([]);

  React.useEffect(() => {
    async function fetchInitialInfo() {

      if (!newLocation) {

        let hairdresserLocationResult = await getHairdresserLocationByUrlAndAuthUser(state.token.accessToken, hairdresserLocationSlug);

        if (hairdresserLocationResult.status === ResponseStatus.OK) {

          setHairdresserLocation(hairdresserLocationResult.data.data);
          setHairdresserOffers(hairdresserLocationResult.data.data.hairdresserOffers)
          setHairdresserUsers(hairdresserLocationResult.data.data.hairdresserUsers)
          setImages(hairdresserLocationResult.data.data.images)

        } else {
          console.log("Fejl under hentning af filial");
          setErrorMessage("Fejl under hentning af filial");
        }
      }

      let hairdresserCategoriesResult = await getHairdresserCategories();
      if (hairdresserCategoriesResult.status === ResponseStatus.OK) {

        setHairdresserCategories(hairdresserCategoriesResult.data.data)

      } else {
        console.log("Fejl under hentning af kategorier");
      }

      setIsPageReady(true);
    }

    fetchInitialInfo();
  }, [hairdresserLocationSlug]);

  const searchAddress = (returnAddress) => {

    setHairdresserLocation({
      ...hairdresserLocation,
      streetName: returnAddress.streetName,
      streetNumber: returnAddress.streetNumber,
      zipCode: returnAddress.zipCode,
      city: returnAddress.city,
      country: returnAddress.country,
      latitude: returnAddress.latitude,
      longitude: returnAddress.longitude
    })
  }

  const addHairdresserLocation = async () => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserLocationResult = await postHairdresserLocation(authState.token.accessToken, hairdresserLocation);

    if (hairdresserLocationResult.status === ResponseStatus.OK) {

      history.push("/dashboard/filialer/" + hairdresserLocationResult.data.data.url);

    } else if (hairdresserLocationResult.status === ResponseStatus.ERROR) {

      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const updateHairdresserLocation = async () => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserLocationResult = await putHairdresserLocation(authState.token.accessToken, hairdresserLocation.id, hairdresserLocation);

    if (hairdresserLocationResult.status === ResponseStatus.OK) {

      setSuccessMessage("Success");

    } else if (hairdresserLocationResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const setHairdresserCategory = (hairdresserCategory) => {

    let updateHairdresserCategories = hairdresserLocation.hairdresserCategories;

    let existIndex = -1;
    for (let i = 0; i < updateHairdresserCategories.length; i++) {
      if (updateHairdresserCategories[i].id === hairdresserCategory.id) {
        existIndex = i;
      }
    }

    if (existIndex >= 0) {
      updateHairdresserCategories.splice(existIndex, 1);
    } else {
      updateHairdresserCategories.push(copy(hairdresserCategory))
    }

    setHairdresserLocation({...hairdresserLocation, hairdresserCategories: updateHairdresserCategories})
  }

  const setImagesContainer = (newImages) => {
    setImages([...images, ...newImages])
  }

  const removeImage = async (image) => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let body = copy(image);
    body.deleted = true;

    let imageResult = await putImage(authState.token.accessToken, image.id, body);

    if (imageResult.status === ResponseStatus.OK) {

      setSuccessMessage("Success");
      const updateImages = images.filter(e => e.id != image.id);
      setImages(updateImages)

    } else if (imageResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const updateImagesOrder = async (oldIndex, newIndex) => {
    let updateImages = arrayMove(copy(images), oldIndex, newIndex);
    setImages(updateImages);

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    for (let i = 0; i < updateImages.length; i++) {

      let body = updateImages[i];
      body.sequence = i;

      let imageResult = await putImage(authState.token.accessToken, updateImages[i].id, body);

      if (imageResult.status === ResponseStatus.OK) {

        setSuccessMessage("Success");

      } else if (imageResult.status === ResponseStatus.ERROR) {
        setErrorMessage("Fejl");
      }
    }

    setLoading(false);
  }

  const addHairdresserUser = async () => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);
/*
    let hairdresserUserResult = await postHairdresserUser(authState.token.accessToken, hairdresserUser.id, hairdresserLocation);

    if (hairdresserUserResult.status === ResponseStatus.OK) {

      setSuccessMessage("Success");

    } else if (hairdresserUserResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }
*/
    setLoading(false);
  }


  return (
    <LoadingPage isPageReady={isPageReady}>
      <div className="location">

        <h1>{hairdresserLocation.name || 'Ny lokation'}</h1>

        {
          errorMessage &&
          <CustomAlert type={"danger"} className="mt-3" close={() => setErrorMessage(null)}>{errorMessage}</CustomAlert>
        }
        {
          successMessage &&
          <CustomAlert type={"success"} className="mt-3" close={() => setSuccessMessage(null)}>{successMessage}</CustomAlert>
        }

        <h4 className="form-header">Primære oplysninger om lokation</h4>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Navn</label>
              <CustomInput type={"text"} name="name" placeholder={"Lokationens navn"} value={hairdresserLocation.name} onChange={(e) => setHairdresserLocation({...hairdresserLocation, name: e.target.value})}/>
            </div>

            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Email</label>
              <CustomInput type={"text"} name="email" placeholder={"Email"} value={hairdresserLocation.email} onChange={(e) => setHairdresserLocation({...hairdresserLocation, email: e.target.value})}/>
            </div>

            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Landekode / Telefonnummer</label>
              <PhoneNumberInput phoneCallingCode={hairdresserLocation.phoneCallingCode} phoneNumber={hairdresserLocation.phoneNumber} onChange={(phoneCallingCode, phoneNumber) => setHairdresserLocation({...hairdresserLocation, phoneCallingCode: phoneCallingCode, phoneNumber: phoneNumber})}/>
            </div>

          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Adresse</label>
              <SearchAddress value={newLocation ? "" : (hairdresserLocation.streetName + " " + hairdresserLocation.streetNumber + ", " + hairdresserLocation.zipCode + " " + hairdresserLocation.city)} setAddress={searchAddress} placeholder="Adresse" showMarker={true}/>
            </div>

            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Homepage</label>
              <CustomInput type={"text"} name="homepage" placeholder={"Homepage"} value={hairdresserLocation.homepage} onChange={(e) => setHairdresserLocation({...hairdresserLocation, homepage: e.target.value})}/>
            </div>

            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Cover</label>
              <CustomInput type={"text"} name="cover" placeholder={"Cover"} value={hairdresserLocation.cover} onChange={(e) => setHairdresserLocation({...hairdresserLocation, cover: e.target.value})}/>
            </div>

          </div>
        </div>

        <div className="form-group">
          <label className="font-size-large font-weight-xbold mb-3">Beskrivelse</label>
          <CustomTextarea value={hairdresserLocation.description} onChange={(value) => setHairdresserLocation({...hairdresserLocation, description: value})} />
        </div>

        <div className="form-group">
          <label className="font-size-large font-weight-xbold mb-3">Aktiv</label>
          <ChoiceWrapper type="single" className="mb-3">
            <ChoiceButton active={!hairdresserLocation.active} onClick={() => setHairdresserLocation({...hairdresserLocation, active: false})}>Inaktiv</ChoiceButton>
            <ChoiceButton active={hairdresserLocation.active} onClick={() => setHairdresserLocation({...hairdresserLocation, active: true})}>Aktiv</ChoiceButton>
          </ChoiceWrapper>
        </div>

        <div className="form-group">
          <label className="font-size-large">Vælg færdigheder</label>
          <ChoiceWrapper type="multi" className="mb-3">
            {
              hasValue(hairdresserCategories) &&
              hairdresserCategories.map(hairdresserCategory => {
                  let active = hairdresserLocation.hairdresserCategories.some(e => e.id === hairdresserCategory.id);
                  return <ChoiceButton key={getKey(hairdresserCategory)} active={active} onClick={() => setHairdresserCategory(hairdresserCategory)}>{hairdresserCategory.name}</ChoiceButton>
                }
              )
            }
          </ChoiceWrapper>
        </div>

        <button type="button" className={"btn btn-primary btn-large" + (readyForSubmit || loading ? "" : " disabled")} disabled={!readyForSubmit || loading} onClick={newLocation ? addHairdresserLocation : updateHairdresserLocation}>{loading ? "Loading..." : (newLocation ? "Opret lokation" : "Gem lokation")}</button>

        {
          !newLocation &&
          <>
            <div className="images mt-5">
              <div className="upload-images">
                <h2>Upload billeder</h2>
                <p className="manchet">Tag nogle gode billeder der viser din salon bedst muligt og upload dem her.</p>

                <DropZone foreignTable="HairdresserLocation" foreignId={hairdresserLocation.id} setImages={setImagesContainer}/>
              </div>

              <Sortable items={images} updateItems={updateImagesOrder} removeItem={removeImage} type="images"/>
            </div>

            <ItemContextProvider items={hairdresserOffers} setItems={setHairdresserOffers}>
              <Offers hairdresserLocationId={hairdresserLocation.id}></Offers>
            </ItemContextProvider>

            <ItemContextProvider items={hairdresserUsers} setItems={setHairdresserUsers}>
              <Users hairdresserLocationId={hairdresserLocation.id}></Users>
            </ItemContextProvider>
          </>
        }

      </div>
    </LoadingPage>
  );
};

export default Location;
