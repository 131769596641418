import React from "react";
import CodeConfirm from '../elements/CodeConfirm';

import { AuthContext } from "../../contexts/AuthContext.js";
import { ResponseStatus } from '../../models/responseStatus.js';
import { confirmUser, resendConfirmation } from "../../requests/user.js";
import {IconLogoIcon} from "../../Icons";

import './ConfirmActivation.css';

export const ConfirmActivation = (props) => {
  const [state,dispatch] = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const handleConfirmation = async (code) => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let data = {
      "confirmationCode": code
    };

    let confirmResult = await confirmUser(state.token.accessToken, data);

    if (confirmResult.status === ResponseStatus.OK) {

      dispatch({
        type: "CONFIRM"
      })

      props.close();

    } else if (confirmResult.status === ResponseStatus.ERROR) {

      // Set new errors
      if (confirmResult.data.errors) {
        for (let [key, value] of Object.entries(confirmResult.data.errors)) {
          for (let [_, value2] of Object.entries(value.description)) {
            setErrorMessage(value2);
          }
        }
      }
      if (confirmResult.data.error) {
        setErrorMessage(confirmResult.data.error);
      }

    } else {
      setErrorMessage("Fejl under bekræftelse af kode");
    }

    setLoading(false);
  }

  const sendConfirmation = async () => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let resendConfirmationResult = await resendConfirmation(state.token.accessToken);

    if (resendConfirmationResult.status === ResponseStatus.OK) {
      setSuccessMessage("Der er blevet sendt en ny bekræftelseskode via mail.");
    }  else {
      setErrorMessage("Fejl under bekræftelse af kode");
    }
    setLoading(false);
  }

  return (
    <CodeConfirm className={"confirm-activation"} title={"Bekræft din profil"} show={props.show} icon={<IconLogoIcon color="primary" />} onSubmit={handleConfirmation} loading={loading} errorMessage={errorMessage} successMessage={successMessage} resend={sendConfirmation}>
      Din profil er endnu ikke blevet aktiveret. Du har modtaget en mail med en aktiveringskode som du skal indtaste herunder.
    </CodeConfirm>
  );
};
export default ConfirmActivation;