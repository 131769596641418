import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

import UserMenu from './UserMenu.js';
import Dashboard from '../userpanel/Dashboard.js';
import ListEstates from '../userpanel/estates/ListEstates';
import AddEstates from '../userpanel/estates/AddEstates';
import ConversationController from '../userpanel/messages/ConversationController';
import ListSearchAgents from "../userpanel/searchAgents/ListSearchAgents";
import SearchAgent from "../userpanel/searchAgents/SearchAgent";
import ConfirmActivation from "../Modals/ConfirmActivation";

import './UserLayout.css';
import {AuthContext} from "../../contexts/AuthContext";
import ConnectedCompanies from "../userpanel/connectedCompanies/ConnectedCompanies";
import ConnectedCompany from "../userpanel/connectedCompanies/ConnectedCompany";
import Location from "../userpanel/connectedCompanies/Locations/Location";
import Locations from "../userpanel/connectedCompanies/Locations/Locations";
import UserRating from "../userpanel/userRatings/UserRating";
import ListUserRatings from "../userpanel/userRatings/ListUserRatings";
import {IconBurgerMenu, IconClose} from "../../Icons";
import Profile from "../userpanel/profile/Profile";

// First we create our class
export const UserLayout = (props) => {

  const [state, ] = React.useContext(AuthContext);
  const [showConfirmActivationModal, setShowConfirmActivationModal] = React.useState(!state.token.isConfirmed);

  return (
    <div className="user-layout d-flex">

      <div className={"user-left d-none d-md-block" + (props.showUserMenu ? " open" : "")}>
        <UserMenu/>
      </div>
      <div className="user-right">
        <div className="user-content">
          <Switch>
            <Route exact path="/dashboard">
              <Dashboard/>
            </Route>

            <Route exact path="/dashboard/profil">
              <Profile/>
            </Route>

            <Route exact path="/dashboard/filialer/opret">
              <Location/>
            </Route>
            <Route exact path="/dashboard/filialer/:branchSlug">
              <Location/>
            </Route>
            <Route exact path="/dashboard/filialer">
              <Locations/>
            </Route>

            <Route exact path="/dashboard/virksomheder/:companySlug/filialer/opret">
              <Location/>
            </Route>
            <Route exact path="/dashboard/virksomheder/:companySlug/filialer/:branchSlug">
              <Location/>
            </Route>

            <Route exact path="/dashboard/virksomheder/opret">
              <ConnectedCompany/>
            </Route>
            <Route exact path="/dashboard/virksomheder/:companySlug">
              <ConnectedCompany/>
            </Route>
            <Route exact path="/dashboard/virksomheder">
              <ConnectedCompanies/>
            </Route>
            <Route exact path="/dashboard/ratings/:ratingId">
              <UserRating/>
            </Route>
            <Route exact path="/dashboard/ratings">
              <ListUserRatings/>
            </Route>

            {
              /*
                <Route exact path="/dashboard/beskeder">
                  <ConversationController/>
                </Route>
                <Route exact path="/dashboard/ejendomme/opret">
                  <AddEstates/>
                </Route>
                <Route exact path="/dashboard/ejendomme/">
                  <ListEstates/>
                </Route>
                <Route exact path="/dashboard/sogeagenter/opret">
                  <SearchAgent/>
                </Route>
                <Route exact path="/dashboard/sogeagenter/:searchAgentId">
                  <SearchAgent/>
                </Route>
                <Route exact path="/dashboard/sogeagenter/">
                  <ListSearchAgents/>
                </Route>
                */
            }

          </Switch>
        </div>
      </div>

      <ConfirmActivation show={showConfirmActivationModal} close={() => setShowConfirmActivationModal(false)} />
    </div>
  )
}

export default UserLayout;
