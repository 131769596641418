import React from 'react';

import './CustomButton.css';
import {hasValue} from "../../utils";

// a basic form
const CustomButton = (props) => {

  return (
    <button
      className={"btn " + (hasValue(props.type) ? 'btn-'+props.type : '') + " " + (props.disabled ? 'disabeld' : '') + " " + props.className}
      type="button"
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.loading ? "Loading..." : props.children}
    </button>
  );
};

export default CustomButton;
