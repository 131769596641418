
export const copy = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const timeSince = (date, maxWithFormat, format) => {
  let then = new Date(date);
  let now   = new Date();

  let minutes = Math.floor((now.getTime() - then.getTime()) / 1000 / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);
  
  if (maxWithFormat === "day" && days < 1) return formatDate(date, format);
  else if (days > 0) return days > 1 ? days + " dage" : "i går";
  else if (hours > 0) return hours + " " + (hours > 1 ? "timer" : "time");
  else if (minutes > 0) return  minutes + " " + (minutes > 1 ? "minutter" : "minut");
  else return "Lige sendt";
}

export const formatDate = (dateIn, format) => {
  let date = new Date(dateIn);

  //let months = ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "November", "December"];
  //let monthsShort = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"];

  //let day = date.getDate();
  //let monthIndex = date.getMonth();
  //let month = date.getMonth() + 1;
  //let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (format === 0) return hours + ":" + minutes;
  else return hours + ":" + minutes;
}

export const formatNumber = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export const hasValue = (value) => {

  if (value === null || value === undefined) {
    return false;
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  if (typeof value === 'object' ) {
    return value !== null;
  }

  return value !== "";
}

export const keyGenerator = (length) => {

  if (length === undefined) length = 64;

  let result           = [];
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++ ) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
  }
  return result.join('');
}

export const getKey = (item) => {
  return hasValue(item.id) ? item.id : item.key;
}

export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const countDecimals = (value) => {

  if (Math.floor(value) === value) return 0;

  let str = value.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
}
