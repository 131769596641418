import React from "react";

import './ChoiceWrapper.css';


export const ChoiceWrapper = (props) => {

  return (
    <div className={"choice-wrapper" + (props.type === "range" ? " choice-wrapper-range" : "") + " " + props.className}>
      {props.children}
    </div>
  );
};
export default ChoiceWrapper;