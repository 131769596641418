import React from 'react';

import './Offers.css';
import {hasValue, getKey} from "../../../../../utils";
import {generateEmptyHairdresserOffer} from "../../../../../types";
import {ItemContext} from "../../../../../contexts/ItemContext";
import {Offer} from "./Offer";
import CustomButton from "../../../../elements/CustomButton";

export const Offers = (props) => {

  const offerContext = React.useContext(ItemContext);

  const addOffer = () => {
    let newOffer = generateEmptyHairdresserOffer(props.hairdresserLocationId);
    offerContext.add(newOffer)
  }

  return (
    <div className="offers mt-5">
      <h2>Dine services</h2>

      {
        offerContext.items.some(offer => !offer.deleted) &&
          <div className="offer-line offer-line-header mt-4 mb-3">
            <div className="offer-active">
            </div>
            <div className="offer-name">
              Navn
            </div>
            <div className="offer-price">
              Pris
            </div>
            <div className="offer-actions">
            </div>
          </div>
      }

      {
        offerContext.items.map(offer =>
          <Offer key={getKey(offer)} offer={offer} />
        )
      }

      <CustomButton type={"primary"} className={"mt-3"} onClick={addOffer}>Opret ny service</CustomButton>

    </div>
  )
}

export default Offers;
