import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faEye, faPencilAlt, faSave, faTimes, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

import CustomInput from "../../../../elements/CustomInput";
import {AuthContext} from "../../../../../contexts/AuthContext";
import {ResponseStatus} from "../../../../../models/responseStatus";

import './Offer.css';
import {postHairdresserOffer, putHairdresserOffer} from "../../../../../requests/hairdresserOffer";
import {ItemContext} from "../../../../../contexts/ItemContext";

import {getKey, hasValue, copy} from "../../../../../utils";
import { initialHairdresserOffer } from "../../../../../types";

import LoadingPage from "../../../../elements/LoadingPage";
import CustomAlert from "../../../../elements/CustomAlert";
import CustomSwitch from "../../../../elements/CustomSwitch";
import {confirm} from "../../../../Modals/Confirm";

export const Offer = (props) => {

  const [state,] = React.useContext(AuthContext);
  const offerContext = React.useContext(ItemContext);

  const [isPageReady, setIsPageReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [readyForSubmit, setReadyForSubmit] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [offer, setOffer] = React.useState(initialHairdresserOffer);
  const [tempOffer, setTempOffer] = React.useState(initialHairdresserOffer);
  const [showEditOffer, setShowEditOffer] = React.useState(false);

  React.useEffect(() => {
    setOffer(props.offer)
    setIsPageReady(true);

    // new offer
    if (!hasValue(props.offer.id)) {
      setShowEditOffer(true);
    }

  }, [getKey(props.offer)]);

  const startEditMode = async () => {
    setTempOffer(copy(offer));
    setShowEditOffer(true);

    setErrorMessage(null);
    setSuccessMessage(null);
  }

  const cancelEditMode = async () => {
    setOffer(copy(tempOffer));
    setShowEditOffer(false);
  }

  const addHairdresserOffer = async (addOffer) => {

    // existing offer
    if (hasValue(offer.id)) {
      updateHairdresserOffer(offer);
      return;
    }

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserOfferResult = await postHairdresserOffer(state.token.accessToken, addOffer);

    if (hairdresserOfferResult.status === ResponseStatus.OK) {

      offerContext.update(hairdresserOfferResult.data.data, getKey(addOffer))
      setOffer(hairdresserOfferResult.data.data)
      setShowEditOffer(false);

      setSuccessMessage("Success");

    } else if (hairdresserOfferResult.status === ResponseStatus.ERROR) {

      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const updateHairdresserOffer = async (addOffer) => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserOfferResult = await putHairdresserOffer(state.token.accessToken, addOffer.id, addOffer);

    if (hairdresserOfferResult.status === ResponseStatus.OK) {

      offerContext.update(hairdresserOfferResult.data.data, getKey(addOffer))
      setOffer(hairdresserOfferResult.data.data)
      setShowEditOffer(false);

      setSuccessMessage("Success");

    } else if (hairdresserOfferResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const toggleActive = async () => {
    let updateOffer = copy(offer);
    updateOffer.active = !updateOffer.active;
    updateHairdresserOffer(updateOffer);
  }

  const removeHairdresserOffer = async () => {
    if (await confirm('Du er ved at slette din service: <br />' + offer.name + '<br /><br />Ønsker du at fortsætte?')) {

      let updateOffer = copy(offer);
      updateOffer.active = false;
      updateOffer.deleted = true;

      if (hasValue(offer.id)) {
        updateHairdresserOffer(updateOffer);
      } else {
        setOffer(updateOffer);
      }
    }
  }

  return (
    <LoadingPage isPageReady={isPageReady} deleted={offer.deleted} hide={true}>

      <div className="offer box box-shadow mb-3">

        <div className="offer-line">
          <div className="offer-active">
            <CustomSwitch active={offer.active} onClick={() => toggleActive()} />
          </div>
          <div className="offer-name">
            {offer.name}
          </div>
          <div className="offer-price">
            {offer.price} kr.
          </div>
          <div className="offer-actions">
            {
              showEditOffer
                ? <>
                    <button type="button" className={"btn btn-icon" + (readyForSubmit || loading ? "" : " disabled")} disabled={!readyForSubmit || loading} onClick={() => addHairdresserOffer(offer)}><FontAwesomeIcon icon={faSave} size="1x" color="#28a745" /></button>
                    <button type="button" className={"btn btn-icon ml-2"} onClick={() => cancelEditMode()}><FontAwesomeIcon icon={faTimes} size="1x" color="#96989b" /></button>
                  </>
                : <button type="button" className={"btn btn-icon ml-2"} onClick={() => startEditMode()}><FontAwesomeIcon icon={faPencilAlt} size="1x" color="#28a745" /></button>
            }
            <button type="button" className={"btn btn-icon ml-2"} onClick={removeHairdresserOffer}><FontAwesomeIcon icon={faTrashAlt} size="1x" color="#D32D27" /></button>
          </div>
        </div>

        {
          hasValue(offer.description) &&
          <div className="offer-line">
            <div className="offer-active">
            </div>
            <div className="offer-description">
              {offer.description}
            </div>
            <div className="offer-actions">
            </div>
          </div>
        }

        <div className={"offer-edit" + (showEditOffer ? ' offer-edit-show ' : '') + " mt-3"}>

          {
            errorMessage &&
            <CustomAlert type={"danger"} close={() => setErrorMessage(null)}>{errorMessage}</CustomAlert>
          }
          {
            successMessage &&
            <CustomAlert type={"success"} close={() => setSuccessMessage(null)}>{successMessage}</CustomAlert>
          }

          <div className="row">

            <div className="col-8 offer-name">
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Navn</label>
                <CustomInput type={"text"} name="name" placeholder={"Tilbuddets navn"} value={offer.name} onChange={(e) => setOffer({...offer, name: e.target.value})} />
              </div>
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Beskrivelse</label>
                <textarea className={"form-control"} type={"text"} name="description" placeholder={"Beskrivelse"} value={offer.description} onChange={(e) => setOffer({...offer, description: e.target.value})} />
              </div>
            </div>
            <div className="col-4 offer-price">
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Pris</label>
                <CustomInput type={"number"} name="price" placeholder={"149,95"} value={offer.price} onChange={(e) => setOffer({...offer, price: e.target.value})} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingPage>
  );
};

export default Offer;
