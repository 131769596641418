import React from "react";

import './ChoiceWrapper.css';
import {hasValue} from "../../utils";

export const LinkPhoneNumber = (props) => {

  return (
    <>
      {
        hasValue(props.phoneNumber) && hasValue(props.phoneCallingCode) &&
        <a href={"tel:" + props.phoneNumber}>
          +{props.phoneCallingCode} {props.phoneNumber.toString().substr(0, 2)} {props.phoneNumber.toString().substr(2, 2)} {props.phoneNumber.toString().substr(4, 2)} {props.phoneNumber.toString().substr(6, 2)}
        </a>
      }

      {
        hasValue(props.phoneNumber) && !hasValue(props.phoneCallingCode) &&
        <a href={"tel:" + props.phoneNumber}>
          {props.phoneNumber.toString().substr(0, 2)} {props.phoneNumber.toString().substr(2, 2)} {props.phoneNumber.toString().substr(4, 2)} {props.phoneNumber.toString().substr(6, 2)}
        </a>
      }

      {
        !hasValue(props.phoneNumber) && !hasValue(props.phoneCallingCode) &&
        <span className="text-greyish">Ikke angivet</span>
      }

    </>
  );
};
export default LinkPhoneNumber;
