import React from 'react';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";

import UserLayout from './UserLayout';
import PrivateRoute from './PrivateRoute'

import Home from '../home/Home';
import Hairdresser from '../home/Hairdresser';
import Faq from '../home/Faq';
import About from '../home/About';
import Contact from '../home/Contact';
import Login from '../Modals/Login';
import CreateUser from '../Modals/CreateUser';
import ForgotPassword from '../Modals/ForgotPassword';
import MapContainer from "../home/MapContainer";
import HomeHairdressers from "../home/HomeHairdressers";

import { AuthContext } from "../../contexts/AuthContext";

import './Layout.css';
import profileImage from '../../assets/images/general/avatar.jpg';

import { IconLogo, IconLogoIcon, IconBurgerMenu, IconClose, IconArrowDown, IconLogoFooter } from "../../Icons";
import {Welcome} from "../Modals/Welcome";
import UserMenu from "./UserMenu";


export const Layout = props => {

  const isMapPage = window.location.pathname === "/find-frisor";
  const isUserPage = window.location.pathname.includes("/dashboard");

  const [state, dispatch] = React.useContext(AuthContext);

  const [showMenu, setShowMenu] = React.useState(false);
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const [showCreateUserModal, setShowCreateUserModal] = React.useState(false);
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = React.useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = React.useState(false);

  const [showAboutUs, setShowAboutUs] = React.useState(false)
  const [showHelp, setShowHelp] = React.useState(false)
  const [showNewsletter, setShowNewsletter] = React.useState(false)

  const logout = () => {
    dispatch({
      type: "LOGOUT",
    })
  }

  return (
    <div className="layout">

      <div className={"top-nav" + (isMapPage ? " fixed" : "")}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-auto logo">
              <Link className="d-none d-md-block" to="/"><IconLogo /></Link>
              <Link className="d-md-none" to="/"><IconLogoIcon color={"primary"} /></Link>
              <div className={"burger-menu d-block d-md-none" + (showMenu ? " open" : "")} onClick={() => { setShowMenu(!showMenu); setShowUserMenu(false); }}>{showMenu ? <IconClose /> : <IconBurgerMenu />}</div>
              {
                state.isAuthenticated &&
                <div className={"burger-user-menu d-block d-md-none" + (showUserMenu ? " open" : "")} onClick={() => { setShowUserMenu(!showUserMenu); setShowMenu(false); }}>
                  {
                    showUserMenu
                      ?
                      <IconClose />
                      :
                      <div className="image image-circle image-cover">
                        <img src={profileImage} alt="bruger-menu"/>
                      </div>
                  }
                </div>
              }
            </div>
            <div className="col vertical-center">
              <nav className={"navbar navbar-expand-md d-none d-md-block" + (showMenu ? " open" : "")}>
                <ul className="navbar-nav mr-auto">

                  <li className="nav-item"><Link className="nav-link" to="/find-frisor">Find din frisør</Link></li>
                  <li className="nav-item d-md-none d-lg-inline-block"><Link className="nav-link" to="/for-frisorer">For frisører</Link></li>
                  <li className="nav-item d-md-none d-lg-inline-block"><Link className="nav-link" to="/kontakt">Kontakt</Link></li>
                  {
                    /*
                    <li className="nav-item"><Link className="nav-link" to="/faq">FAQ</Link></li>
                    <li className="nav-item"><Link className="nav-link" to="/om">Om</Link></li>
                    */
                  }

                  <li className="nav-item nav-item-buttons vertical-center">
                    {
                      state.isAuthenticated
                        ?
                        <>
                          <button className="btn btn-primary-contrast ml-0 ml-md-4" onClick={() => logout()}>Log ud</button>
                          <Link className="btn btn-primary ml-2" to="/dashboard">Dashboard</Link>
                        </>
                        :
                        <>
                          <button className="btn btn-primary-contrast ml-0 ml-md-4" onClick={() => setShowLoginModal(true)}>Log ind</button>
                          <button className="btn btn-primary ml-2" onClick={() => setShowCreateUserModal(true)}>Opret bruger</button>
                        </>
                    }
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="main">
        {
          !isUserPage && state.isAuthenticated &&
          <div className={"user-left d-none" + (showUserMenu ? " open" : "")}>
            <UserMenu />
          </div>
        }
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/find-frisor" component={MapContainer} />
          <Route path="/for-frisorer" component={HomeHairdressers} />
          <Route path="/faq" component={Faq} />
          <Route path="/om" component={About} />
          <Route path="/kontakt" component={Contact} />
          <Route path="/frisor/:hairdresserLocationUrl" component={Hairdresser} />
          <PrivateRoute path="/dashboard" component={UserLayout} showUserMenu={showUserMenu} />
        </Switch>
      </div>

      <div className={"footer" + (isMapPage ? " d-none" : "")}>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="logo-footer">
                <Link to="/"><IconLogoFooter /></Link>
              </div>
              <div className="address">
                <ul>
                  <li>Sjælsø Allé 7H</li>
                  <li>3450 Blovstrød</li>
                  <li>Danmark</li>
                  <li className="mt-2"><a href="mailto:kontakt@frisorsiden.dk" className="text-underline">kontakt@frisorsiden.dk</a></li>
                </ul>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className="col-md-2">
              <h4 onClick={() => setShowAboutUs(!showAboutUs)} >Om os <IconArrowDown color="#ffffff" /></h4>
              <ul className={"footer-menu" + (showAboutUs ? " open" : "")}>
                <li><Link to="/om">Om Frisørsiden</Link></li>
                <li><Link to="/kontakt">Kontakt</Link></li>
              </ul>
            </div>
            <div className="col-md-2">
              <h4 onClick={() => setShowHelp(!showHelp)}>Hjælp <IconArrowDown color="#ffffff" /></h4>
              <ul className={"footer-menu" + (showHelp ? " open" : "")}>
                <li><Link to="/faq">FAQ</Link></li>
              </ul>
            </div>
            <div className="col"></div>
            <div className="col-md-4 news-letter-signup">
              <h4 onClick={() => setShowNewsletter(!showNewsletter)}>Vores mål <IconArrowDown color="#ffffff" /></h4>
              <div className={"footer-menu" + (showNewsletter ? " open" : "")}>
                <p>Frisørsiden er sat i verden, for at gøre det nemt og overskueligt at finde en frisør. Samtidig vil vi også hjælpe frisørerne med at blive synlige for den gængse kunde. Kort og godt, et sted hvor vi mødes.</p>
              </div>
            </div>
          </div>

          {/*
          <div className="footer-bottom">
            <div className="social">
              <a href="https://www.facebook.com/mindroemmebolig" target="_blank"><IconFacebook color="#ffffff" /></a>
            </div>
            <div className="social">
              <a href="https://www.instagram.com/mindrommebolig/" target="_blank"><IconInstagram color="#ffffff" /></a>
            </div>
            <div className="social">
              <a href="https://www.linkedin.com/company/min-dr%C3%B8mmebolig-aps" target="_blank"><IconLinkedIn color="#ffffff"/></a>
            </div>
            <div className="copyrights">
              <span>© 2020 Frisørsiden</span>
            </div>
          </div>
          */}
        </div>
      </div>


      <Welcome show={showWelcomeModal} close={() => setShowWelcomeModal(false)} />
      <CreateUser show={showCreateUserModal} close={() => setShowCreateUserModal(false)} login={() => setShowLoginModal(true)} welcome={() => setShowWelcomeModal(true)} />
      <Login show={showLoginModal} close={() => setShowLoginModal(false)} forgotPassword={() => setShowForgotPasswordModal(true)} createUser={() => setShowCreateUserModal(true)} />
      <ForgotPassword show={showForgotPasswordModal} close={() => setShowForgotPasswordModal(false)} login={() => setShowLoginModal(true)} />

    </div>
  )
};

export default Layout;
