import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import './DropZone.css';
import {postImage} from "../../requests/image";
import {postHairdresserLocation} from "../../requests/hairdresserLocation";
import {ResponseStatus} from "../../models/responseStatus";
import {AuthContext} from "../../contexts/AuthContext";
import {API_URL} from "../../requests/base";
import profileImage from "../../assets/images/general/avatar.jpg";
import {hasValue} from "../../utils";

const DropZone = (props) => {

  const [authState] = React.useContext(AuthContext);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {

      let formData = new FormData();
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append('files[]', acceptedFiles[i]);
      }
      formData.append('foreignId', props.foreignId);
      formData.append('foreignTable', props.foreignTable);
      formData.append('type', hasValue(props.type) ? props.type : '');
      formData.append('deleted', 0);

      let headers = {};
      headers["Authorization"] = "Baerer " + authState.token.accessToken;
      headers["Access-Control-Allow-Origin"] = "*";
      headers["Access-Control-Allow-Methods"] = "POST, GET, OPTIONS";
      headers["Access-Control-Allow-Headers"] = "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With";

      fetch(
        API_URL + '/images', {
          method: 'POST',
          mode: "cors",
          cache: "no-cache",
          headers: headers,
          body: formData
        }
      )
        .then((response) => response.json())
        .then((result) => {
          props.setImages(result.data)

        })
        .catch((error) => {
          console.error('Error:', error);
        });

/*
      postImage(authState.token.accessToken, {foreignId: props.foreignId, foreignTable: props.foreignTable }, acceptedFiles, () => {
        console.log(123)
      });
*/
      /*
      let newFiles = acceptedFiles.map(file => {

        if (hairdresserLocationResult.status === ResponseStatus.OK) {

          history.push("/dashboard/filialer/" + hairdresserLocationResult.data.data.url);

        } else if (hairdresserLocationResult.status === ResponseStatus.ERROR) {

          setErrorMessage("Fejl");
        }

        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      });

      let allFiles = oldFiles.concat(newFiles);
      setFiles(allFiles)
      props.setImages(allFiles)
      console.log(allFiles)

      for (let i = 0; i < allFiles.length; i++) {
        getBase64(allFiles[i], (result) => {
          console.log(result)
        });
      }
      */
    }
  });

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className={"upload-images" + (props.type === "profileImage" ? ' profile-image' : '')}>
      <div {...getRootProps({ className: 'dropzone' + (hasValue(props.image) || files.length > 0 ? "" : " empty") })}>
        <input {...getInputProps()} />
        {
          hasValue(props.image) &&
            <>
              <div className="image image-cover image-circle">
                {
                  props.type === 'profileImage' &&
                  <img src={props.image.url} alt="Upload profilbillede"/>
                }
              </div>
            </>
        }
        {
          !hasValue(props.image) &&
            <>
              <div className="icon image image-cover">
                {
                  props.type === 'profileImage' &&
                  <img src={profileImage} alt="Upload profilbillede"/>
                }
              </div>
              <div className="drop-text">
                {
                  props.type === 'profileImage' &&
                  <>
                    <span className="d-block mb-1 font-weight-light font-size-normal"><span className="font-weight-bold text-underline text-primary">Upload dit profilbillede</span></span>
                  </>
                }
                {
                  props.type !== 'profileImage' &&
                  <>
                    <span className="d-block mb-1 font-weight-light font-size-normal"><span className="font-weight-bold text-underline text-primary">Upload billeder</span> eller drag & drop</span>
                    <span className="d-block font-weight-light font-size-xsmall text-greyish">Tilføj så mange du ønsker</span>
                  </>
                }
              </div>
            </>
        }
      </div>
      <div className="thumbs-container">
        {
          files.map((file, idx) =>
            <div className="thumb" key={idx}>
              <div className="thumb-inner image image-cover">
                <img src={file.preview} className="img" alt="preview" />
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
}


export default DropZone;
