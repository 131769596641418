import React from 'react';

import './CustomInput.css';

// a basic form
const CustomInput = (props) => {

  const keyDetection = (e) => {
    if (e.keyCode == 13) {
      if (props.onEnterKeyUp) props.onEnterKeyUp();
    }
  }

  return (
    !props.showSubmit
      ?
      <input
        className={"form-control " + props.className}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        readOnly={props.readOnly}
        onChange={props.onChange}
        onKeyUp={(e) => keyDetection(e)}
        autoComplete="nope" />
      :
      <div className="input-with-submit">
        <input
          className={"form-control " + props.className}
           type={props.type}
           name={props.name}
           placeholder={props.placeholder}
           value={props.value}
           readOnly={props.readOnly}
           onChange={props.onChange}
           onKeyUp={(e) => keyDetection(e)}
           autoComplete="nope" />
        <button type="button" className={"btn btn-primary"} onClick={props.onClick}>Søg</button>
      </div>
  );
}

export default CustomInput;
