import React from "react";
import Modal from '../elements/Modal.js';
import {IconRatingEmtpy, IconRatingFull} from "../../Icons";

import { AuthContext } from "../../contexts/AuthContext.js";
import {postHairdresserRating} from "../../requests/hairdresserRating";
import {ResponseStatus} from "../../models/responseStatus";
import {copy, getKey, hasValue} from "../../utils";

import './RateHairdresser.css';
import ChoiceWrapper from "../elements/ChoiceWrapper";
import ChoiceButton from "../elements/ChoiceButton";

import CreateUser from "../templates/CreateUser";
import Login from "../templates/Login";
import CustomButton from "../elements/CustomButton";
import {generateEmptyHairdresserRating} from "../../types";

export const RateHairdresser = (props) => {
  const [state,] = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [message, setMessage] = React.useState("");
  const [ratingStep, setRatingStep] = React.useState('rate');

  const [hairdresserCategoryIds, setHairdresserCategoryIds] = React.useState([]);

  const [moneyScore, setMoneyScore] = React.useState(0);
  const [tempMoneyScore, setTempMoneyScore] = React.useState(0);

  const [qualityScore, setQualityScore] = React.useState(0);
  const [tempQualityScore, setTempQualityScore] = React.useState(0);

  const [experienceScore, setExperienceScore] = React.useState(0);
  const [tempExperienceScore, setTempExperienceScore] = React.useState(0);

  const [score, setScore] = React.useState(0);
  const [tempScore, setTempScore] = React.useState(0);

  const [showThanksMessage, setShowThanksMessage] = React.useState(false);

  const addRating = async () => {

    if (!state.isAuthenticated) {
      setRatingStep('createUser');
      return;
    }

    if (score === 0 || moneyScore === 0 || qualityScore === 0 || experienceScore === 0) {
      setErrorMessage('Vælg point før du afsender din rating.');
      return;
    }

    if (message === '') {
      setErrorMessage('Begrund din rating med en kort beskrivelse.');
      return;
    }

    setLoading(true);
    setErrorMessage(null);

    let hairdresserCategoryIdsToUse = hairdresserCategoryIds;
    if (hairdresserCategoryIdsToUse.length === 0) {
      hairdresserCategoryIdsToUse = props.hairdresserCategories.map(e => e.id);
    }

    let rating = generateEmptyHairdresserRating(props.hairdresserLocation.id);
    rating.overall = score;
    rating.money = moneyScore;
    rating.quality = qualityScore;
    rating.experience = experienceScore;
    rating.message = message;
    rating.hairdresserCategoryIds = hairdresserCategoryIdsToUse;

    let ratingResult = await postHairdresserRating(state.token.accessToken, rating);

    if (ratingResult.status === ResponseStatus.OK) {

      setShowThanksMessage(true)

    } else if (ratingResult.status === ResponseStatus.ERROR) {

      // Set new errors
      for (let [, value] of Object.entries(ratingResult.data.errors)) {
        for (let [, value2] of Object.entries(value.description)) {
          setErrorMessage(value2);
        }
      }
    }

    setLoading(false);
  }

  const setHairdresserCategoryId = (hairdresserCategoryId) => {

    let updateHairdresserCategoryIds = copy(hairdresserCategoryIds);

    let existIndex = -1;
    for (let i = 0; i < updateHairdresserCategoryIds.length; i++) {
      if (updateHairdresserCategoryIds[i] === hairdresserCategoryId) {
        existIndex = i;
      }
    }

    if (existIndex >= 0) {
      updateHairdresserCategoryIds.splice(existIndex, 1);
    } else {
      updateHairdresserCategoryIds.push(hairdresserCategoryId)
    }

    setHairdresserCategoryIds(updateHairdresserCategoryIds)
  }

  const loginSuccess = () => {
    setSuccessMessage('Du er nu logget ind og er klar til at raté')
    setRatingStep('rate')
  }

  const createdUserSuccess = () => {
    setSuccessMessage('Du er nu logget ind og er klar til at raté')
    setRatingStep('rate')
  }

  return (
    <Modal show={props.show} close={props.close} className="rate-hairdresser">

      <div className="modal-header d-flex">
        <div className="flex-grow-1">
          <h3>Rate {props.hairdresserLocation.name}</h3>
        </div>
        <div className="modal-close" onClick={props.close}></div>
      </div>

      {
        showThanksMessage
          ?
          <div className="modal-body">
            <p>Tak for din anmeldelse.</p>
            <button type="button" className="btn btn-primary" onClick={() => props.close()}>Luk</button>
          </div>
          :
          <>

            {
              hasValue(successMessage) &&
              <div className="alert alert-success">
                {successMessage}
              </div>
            }

            {
              hasValue(errorMessage) &&
              <div className="alert alert-danger">
                {errorMessage}
              </div>
            }

            {
              ratingStep === 'rate' &&
              <>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="rate-hairdresser-label">Værdi for pengene</label>
                    <p>Var behandlingen dine penge værd?</p>
                    <div className="hairdresser-rating-score">
                      <div className="hairdresser-rating-icons">
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempMoneyScore(1) } onMouseLeave={() => setTempMoneyScore(0)} onClick={() => setMoneyScore(1)}>{moneyScore >= 1 || tempMoneyScore >= 1 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempMoneyScore(2) } onMouseLeave={() => setTempMoneyScore(0)} onClick={() => setMoneyScore(2)}>{moneyScore >= 2 || tempMoneyScore >= 2 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempMoneyScore(3) } onMouseLeave={() => setTempMoneyScore(0)} onClick={() => setMoneyScore(3)}>{moneyScore >= 3 || tempMoneyScore >= 3 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempMoneyScore(4) } onMouseLeave={() => setTempMoneyScore(0)} onClick={() => setMoneyScore(4)}>{moneyScore >= 4 || tempMoneyScore >= 4 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempMoneyScore(5) } onMouseLeave={() => setTempMoneyScore(0)} onClick={() => setMoneyScore(5)}>{moneyScore >= 5 || tempMoneyScore >= 5 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="rate-hairdresser-label">Kvaliteten af håndværket</label>
                    <p>Er du tilfreds med behandlingen?</p>
                    <div className="hairdresser-rating-score">
                      <div className="hairdresser-rating-icons">
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempQualityScore(1) } onMouseLeave={() => setTempQualityScore(0)} onClick={() => setQualityScore(1)}>{qualityScore >= 1 || tempQualityScore >= 1 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempQualityScore(2) } onMouseLeave={() => setTempQualityScore(0)} onClick={() => setQualityScore(2)}>{qualityScore >= 2 || tempQualityScore >= 2 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempQualityScore(3) } onMouseLeave={() => setTempQualityScore(0)} onClick={() => setQualityScore(3)}>{qualityScore >= 3 || tempQualityScore >= 3 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempQualityScore(4) } onMouseLeave={() => setTempQualityScore(0)} onClick={() => setQualityScore(4)}>{qualityScore >= 4 || tempQualityScore >= 4 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempQualityScore(5) } onMouseLeave={() => setTempQualityScore(0)} onClick={() => setQualityScore(5)}>{qualityScore >= 5 || tempQualityScore >= 5 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="rate-hairdresser-label">Oplevelsen hos frisøren</label>
                    <p>Havde du en god oplevelse undervejs?</p>
                    <div className="hairdresser-rating-score">
                      <div className="hairdresser-rating-icons">
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempExperienceScore(1) } onMouseLeave={() => setTempExperienceScore(0)} onClick={() => setExperienceScore(1)}>{experienceScore >= 1 || tempExperienceScore >= 1 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempExperienceScore(2) } onMouseLeave={() => setTempExperienceScore(0)} onClick={() => setExperienceScore(2)}>{experienceScore >= 2 || tempExperienceScore >= 2 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempExperienceScore(3) } onMouseLeave={() => setTempExperienceScore(0)} onClick={() => setExperienceScore(3)}>{experienceScore >= 3 || tempExperienceScore >= 3 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempExperienceScore(4) } onMouseLeave={() => setTempExperienceScore(0)} onClick={() => setExperienceScore(4)}>{experienceScore >= 4 || tempExperienceScore >= 4 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempExperienceScore(5) } onMouseLeave={() => setTempExperienceScore(0)} onClick={() => setExperienceScore(5)}>{experienceScore >= 5 || tempExperienceScore >= 5 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="rate-hairdresser-label">Din generele mening</label>
                    <p>En generel vurdering af dit besøg.</p>
                    <div className="hairdresser-rating-score">
                      <div className="hairdresser-rating-icons">
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempScore(1) } onMouseLeave={() => setTempScore(0)} onClick={() => setScore(1)}>{score >= 1 || tempScore >= 1 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempScore(2) } onMouseLeave={() => setTempScore(0)} onClick={() => setScore(2)}>{score >= 2 || tempScore >= 2 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempScore(3) } onMouseLeave={() => setTempScore(0)} onClick={() => setScore(3)}>{score >= 3 || tempScore >= 3 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempScore(4) } onMouseLeave={() => setTempScore(0)} onClick={() => setScore(4)}>{score >= 4 || tempScore >= 4 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                        <span className="hairdresser-rating-input" onMouseEnter={() => setTempScore(5) } onMouseLeave={() => setTempScore(0)} onClick={() => setScore(5)}>{score >= 5 || tempScore >= 5 ? <IconRatingFull /> : <IconRatingEmtpy />}</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="rate-hairdresser-label">Vælg færdigheder</label>
                    <p>Hvilke færdigheder blev der brugt under dit besøg?</p>
                    <ChoiceWrapper type="multi" className="mb-3">
                      {
                        hasValue(props.hairdresserCategories) &&
                        props.hairdresserCategories.map(hairdresserCategory => {
                            let active = hairdresserCategoryIds.some(id => id === hairdresserCategory.id);
                            return <ChoiceButton key={getKey(hairdresserCategory)} active={active} onClick={() => setHairdresserCategoryId(hairdresserCategory.id)}>{hairdresserCategory.name}</ChoiceButton>
                          }
                        )
                      }
                    </ChoiceWrapper>
                  </div>

                  <div className="form-group">
                    <label>Beskrivelse</label>
                    <textarea className="form-control" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                  </div>

                  <CustomButton type={"primary"} disabled={loading} loading={loading} onClick={addRating}>Rate</CustomButton>
                </div>
              </>
            }
            {
              ratingStep === 'createUser' &&
              <>
                <h4>Opret en bruger og færdiggør din rating</h4>

                <CreateUser success={() => createdUserSuccess()} cancel={() => setRatingStep('rate')} goToLogin={() => setRatingStep('login')} />
              </>
            }
            {
              ratingStep === 'login' &&
              <>
                <h4>Login og færdiggør din rating</h4>

                <Login success={() => loginSuccess()} cancel={() => setRatingStep('rate')} goToCreateUser={() => setRatingStep('createUser')} />
              </>
            }
          </>
      }

    </Modal>
  );
}

export default RateHairdresser;
