import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';
import MapListing from './MapListing';

import './MapContainer.css';

import { getHairdresserLocations } from "../../requests/hairdresserLocation.js";
import { getHairdresserCategories } from "../../requests/hairdresserCategories.js";
import {ResponseStatus} from "../../models/responseStatus";
import ChoiceButton from "../elements/ChoiceButton";
import ChoiceWrapper from "../elements/ChoiceWrapper";

import {copy, hasValue, getKey} from "../../utils"
import CustomInput from "../elements/CustomInput";
import RangeInput from "../elements/RangeInput";
import SearchAddress from "../elements/SearchAddress";
import {IconClose, IconFilter, IconList, IconMap} from "../../Icons";

const isbot = require('isbot');

const defaultMinPrice = 0;
const defaultMaxPrice = 1000;

const initialSearch = {
  keyword: "",
  minPrice: defaultMinPrice,
  maxPrice: defaultMaxPrice,
  categories: [],
  certified: null,
  nwLatitude: null,
  nwLongitude: null,
  seLatitude: null,
  seLongitude: null
}

let searchTimeout = 0;

export const MapContainer = (props) => {

  let showMap = isbot(navigator.userAgent);

  let query = new URLSearchParams(props.location.search);
  let initialLatitude = 56.16969587780383;
  let initialLongitude = 10.125789667311835;
  let initialZoom = 8;

  if (query.has("latitude") && query.has("longitude") && !isNaN(query.get("latitude")) && !isNaN(query.get("longitude"))) {
    initialLatitude = Number(query.get("latitude"));
    initialLongitude = Number(query.get("longitude"));
    initialZoom = 12;
  }

  const mapRef = React.useRef(null);
  const [search, setSearch] = React.useState(initialSearch);

  const [address, setAddress] = React.useState();
  const [bounds, setBounds] = React.useState(null);
  const [center, setCenter] = React.useState({lat: initialLatitude, lng: initialLongitude})
  const [zoom, setZoom] = React.useState(initialZoom);

  const [markers, setMarkers] = React.useState([]);
  const [hairdresserCategories, setHairdresserCategories] = React.useState([]);

  const [showListView, setShowListView] = React.useState(true);
  const [showFilter, setShowFilter] = React.useState(true);

  React.useEffect(() => {
    async function fetchInitialInfo() {

      let hairdresserLocationResult = await getHairdresserLocations();

      if (hairdresserLocationResult.status === ResponseStatus.OK) {

        setMarkers(hairdresserLocationResult.data.data)

      } else {
        console.log("Fejl under hentning af frisører");
      }

      let hairdresserCategoriesResult = await getHairdresserCategories();
      if (hairdresserCategoriesResult.status === ResponseStatus.OK) {

        setHairdresserCategories(hairdresserCategoriesResult.data.data)

      } else {
        console.log("Fejl under hentning af kategorier");
      }
    }
    fetchInitialInfo();

    if (window.innerWidth < 767) {
      setShowListView(false)
      setShowFilter(false)
    }
  }, []);

  // do not use the state directly, as this might not be updated at this point
  const searchHairdresserLocations = async (bounds, keyword, minPrice, maxPrice, categories, certified) => {

    clearTimeout(searchTimeout);
    searchTimeout = setTimeout(async () => {
      let body = {
        keyword: keyword,
        minPrice: minPrice,
        maxPrice: maxPrice,
        categories: categories,
        certified: certified,
        nwLatitude: bounds?.nw?.lat,
        nwLongitude: bounds?.nw?.lng,
        seLatitude: bounds?.se?.lat,
        seLongitude: bounds?.se?.lng
      };

      let hairdresserLocationResult = await getHairdresserLocations(body);

      if (hairdresserLocationResult.status === ResponseStatus.OK) {
        setMarkers(hairdresserLocationResult.data.data)
      }
    }, 1000);
  }

  const setAddresField = (returnAddress) => {
    if (returnAddress.id.length > 0) {
      let address = {
        zipCode: returnAddress.zipCode,
        city: returnAddress.city
      };

      setAddress(address)
      setCenter({lat: returnAddress.latitude, lng: returnAddress.longitude})
      setZoom(12);
    }
  }

  const setCategory = (category) => {

    let updateCategories = copy(search.categories);

    if (updateCategories.includes(category)) {
      updateCategories = updateCategories.filter(e => e !== category);
    } else {
      updateCategories.push(category);
    }

    setSearch({...search, categories: updateCategories})
    searchHairdresserLocations(bounds, search.keyword, search.minPrice, search.maxPrice, updateCategories, search.certified);
  }

  const setPriceInterval = async (minPrice, maxPrice) => {
    setSearch({...search, minPrice: minPrice, maxPrice: maxPrice});
    searchHairdresserLocations(bounds, search.keyword, minPrice, maxPrice, search.categories, search.certified);
  }

  const setKeyword = (keyword) => {
    setSearch({...search, keyword: keyword})
    searchHairdresserLocations(bounds, keyword, search.minPrice, search.maxPrice, search.categories, search.certified);
  }

  const setCertified = (certified) => {
    setSearch({...search, certified: certified})
    searchHairdresserLocations(bounds, search.keyword, search.minPrice, search.maxPrice, search.categories, certified);
  }

  console.log(search)

  return (
    <div className="maps">
      <div className={"maps-actions" + (showListView || showFilter ? " open" : "")}>
        <div className="map-icon map-filter-trigger" onClick={() => setShowFilter(!showFilter)}>
          {
            showFilter
              ? <IconClose />
              : <IconFilter />
          }
        </div>
        <div className="map-icon map-list-view-trigger" onClick={() => { setShowFilter(false); setShowListView(!showListView); }}>
          {
            showListView
              ? <IconMap />
              : <IconList />
          }
        </div>

        <SearchAddress searchFor={"cities"} value={address ? (address.zipCode + ", " + address.city) : ""} setAddress={setAddresField} showMarker={true} />
      </div>
      <div className={"maps-left"+ (showListView || showFilter ? " open" : "")}>
        <div className={"maps-filter" + (showFilter ? " open" : "")}>

          <div className="form-group">
            <label className="font-size-large">Fri søgetekst</label>
            <CustomInput type="text" value={search.keyword} onChange={(e) => setKeyword(e.target.value)} />
          </div>

          <div className="form-group">
            <label className="font-size-large">Vælg færdigheder</label>

            <ChoiceWrapper type="multi" className="mb-3">
              {
                hasValue(hairdresserCategories) &&
                hairdresserCategories.map(hairdresserCategory => {
                    let active = search.categories.some(categoryId => categoryId === hairdresserCategory.id);
                    return <ChoiceButton key={getKey(hairdresserCategory)} active={active} onClick={() => setCategory(hairdresserCategory.id)}>{hairdresserCategory.name}</ChoiceButton>
                  }
                )
              }
            </ChoiceWrapper>
          </div>

          <div className="form-group">
            <label className="font-size-large">Certificeret eller ej?</label>
            <ChoiceWrapper type="single" className="mb-3">
              <ChoiceButton active={search.certified === "0"} onClick={() => setCertified(search.certified === "0" ? "" : "0" )}>Ikke certificeret</ChoiceButton>
              <ChoiceButton active={search.certified === "1"} onClick={() => setCertified(search.certified === "1" ? "" : "1" )}>Certificeret</ChoiceButton>
            </ChoiceWrapper>
          </div>

          <div className="form-group mb-0">
            <label className="font-size-large">Vælg pris</label>
            <RangeInput min={defaultMinPrice} max={defaultMaxPrice} handleChange={setPriceInterval} lowerVal={search.minPrice} upperVal={search.maxPrice} />
          </div>

        </div>

        <div className="map-result-counter">
          Der blev fundet {markers.length} frisører
        </div>

        <div className={"maps-hairdressers" + (showListView && !showFilter ? " open" : "")}>
          {
            markers.map((marker) =>
              <MapListing key={marker.id} marker={marker} />
            )
          }
        </div>
      </div>
      <div className="maps-map">
        {

          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBG3cpiPcZIKperkKlPeDEm8iSejkAxhPo' }}
            defaultCenter={center}
            center={center}
            defaultZoom={zoom}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              mapRef.current = map;
            }}
            options={{
              fullscreenControl: false,
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom);
              setBounds(bounds);
              searchHairdresserLocations(bounds, search.keyword, search.minPrice, search.maxPrice, search.categories, search.certified);
            }}
          >
            {
              markers.map((marker) =>
                <MapMarker key={marker.id} lat={marker.latitude} lng={marker.longitude} marker={marker} />
              )
            }
          </GoogleMapReact>
        }
      </div>
    </div>
  );
}

export default MapContainer
/*
MDB : AIzaSyC-i3VYLNPJySnDxtWBWGHdg37i-RQzpEU
FRI : AIzaSyBG3cpiPcZIKperkKlPeDEm8iSejkAxhPo
<GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC-i3VYLNPJySnDxtWBWGHdg37i-RQzpEU' }}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
          zoom={zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds(bounds);
            searchHairdresserLocations(zoom, bounds, keyword, price.minPrice, price.maxPrice, categories);
          }}
        >
          {
            markers.map((marker) =>
              <MapMarker key={marker.id} lat={marker.latitude} lng={marker.longitude} marker={marker} />
            )
          }
        </GoogleMapReact>
*/
