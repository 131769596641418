import React from 'react';
import {useHistory} from "react-router-dom";

import {AuthContext} from "../../../contexts/AuthContext";
import {getUserByAuth, putUser} from "../../../requests/user";
import {ResponseStatus} from "../../../models/responseStatus";

import './Profile.css';
import CustomAlert from "../../elements/CustomAlert";
import CustomInput from "../../elements/CustomInput";
import PhoneNumberInput from "../../elements/PhoneNumberInput";
import SearchAddress from "../../elements/SearchAddress";
import {Editor} from "@tinymce/tinymce-react";
import ChoiceWrapper from "../../elements/ChoiceWrapper";
import ChoiceButton from "../../elements/ChoiceButton";
import { copy, arrayMove, getKey, hasValue} from "../../../utils";
import DropZone from "../../elements/DropZone";
import Sortable from "../../elements/Sortable";
import LoadingPage from "../../elements/LoadingPage";

import {putImage} from "../../../requests/image";
import {generateEmptyUser, initialImage} from "../../../types";
import CustomButton from "../../elements/CustomButton";
import CustomTextarea from "../../elements/CustomTextarea";

export const Profile = (props) => {
  const [authState,] = React.useContext(AuthContext);

  const [isPageReady, setIsPageReady] = React.useState(false)
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const [user, setUser] = React.useState(generateEmptyUser());
  const [images, setImages] = React.useState([]);
  const [profileImage, setProfileImage] = React.useState(initialImage);

  React.useEffect(() => {
    async function fetchInitialInfo() {
        let userResult = await getUserByAuth(authState.token.accessToken);
        if (userResult.status === ResponseStatus.OK) {

          setUser(userResult.data.data);
          setImages(userResult.data.data.images)
          setProfileImage(userResult.data.data.profileImage)

        } else {
          console.log("Fejl under hentning af bruger");
          setErrorMessage("Fejl under hentning af profil");
        }

        setIsPageReady(true)
      }

    fetchInitialInfo();
  }, []);


  const updateUser = async () => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let userData = copy(user);

    let userResult = await putUser(authState.token.accessToken, user.id, userData);

    if (userResult.status === ResponseStatus.OK) {

      setSuccessMessage("Success");

    } else if (userResult.status === ResponseStatus.ERROR) {

      // Reset validations
      /*
      let indicativeOfferValidationCopy = copy(indicativeOfferValidation);
      for (let [key,] of Object.entries(indicativeOfferValidation)) {
        indicativeOfferValidationCopy[key] = [];
      }
      */

      // Set new errors
      /*
      for (let [key, value] of Object.entries(indicativeOfferResult.data.errors)) {
        for (let [, value2] of Object.entries(value.description)) {
          if (key in indicativeOfferValidationCopy)
            indicativeOfferValidationCopy[key].push(value2);
          else
            setErrorMessage(value2);
        }
      }
      */

      //setIndicativeOfferValidation(indicativeOfferValidationCopy);
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const setProfileImageContainer = (newImage) => {
    if (newImage.length === 1) {
      setProfileImage(newImage[0])
    }
  }

  const setImagesContainer = (newImages) => {
    setImages([...images, ...newImages])
  }

  const removeImage = async (image) => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let body = copy(image);
    body.deleted = true;

    let imageResult = await putImage(authState.token.accessToken, image.id, body);

    if (imageResult.status === ResponseStatus.OK) {

      setSuccessMessage("Success");
      const updateImages = images.filter(e => e.id != image.id);
      setImages(updateImages)

    } else if (imageResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const updateImagesOrder = async (oldIndex, newIndex) => {
    let updateImages = arrayMove(copy(images), oldIndex, newIndex);
    setImages(updateImages);

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    for (let i = 0; i < updateImages.length; i++) {

      let body = updateImages[i];
      body.sequence = i;

      let imageResult = await putImage(authState.token.accessToken, updateImages[i].id, body);

      if (imageResult.status === ResponseStatus.OK) {

        setSuccessMessage("Success");

      } else if (imageResult.status === ResponseStatus.ERROR) {
        setErrorMessage("Fejl");
      }
    }

    setLoading(false);
  }

  return (
    <LoadingPage isPageReady={isPageReady}>
      <div className="location">

        <h1>{user.firstName} {user.lastName}</h1>

        {
          errorMessage &&
          <CustomAlert type={"danger"} className="mt-3" close={() => setErrorMessage(null)}>{errorMessage}</CustomAlert>
        }
        {
          successMessage &&
          <CustomAlert type={"success"} className="mt-3" close={() => setSuccessMessage(null)}>{successMessage}</CustomAlert>
        }

        <h4 className="form-header mb-3">Primære oplysninger om dig</h4>

        <div className="row align-items-center">

          <div className="col-xl-7">
            <div className="row align-items-center">
              <div className="col-6 offset-3 offset-lg-0 col-lg-4 col-xl-4">
                <DropZone foreignTable="User" foreignId={user.id} type="profileImage" image={profileImage} setImages={setProfileImageContainer} />
              </div>

              <div className="col-lg-8 col-xl-8">
                <div className="form-group">
                  <label className="font-size-large font-weight-xbold mb-3">Fornavn</label>
                  <CustomInput type={"text"} name="name" placeholder={"Navn"} value={user.firstName} onChange={(e) => setUser({...user, firstName: e.target.value})}/>
                </div>

                <div className="form-group">
                  <label className="font-size-large font-weight-xbold mb-3">Efternavn</label>
                  <CustomInput type={"text"} name="name" placeholder={"Navn"} value={user.lastName} onChange={(e) => setUser({...user, lastName: e.target.value})}/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5">

            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Email</label>
              <CustomInput type={"text"} name="email" placeholder={"Email"} value={user.email} onChange={(e) => setUser({...user, email: e.target.value})}/>
            </div>

            <div className="form-group">
              <label className="font-size-large font-weight-xbold mb-3">Landekode / Telefonnummer</label>
              <PhoneNumberInput phoneCallingCode={user.phoneCallingCode} phoneNumber={user.phoneNumber} onChange={(phoneCallingCode, phoneNumber) => setUser({...user, phoneCallingCode: phoneCallingCode, phoneNumber: phoneNumber})}/>
            </div>

          </div>
        </div>

        <div className="form-group">
          <label className="font-size-large font-weight-xbold mb-3">Kort beskrivelse om dig (max 100 tegn)</label>
          <textarea className="form-control" value={user.shortDescription} onChange={(e) => setUser({...user, shortDescription: e.target.value})} />
        </div>

        <div className="form-group">
          <label className="font-size-large font-weight-xbold mb-3">Fritekst til din profil</label>
          <CustomTextarea value={user.description} onChange={(value) => setUser({...user, description: value})} />
        </div>

        <CustomButton type="primary" className="btn-large" disabled={loading} onClick={updateUser}>{loading ? "Loading..." : "Gem profil"}</CustomButton>

        <div className="images mt-5">
          <div className="upload-images">
            <h2>Upload billeder</h2>
            <p className="manchet">Har du noget du ønsker at vise til andre? Så upload billederne her. </p>

            <DropZone foreignTable="User" foreignId={user.id} setImages={setImagesContainer} />
          </div>

          <Sortable items={images} updateItems={updateImagesOrder} removeItem={removeImage} type="images" />
        </div>

      </div>
    </LoadingPage>
  );
};

export default Profile;
