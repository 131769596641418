import React from "react";

import {copy, getKey, hasValue} from "../../utils";
import Rating from "./Rating";

import './RatingList.css';

export const RatingList = ({ratings, groupedRatings, showLocation}) => {
  return (
    hasValue(ratings)
      ?
      <div className="rating-list">
        {
          ratings.map((rating) =>
            <Rating key={getKey(rating)} rating={rating} showLocation={showLocation || false} />
          )
        }
      </div>
      :
      <div className="rating-list">
        {
          Object.keys(groupedRatings).map((key) =>
            <Rating key={getKey(groupedRatings[key].average)} rating={groupedRatings[key].average} ratings={groupedRatings[key].ratings} showLocation={showLocation || false} />
          )
        }
      </div>
  );
};

export default RatingList;
