import { callApi } from './base.js';

export const postHairdresserUser = async (token, data) => {
  return callApi({
    url: "/hairdresser-user",
    method: "POST",
    body: data,
    token: token,
  });
};

export const putHairdresserUser = async (token, id, data) => {
  return callApi({
    url: "/hairdresser-user/" + id,
    method: "PUT",
    body: data,
    token: token,
  });
};

export const deleteHairdresserUser = async (token, id) => {
  return callApi({
    url: "/hairdresser-user/" + id,
    method: "DELETE",
    body: null,
    token: token,
  });
};
