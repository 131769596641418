import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck, faEye, faPencilAlt, faSave, faTimes, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

import CustomInput from "../../../../elements/CustomInput";
import {AuthContext} from "../../../../../contexts/AuthContext";
import {ResponseStatus} from "../../../../../models/responseStatus";

import './User.css';
import {postHairdresserUser, putHairdresserUser} from "../../../../../requests/hairdresserUser";
import {ItemContext} from "../../../../../contexts/ItemContext";

import {getKey, hasValue, copy} from "../../../../../utils";
import { initialHairdresserUser } from "../../../../../types";

import LoadingPage from "../../../../elements/LoadingPage";
import CustomAlert from "../../../../elements/CustomAlert";
import CustomSwitch from "../../../../elements/CustomSwitch";
import {confirm} from "../../../../Modals/Confirm";
import ChoiceButton from "../../../../elements/ChoiceButton";
import ChoiceWrapper from "../../../../elements/ChoiceWrapper";

export const User = (props) => {

  const [state,] = React.useContext(AuthContext);
  const hairdresserUserContext = React.useContext(ItemContext);

  const [isPageReady, setIsPageReady] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [readyForSubmit, setReadyForSubmit] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);
  const [hairdresserUser, setHairdresserUser] = React.useState(initialHairdresserUser);
  const [tempHairdresserUser, setTempHairdresserUser] = React.useState(initialHairdresserUser);
  const [showEditHairdresserUser, setShowEditHairdresserUser] = React.useState(false);

  React.useEffect(() => {
    setHairdresserUser(props.hairdresserUser)
    setIsPageReady(true);

    // new hairdresserUser
    if (!hasValue(props.hairdresserUser.id)) {
      setShowEditHairdresserUser(true);
    }

  }, [getKey(props.hairdresserUser)]);

  const startEditMode = async () => {
    setTempHairdresserUser(copy(hairdresserUser));
    setShowEditHairdresserUser(true);

    setErrorMessage(null);
    setSuccessMessage(null);
  }

  const cancelEditMode = async () => {
    setHairdresserUser(copy(tempHairdresserUser));
    setShowEditHairdresserUser(false);
  }

  const updateHairdresserUser = async (addUser) => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserUserResult = await putHairdresserUser(state.token.accessToken, addUser.id, addUser);

    if (hairdresserUserResult.status === ResponseStatus.OK) {

      hairdresserUserContext.update(hairdresserUserResult.data.data, getKey(addUser))
      setHairdresserUser(hairdresserUserResult.data.data)
      setShowEditHairdresserUser(false);

      setSuccessMessage("Success");

    } else if (hairdresserUserResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  const removeHairdresserUser = async () => {
    if (await confirm('Du er ved at fjerne brugeren: <br />' + hairdresserUser.user.firstName + ' ' + hairdresserUser.user.lastName + '<br /><br />Ønsker du at fortsætte?')) {

      let updateUser = copy(hairdresserUser);
      updateUser.active = false;
      updateUser.deleted = true;

      if (hasValue(hairdresserUser.id)) {
        updateHairdresserUser(updateUser);
      } else {
        setHairdresserUser(updateUser);
      }
    }
  }

  return (
    <LoadingPage isPageReady={isPageReady} deleted={hairdresserUser.deleted} hide={true}>
      <div className="hairdresser-user box box-shadow mb-3">

        <div className="hairdresser-user-line">
          <div className="hairdresser-user-active">

          </div>
          <div className="hairdresser-user-name">
            {hairdresserUser.user.firstName} {hairdresserUser.user.lastName}
          </div>
          <div className="hairdresser-user-title">
            {hairdresserUser.title}
          </div>
          <div className="hairdresser-user-show-profile">
            {hairdresserUser.showProfile ? 'Synlig' : 'Skjult'}
          </div>
          <div className="hairdresser-user-permission">
            {hairdresserUser.permission === 'write' ? 'Se | Redigere' : 'Se'}
          </div>
          <div className="hairdresser-user-actions">
            {
              showEditHairdresserUser
                ? <>
                  <button type="button" className={"btn btn-icon" + (readyForSubmit || loading ? "" : " disabled")} disabled={!readyForSubmit || loading} onClick={() => updateHairdresserUser(hairdresserUser)}><FontAwesomeIcon icon={faSave} size="1x" color="#28a745" /></button>
                  <button type="button" className={"btn btn-icon ml-2"} onClick={() => cancelEditMode()}><FontAwesomeIcon icon={faTimes} size="1x" color="#96989b" /></button>
                </>
                : <button type="button" className={"btn btn-icon ml-2"} onClick={() => startEditMode()}><FontAwesomeIcon icon={faPencilAlt} size="1x" color="#28a745" /></button>
            }
            <button type="button" className={"btn btn-icon ml-2"} onClick={removeHairdresserUser}><FontAwesomeIcon icon={faTrashAlt} size="1x" color="#D32D27" /></button>
          </div>
        </div>

        {
          /*
          hasValue(hairdresserUser.description) &&
          <div className="offer-line">
            <div className="offer-active">
            </div>
            <div className="offer-description">
              {offer.description}
            </div>
            <div className="offer-actions">
            </div>
          </div>
           */
        }

        <div className={"hairdresser-user-edit" + (showEditHairdresserUser ? ' hairdresser-user-edit-show ' : '') + " mt-3"}>

          {
            errorMessage &&
            <CustomAlert type={"danger"} close={() => setErrorMessage(null)}>{errorMessage}</CustomAlert>
          }
          {
            successMessage &&
            <CustomAlert type={"success"} close={() => setSuccessMessage(null)}>{successMessage}</CustomAlert>
          }

          <div className="row">
            <div className="col-8 hairdresser-user-name">
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Navn</label>
                <CustomInput type={"text"} name="name" defaultValue={hairdresserUser.user.firstName + " " +hairdresserUser.user.lastName} readOnly={true} />
              </div>
            </div>

            <div className="col-4 hairdresser-user-price">
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Titel</label>
                <CustomInput type={"text"} name="title" defaultValue={hairdresserUser.title} onChange={(e) => setHairdresserUser({ ...hairdresserUser, title: e.target.value })} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Rettigheder til salonen</label>
                <ChoiceWrapper type="single" className="mb-3">
                  <ChoiceButton active={hairdresserUser.permission === 'read'} onClick={() => setHairdresserUser({...hairdresserUser, permission: 'read'})}>Se profil</ChoiceButton>
                  <ChoiceButton active={hairdresserUser.permission === 'write'} onClick={() => setHairdresserUser({...hairdresserUser, permission: 'write'})}>Redigere profil</ChoiceButton>
                </ChoiceWrapper>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label className="font-size-large font-weight-xbold mb-3">Vis brugeren som medarbejder hos salonen</label>
                <ChoiceWrapper type="single" className="mb-3">
                  <ChoiceButton active={!hairdresserUser.showProfile} onClick={() => setHairdresserUser({...hairdresserUser, showProfile: false})}>Skjul</ChoiceButton>
                  <ChoiceButton active={hairdresserUser.showProfile} onClick={() => setHairdresserUser({...hairdresserUser, showProfile: true})}>Vis</ChoiceButton>
                </ChoiceWrapper>
              </div>
            </div>
          </div>

        </div>

      </div>

    </LoadingPage>
  );
};

export default User;
