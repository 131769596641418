import React from 'react';

import './ListUserRatings.css';
import {getHairdresserRatingsByAuthUser} from "../../../requests/hairdresserRating";
import {AuthContext} from "../../../contexts/AuthContext";
import {ResponseStatus} from "../../../models/responseStatus";
import {hasValue} from "../../../utils";
import RatingList from "../../templates/RatingList";
import LoadingPage from "../../elements/LoadingPage";

export const ListUserRatings = () => {

  const [state,] = React.useContext(AuthContext);

  const [isPageReady, setIsPageReady] = React.useState(false)
  const [ratings, setRatings] = React.useState([]);

  React.useEffect(() => {
    async function fetchInitialInfo() {

      let ratingsResult = await getHairdresserRatingsByAuthUser(state.token.accessToken);

      if (ratingsResult.status === ResponseStatus.OK) {

        setRatings(ratingsResult.data.data);

      } else {
        console.log("Fejl under hentning af søgeagenter");
      }

      setIsPageReady(true)

    }
    fetchInitialInfo();
  }, []);

  return (
    <div className="list-user-ratings">

      <div className="d-flex">
        <div className="flex-grow-1">
          <h1>Dine ratings</h1>
        </div>
      </div>

      <LoadingPage isPageReady={isPageReady}>
        {
          hasValue(ratings)
            ?
              <>
                <p>
                  Du har rated {ratings.length} frisører.<br />
                  Hvis du ønsker at rette eller raté en ny frisør, skal du finde deres profil og raté dem derigennem. Hvis du ratér en frisør for anden gang, opdateres din oprindelige rating.
                </p>
                <RatingList ratings={ratings} showLocation={true} />
              </>
            :
              <div>
                <p>
                  Du har ikke rated nogle frisører endnu.<br />
                  Hvis du ønsker at rate et firma, skal du finde deres profil og rate dem derigennem.
                </p>
              </div>
        }
      </LoadingPage>

    </div>
  )
}

export default ListUserRatings;
