import React from "react";

import './ChoiceButton.css';

export const ChoiceButton = (props) => {

  return (
    props.active
      ? <span className={"choice-button" + (props.active ? " active" : "")} onClick={props.onClick}>{props.children}</span>
      : <button className={"choice-button" + (props.active ? " active" : "")} onClick={props.onClick}>{props.children}</button>
  );
};
export default ChoiceButton;