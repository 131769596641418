import React from 'react';

import './RatingIcons.css';
import {IconRatingEmtpy, IconRatingFull, IconRatingHalf} from "../../Icons";

// First we create our class
const RatingIcons = ({rating, size}) => {

	return (
		<div className={"rating-icons" + (size === 'large' ? ' rating-icons-large' : '')}>
			{rating.overall >= 1 ? <IconRatingFull /> : rating.overall > 0 ?  <IconRatingHalf /> : <IconRatingEmtpy />}
			{rating.overall >= 2 ? <IconRatingFull /> : rating.overall > 1 ?  <IconRatingHalf /> : <IconRatingEmtpy />}
			{rating.overall >= 3 ? <IconRatingFull /> : rating.overall > 2 ?  <IconRatingHalf /> : <IconRatingEmtpy />}
			{rating.overall >= 4 ? <IconRatingFull /> : rating.overall > 3 ?  <IconRatingHalf /> : <IconRatingEmtpy />}
			{rating.overall >= 5 ? <IconRatingFull /> : rating.overall > 4 ?  <IconRatingHalf /> : <IconRatingEmtpy />}
		</div>

	)
}

export default RatingIcons;
