import React from 'react';

import './Users.css';
import {hasValue, getKey, copy} from "../../../../../utils";
import {generateEmptyHairdresserUser, initialHairdresserUser} from "../../../../../types";
import {ItemContext} from "../../../../../contexts/ItemContext";
import {User} from "./User";
import CustomButton from "../../../../elements/CustomButton";
import CustomInput from "../../../../elements/CustomInput";
import {getUsers} from "../../../../../requests/user";
import {ResponseStatus} from "../../../../../models/responseStatus";
import {postHairdresserUser} from "../../../../../requests/hairdresserUser";
import {AuthContext} from "../../../../../contexts/AuthContext";
import CustomAlert from "../../../../elements/CustomAlert";

export const Users = (props) => {

  const [state,] = React.useContext(AuthContext);
  const hairdresserUserContext = React.useContext(ItemContext);

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [hairdresserUser, setHairdresserUser] = React.useState(initialHairdresserUser);

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [successMessage, setSuccessMessage] = React.useState(null);

  const addHairdresserUser = async (addUser) => {

    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    let hairdresserUserResult = await postHairdresserUser(state.token.accessToken, copy(addUser));

    if (hairdresserUserResult.status === ResponseStatus.OK) {

      hairdresserUserContext.add(hairdresserUserResult.data.data)

      setSuccessMessage("Brugeren er blevet tilføjet");
      setEmail('');
      setName('');

    } else if (hairdresserUserResult.status === ResponseStatus.ERROR) {

      setErrorMessage("Brugeren blev ikke tilføjet, kontakt supporten for hjælp.");
    }

    setLoading(false);
  }

  const searchUser = async (email) => {

    setErrorMessage(null);
    setSuccessMessage(null);

    if (!hasValue(email)) {
      return;
    }

    if (hairdresserUserContext.items.some(e => e.user.email == email)) {
      setErrorMessage("Brugeren af denne email er allerede tilknyttet denne salon.");
      return;
    }

    setLoading(true);

    let body = {
      email: email
    };

    let usersResult = await getUsers(body);

    if (usersResult.status === ResponseStatus.OK) {

      if (hasValue(usersResult.data.data)) {
        let newHairdresserUser = generateEmptyHairdresserUser(usersResult.data.data[0].id, props.hairdresserLocationId);
        setHairdresserUser(newHairdresserUser);
        setName(usersResult.data.data[0].firstName + ' ' + usersResult.data.data[0].lastName)
      } else {
        setErrorMessage("Ingen bruger fundet på denne email.");
      }

    } else if (usersResult.status === ResponseStatus.ERROR) {
      setErrorMessage("Fejl");
    }

    setLoading(false);
  }

  return (
    <div className="hairdresser-users mt-5">
      <h2>Tilføjede brugere</h2>

      {
        hairdresserUserContext.items.some(hairdresserUser => !hairdresserUser.deleted) &&
        <div className="hairdresser-user-line hairdresser-user-line-header mt-4 mb-3">
          <div className="hairdresser-user-active">
          </div>
          <div className="hairdresser-user-name">
            Navn
          </div>
          <div className="hairdresser-user-title">
            Titel
          </div>
          <div className="hairdresser-user-show-profile">
            Vis visitkort af brugeren
          </div>
          <div className="hairdresser-user-permission">
            Rettigheder
          </div>
          <div className="hairdresser-user-actions">
          </div>
        </div>
      }

      {
        hairdresserUserContext.items.map(hairdresserUser =>
          <User key={getKey(hairdresserUser)} hairdresserUser={hairdresserUser} />
        )
      }

      <div className="box box-shadow">
        <div className="form-group">
          <label className="font-size-large font-weight-xbold mb-3">Søg efter brugere til salonen via deres mail-adresse.</label>
          <CustomInput type={"text"} name="name" placeholder={"example@mail.dk"} value={email} onChange={(e) => setEmail(e.target.value)} showSubmit={true} onClick={() => searchUser(email)} onEnterKeyUp={() => searchUser(email)}/>
        </div>

        {
          errorMessage &&
          <CustomAlert type={"danger"} close={() => setErrorMessage(null)}>{errorMessage}</CustomAlert>
        }
        {
          successMessage &&
          <CustomAlert type={"success"} close={() => setSuccessMessage(null)}>{successMessage}</CustomAlert>
        }

        {
          hasValue(name) &&
          <div>
            <h5>Bruger fundet</h5>
            <div className="row">
              <div className="col-md-6 mb-3 mb-md-0">
                {name}
              </div>
              <div className="col-md-6 text-md-right">
                <CustomButton type="primary" onClick={() => addHairdresserUser(hairdresserUser)} >Tilføj bruger</CustomButton>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Users;
