import React from 'react';

import './CustomSelect.css';
import Select from "react-select";

// a basic form
const CustomSelect = (props) => {

  let [viewHeight, setViewHeight] = React.useState(0);
  let [viewWidth, setViewWidth] = React.useState(0);

  React.useEffect(() => {
    function setDimensions() {
      setViewHeight(window.innerHeight)
      setViewWidth(window.innerWidth)
    }
    setDimensions()
    window.addEventListener('resize', setDimensions);
    return () => window.removeEventListener('resize', setDimensions);
  });

  return (

    <Select
      styles={{
        indicatorSeparator: () => { }, // removes the "stick"
        dropdownIndicator: base => ({
          ...base,
          '& svg': { display: 'none' }
        }),
        menu: (base, state) => {

          if (viewWidth < 767) {
            return {
              ...base,
              top: "calc(" + viewHeight + "px - 190px)",
              left: "0",
              position: "fixed",
              zIndex: 9999,
              margin: 0,
              borderRadius: 0,
              height: "190px"
            }
          } else {
            return {
              ...base,
              zIndex: 9999,
              width: "100%"
            }
          }
        },
        option: (base, state) => ({
          ...base,
          backgroundColor: state.isSelected ? "#8562A2" : "#ffffff",
          "&:hover": {
            backgroundColor: state.isSelected ? "#8562A2" : "#d3c0e0"
          },
          "&:focus": {
            backgroundColor: state.isSelected ? "#8562A2" : "#d3c0e0"
          },
          "&:active": {
            backgroundColor: state.isSelected ? "#8562A2" : "#d3c0e0"
          }
        })
      }}
      className="select"
      value={props.options.find(e => e.value === props.value)}
      options={props.options}
      onChange={(selectedOption) => props.onChange(selectedOption.value) }
      isSearchable={false}
      /*defaultMenuIsOpen={true}*/
    />
  );
}

export default CustomSelect;
